import React, { useEffect, useState } from 'react'
import { CanvasJSChart } from 'canvasjs-react-charts'
import Cookies from 'js-cookie'

const ChartSpecial = ({ id, fields, type, yields, names, sums }) => {
  const [updateKey, Aupdate] = React.useState(0)
  // const colors = ['#B1A2CA', '#D291BC', '#FF6D6A', '#E9EC6B', '#EFBE7D']
  const colors = ['#8BD3E6', '#FF6D6A', '#E9EC6B', '#EFBE7D', '#B1A2CA', '#E0BBE4', '#957DAD', '#D291BC']
  const match = {}
  const seasons = fields.filter((e) => e.field_id === id).map(e => {
    return Number(e.season)
  })
  console.log(fields)
  console.log(fields.filter((e) => e.field_id === id))
  const options = {
    title: {
      text: `Зависимость урожайности культуры ${fields.filter((e) => e.field_id === id)[0].croptype} от влажности почвы и суммы активных температур в период "${type}"`
    },
    axisX: {
      title: `Поле ${names[id]}`
    },
    axisY: {
      title: 'Влажность',
      suffix: '%',
      lineColor: '#4F81BC',
      tickColor: '#4F81BC',
      labelFontColor: '#4F81BC'
    },
    axisY2: [
      {
        title: 'Температура',
        suffix: '°C',
        lineColor: '#C0504E',
        tickColor: '#C0504E',
        labelFontColor: '#C0504E'
      },
      {
        title: 'Урожайность',
        suffix: ' ц/га',
        lineColor: '#7F6084',
        titleFontColor: '#7F6084',
        labelFontColor: '#7F6084'
      }
    ],
    data: [
      {
        type: 'column',
        suffix: '%',
        name: 'Влажность поверхностного грунта',
        showInLegend: true,
        dataPoints: fields.map(e => {
          if (e.field_id === id && e.type === type) {
            return ({ label: e.season, y: Number(e.humtop) })
          }
          return null
        }).filter(e => e !== null)
      },
      {
        type: 'column',
        suffix: '%',
        name: 'Влажность в корневой зоне',
        showInLegend: true,
        dataPoints: fields.map(e => {
          if (e.field_id === id && e.type === type) {
            return ({ label: e.season, y: Number(e.humbottom) })
          }
          return null
        }).filter(e => e !== null)
      },
      {
        type: 'line',
        axisYType: 'secondary',
        name: 'Сумма активных температур',
        showInLegend: true,
        dataPoints: sums
      },
      {
        type: 'line',
        axisYType: 'secondary',
        axisYIndex: 1,
        name: 'Урожайность',
        showInLegend: true,
        dataPoints: yields
      }
    ]
  }

  return (
    <div>
      <CanvasJSChart options={options} />
    </div>
  )
}
export default ChartSpecial
