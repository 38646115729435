import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as FA from '@fortawesome/free-solid-svg-icons'
import Cookies from 'js-cookie'

const Analysis = () => {
  const [curTheme, setTheme] = React.useState(Cookies.get('theme') === 'black' ? 'black' : 'white')
  if (curTheme === 'white') {
    document.documentElement.style.setProperty('--theme-main', 'darkblue')
    document.documentElement.style.setProperty('--theme-text', 'white')
    document.documentElement.style.setProperty('--theme-secondary', 'lightgray')
    document.documentElement.style.setProperty('--theme-contrast', 'black')
  } else if (curTheme === 'black') {
    document.documentElement.style.setProperty('--theme-main', 'lime')
    document.documentElement.style.setProperty('--theme-text', 'black')
    document.documentElement.style.setProperty('--theme-secondary', 'darkslategray')
    document.documentElement.style.setProperty('--theme-contrast', 'white')
  }
  const themeChangeFunc = () => {
    if (curTheme === 'black') {
      Cookies.set('theme', 'white')
      setTheme('white')
      document.documentElement.style.setProperty('--theme-main', 'darkblue')
      document.documentElement.style.setProperty('--theme-text', 'white')
      document.documentElement.style.setProperty('--theme-secondary', 'lightgray')
      document.documentElement.style.setProperty('--theme-contrast', 'black')
    } else {
      Cookies.set('theme', 'black')
      setTheme('black')
      document.documentElement.style.setProperty('--theme-main', 'lime')
      document.documentElement.style.setProperty('--theme-text', 'black')
      document.documentElement.style.setProperty('--theme-secondary', 'darkslategray')
      document.documentElement.style.setProperty('--theme-contrast', 'white')
    }
  }
  return (
    <div className='data calcmodal-body'>
      <a style={{ width: 'fit-content', float: 'left', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' onClick={themeChangeFunc}>
        <FontAwesomeIcon icon={FA.faPalette} />
      </a>
      <a style={{ width: 'fit-content', float: 'right', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' href='/profile'>
        <FontAwesomeIcon icon={FA.faHome} />
      </a>
      <h3 align='center'>Анализ К/Х</h3>
      <img className='mx-auto d-block' src='img/core-img/mayak4.png' alt='' />
      <br />
      <br />
      <p>
        <b> 1. Результаты агрохимического обследования почв</b>
      </p>
      <p>
        <b> 1.1 Почвенно-климатические условия землепользования</b>
      </p>
      <p>
        Климатические условия области отличаются большим разнообразием и пестротой,
        что обусловлено обширностью территории, значительной протяженностью с севера
        на юг и еще большей – с запада на восток, а также изрезанностью рельефа.
        Климат области резко континентальный, сухой. Высокая степень
        континентальности проявляется в больших годовых и суточных амплитудах
        температуры и в неустойчивости климатических показателей во времени (из года
        в год). Средняя годовая температура воздуха колеблется по территории области
        в пределах 1,4 - 7,3°С, причем наиболее высокие ее значения характерны для
        самых южных районов – пустынь. Лето на территории области очень жаркое, а на
        юге знойное и продолжительное. Температура воздуха летом иногда повышается
        до 40-48°С; зима, наоборот, холодная, морозы иногда доходят до 40-45°С и
        даже 50°С. В среднем продолжительность теплого периода (со средней суточной
        температурой воздуха выше 0°) колеблется по территории области от 200 (на
        северо-востоке) до 240 дней (на юге). Территория ТОО «КХ «Маяк»
        характеризуется засушливым климатом и высокими максимальными летними
        температурами. Климат резко континентальный. Средняя температура января
        −17º-18ºС, июля +20º+21ºС. Годовое количество атмосферных осадков составляет
        250-300 мм. Рельеф. Территория представляет собой слабоволнистую степь.
        Растительность представлена ковыльно-типчаковой растительностью, с большим
        количеством солёных озёр.
      </p>
      <img className='mx-auto d-block' src='img/core-img/mayak6.png' alt='' />
      <br />
      <p align='justify'>
        <b> 1.2. Физико-химические свойства почвы. </b>
      </p>
      <p>
        Почвы каштановые, по механическому составу супесчаные легкого механического
        состава, легко подвергаются ветровой эрозии. Содержание гумуса
        характеризуется как очень низкое 0,22-1,18%, реакция почвенного раствора
        слабокислая и ближе к нейтральному, рН составляет 6,5-7,04. В целом
        климатические условия хозяйства благоприятны для выращивания всех
        районированных сельскохозяйственных культур.
      </p>
      <p>
        <img className='mx-auto d-block' src='img/core-img/mayak7.png' alt='' />
        <br />
        <br />
        <b>1.3. Результаты полевого агрохимического обследования.</b>
      </p>
      <p>
        Полевое агрохимическое обследование на землях ТОО «КХ «Маяк» Лебяжинского
        района Павлодарской области проводилось 12-16 апреля 2020 года на площади
        2000 га. Было отобрано 76 почвенных образцов. Для отбора проб почвенных
        образцов использовали автоматизированный пробоотборник WINTEX 1000S.
      </p>
      <p>
        <b>1.4. Результаты химико-аналитических исследований почвенных образцов.</b>
      </p>
      <p>
        В почвенных образцах, отобранных с элементарных участков проведено
        определение содержания гумуса, рН, общего азота, фосфора и калия, серы.
        Результаты агрохимических анализов почвенных образцов предоставлены в
        сводной ведомости анализов (средние значения по полю) и в приложении по
        точкам отбора в каждом поле.
      </p>
      <br />
      <button type='button' className='btn btn-default' onClick='window.print()'>
        Распечатать анализ
      </button>
      <br />
      <button type='button' className='btn btn-default' onClick='window.print()'>
        <i className='fa fa-fw fa-plus icon-black' /> Сохранить Анализ
      </button>
    </div>
  )
}

export default Analysis
