import { useEffect } from 'react'
import AOS from 'aos'
import { useTranslation } from 'react-i18next'
import '../styles/external.css'

const Tracking = () => {
  const { t, i18n } = useTranslation()
  return (
    <div id="omnicomm-online">
  <div className="omn-mainpage ru" style={{ width: 1195 }}>
    <div
      className="omn-leftPanel left-panel-resizable"
      style={{ height: '100vh', width: 260 }}
    >
      <div className="omn-menu brd6">
        <div className="logo bgc3">
          <div className="sm-logo-container" />
          <span />
        </div>
        <div style={{ display: 'none' }} />
      </div>
      <div className="objectPeriodSelectContainer brd2">
        <div className="">
          <div className="datepicker-widget row">
            <div className="col start-date">
              <div className="date-container txc2">26.03.2023</div>
              <div className="day-time-container txc2">
                <span>вс</span>
                <span>00:00</span>
              </div>
            </div>
            <div className="col datepicker-widget-divider">
              <span className="icon-container">
                <i className="icon icon-calendar-bg" />
              </span>
            </div>
            <div className="col finish-date">
              <div className="date-container txc2">26.03.2023</div>
              <div className="day-time-container txc2">
                <span>вс</span>
                <span>23:59</span>
              </div>
            </div>
          </div>
          <div className="quick-period-selector row">
            <div className="col quick-period-selector-single today selected txc2">
              <span>Сегодня</span>
            </div>
            <div className="col quick-period-selector-single yesterday txc3 txc2-hv">
              <span>Вчера</span>
            </div>
            <div className="col quick-period-selector-single week txc3 txc2-hv">
              <span>7 дней</span>
            </div>
            <div className="col quick-period-selector-single month txc3 txc2-hv">
              <span>Месяц</span>
            </div>
          </div>
        </div>
        <div className="omn-treeContainer">
          <div className="omn-tree-select row">
            <div className="col selected  vehicles brd2">
              <div title="Транспортные средства">
                <span className="icon-container">
                  <i className="icon icon-tree-select-vehicles" />
                </span>
                <span className="selected-amount txc2">0</span>
              </div>
            </div>
            <div className="col   drivers brd2">
              <div title="Водители">
                <span className="icon-container">
                  <i className="icon icon-tree-select-drivers" />
                </span>
                <span className="selected-amount txc3">0</span>
              </div>
            </div>
            <div className="col   geozones brd2">
              <div title="Геозоны">
                <span className="icon-container">
                  <i className="icon icon-tree-select-geozones" />
                </span>
                <span className="selected-amount txc3">0</span>
              </div>
            </div>
            <div className="col   routes brd2">
              <div title="Маршруты">
                <span className="icon-container">
                  <i className="icon icon-tree-select-routes" />
                </span>
                <span className="selected-amount txc3">0</span>
              </div>
            </div>
          </div>
          <div className="">
            <div
              className="txc3 omn-tree vehicles"
              style={{ display: 'block', height: 793 }}
            >
              <section className="gloriousSearch">
                <input
                  placeholder="Найти ТС..."
                  type="text"
                  className="gloriousSearchInput"
                />
                <button rel="stop" />
                <button rel="delete" />
                <button rel="search" />
              </section>
              <section className="gloriousVehicleFilter row">
                <div
                  rel="green"
                  count={7}
                  className="col txc2 tree-filter green"
                  title="Данные поступили за последние 20 мин"
                >
                  <div>
                    <span />
                  </div>
                </div>
                <div
                  rel="yellow"
                  count={5}
                  className="col txc2 tree-filter yellow"
                  title="Последние данные поступили от 20 мин до 24 ч 01 мин назад"
                >
                  <div>
                    <span />
                  </div>
                </div>
                <div
                  rel="red"
                  count={4}
                  className="col txc2 tree-filter red"
                  title="Данные не поступали более 24 ч 01 мин"
                >
                  <div>
                    <span />
                  </div>
                </div>
                <div
                  rel="black"
                  count={15}
                  className="col txc2 tree-filter black"
                  title="Данные отсутствуют в программе"
                >
                  <div>
                    <span />
                  </div>
                </div>
                <div
                  rel="blue"
                  count={31}
                  className="col txc2 tree-filter blue ui-state-active"
                  title="Отобразить все ТС"
                >
                  <div>
                    <span />
                  </div>
                </div>
              </section>
              <section className="gloriousForest">
                <ul className="gloriousRoot" count={31}>
                  <li className="gloriousRootLeaf withFilters">
                    <div className="col">
                      <a
                        title="Выделить все объекты"
                        className="gloriousCheckbox"
                      >
                        <span />
                      </a>
                    </div>
                    <div className="col">
                      <a
                        style={{}}
                        title="Скрыть группы"
                        className="gloriousFilter"
                      >
                        <span />
                      </a>
                    </div>
                    <div className="col">
                      <a title="Создать группу" className="gloriousPlus">
                        <span />
                      </a>
                    </div>
                    <div className="col" />
                    <div className="col">
                      <a
                        className="gloriousOpener"
                        style={{}}
                        title="Развернуть все группы"
                      >
                        <span />
                      </a>
                    </div>
                  </li>
                </ul>
                <ul
                  className="gloriousTree"
                  style={{ height: 669, maxHeight: '658.734px' }}
                >
                  <li className="gloriousGroup" count={3}>
                    <a className="gloriousOpener">
                      <span />
                    </a>
                    <a className="gloriousCheckbox group ">
                      <span />
                    </a>
                    <span className="gloriousTitle">
                      <span className="txc2">AGRO</span>
                    </span>
                    <ul style={{ display: 'none' }}>
                      <li className="gloriousObject                   unknown                       FAS">
                        <a className="gloriousCheckbox object ">
                          <span />
                        </a>
                        <span className="gloriousTitle">
                          <span className="txc2">Трактор</span>
                        </span>
                      </li>
                      <li className="gloriousObject                   unknown                       FAS">
                        <a className="gloriousCheckbox object ">
                          <span />
                        </a>
                        <span className="gloriousTitle">
                          <span className="txc2">Трактор 1</span>
                        </span>
                      </li>
                      <li className="gloriousObject                   unknown                       FAS">
                        <a className="gloriousCheckbox object ">
                          <span />
                        </a>
                        <span className="gloriousTitle">
                          <span className="txc2">Трактор 2</span>
                        </span>
                      </li>
                    </ul>
                  </li>
                  <li className="gloriousGroup" count={2}>
                    <a className="gloriousOpener">
                      <span />
                    </a>
                    <a className="gloriousCheckbox group ">
                      <span />
                    </a>
                    <span className="gloriousTitle">
                      <span className="txc2">CAN bus</span>
                    </span>
                    <ul style={{ display: 'none' }}>
                      <li className="gloriousObject                   unknown                       FAS">
                        <a className="gloriousCheckbox object ">
                          <span />
                        </a>
                        <span className="gloriousTitle">
                          <span className="txc2">Камаз (CAN)</span>
                        </span>
                      </li>
                      <li className="gloriousObject                   unknown                       FAS">
                        <a className="gloriousCheckbox object ">
                          <span />
                        </a>
                        <span className="gloriousTitle">
                          <span className="txc2">Камаз Самосвал (CAN)</span>
                        </span>
                      </li>
                    </ul>
                  </li>
                  <li className="gloriousGroup" count={1}>
                    <a className="gloriousOpener">
                      <span />
                    </a>
                    <a className="gloriousCheckbox group ">
                      <span />
                    </a>
                    <span className="gloriousTitle">
                      <span className="txc2">Fuel per kg</span>
                    </span>
                    <ul style={{ display: 'none' }}>
                      <li className="gloriousObject                   unknown                       FAS">
                        <a className="gloriousCheckbox object ">
                          <span />
                        </a>
                        <span className="gloriousTitle">
                          <span className="txc2">ТС_КГ</span>
                        </span>
                      </li>
                    </ul>
                  </li>
                  <li className="gloriousGroup" count={4}>
                    <a className="gloriousOpener">
                      <span />
                    </a>
                    <a className="gloriousCheckbox group ">
                      <span />
                    </a>
                    <span className="gloriousTitle">
                      <span className="txc2">IQFreeze</span>
                    </span>
                    <ul style={{ display: 'none' }}>
                      <li className="gloriousObject                   unknown                       FAS">
                        <a className="gloriousCheckbox object ">
                          <span />
                        </a>
                        <span className="gloriousTitle">
                          <span className="txc2">реф ам501433</span>
                        </span>
                      </li>
                      <li className="gloriousObject                   unknown                       FAS">
                        <a className="gloriousCheckbox object ">
                          <span />
                        </a>
                        <span className="gloriousTitle">
                          <span className="txc2">реф ам501533</span>
                        </span>
                      </li>
                      <li className="gloriousObject                   unknown                       FAS">
                        <a className="gloriousCheckbox object ">
                          <span />
                        </a>
                        <span className="gloriousTitle">
                          <span className="txc2">реф ам501633</span>
                        </span>
                      </li>
                      <li className="gloriousObject                   unknown                       FAS">
                        <a className="gloriousCheckbox object ">
                          <span />
                        </a>
                        <span className="gloriousTitle">
                          <span className="txc2">реф ам503333</span>
                        </span>
                      </li>
                    </ul>
                  </li>
                  <li className="gloriousGroup" count={3}>
                    <a className="gloriousOpener">
                      <span />
                    </a>
                    <a className="gloriousCheckbox group ">
                      <span />
                    </a>
                    <span className="gloriousTitle">
                      <span className="txc2">Safe Driving</span>
                    </span>
                    <ul style={{ display: 'none' }}>
                      <li className="gloriousObject                   unknown                       FAS">
                        <a className="gloriousCheckbox object ">
                          <span />
                        </a>
                        <span className="gloriousTitle">
                          <span className="txc2">SafeDrivingVehicleBot</span>
                        </span>
                      </li>
                      <li className="gloriousObject                   unknown                       FAS">
                        <a className="gloriousCheckbox object ">
                          <span />
                        </a>
                        <span className="gloriousTitle">
                          <span className="txc2">ТС2</span>
                        </span>
                      </li>
                      <li className="gloriousObject                   unknown                       FAS">
                        <a className="gloriousCheckbox object ">
                          <span />
                        </a>
                        <span className="gloriousTitle">
                          <span className="txc2">ТС3</span>
                        </span>
                      </li>
                    </ul>
                  </li>
                  <li className="gloriousGroup" count={1}>
                    <a className="gloriousOpener">
                      <span />
                    </a>
                    <a className="gloriousCheckbox group ">
                      <span />
                    </a>
                    <span className="gloriousTitle">
                      <span className="txc2">TPMS</span>
                    </span>
                    <ul style={{ display: 'none' }}>
                      <li className="gloriousObject                   unknown                       FAS">
                        <a className="gloriousCheckbox object ">
                          <span />
                        </a>
                        <span className="gloriousTitle">
                          <span className="txc2">TPMS</span>
                        </span>
                        <div className="vehicle-icon-container">
                          <a className="vehicle-tree-oko">
                            <span />
                          </a>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li className="gloriousGroup" count={3}>
                    <a className="gloriousOpener">
                      <span />
                    </a>
                    <a className="gloriousCheckbox group ">
                      <span />
                    </a>
                    <span className="gloriousTitle">
                      <span className="txc2">Video</span>
                    </span>
                    <ul style={{ display: 'none' }}>
                      <li className="gloriousObject                   unknown                       FAS">
                        <a className="gloriousCheckbox object ">
                          <span />
                        </a>
                        <span className="gloriousTitle">
                          <span className="txc2">АВТОТОПЛИВОЗАПРАВЩИК</span>
                        </span>
                        <div className="vehicle-icon-container">
                          <a className="vehicle-tree-oko">
                            <span />
                          </a>
                        </div>
                      </li>
                      <li className="gloriousObject                   unknown                       FAS">
                        <a className="gloriousCheckbox object ">
                          <span />
                        </a>
                        <span className="gloriousTitle">
                          <span className="txc2">Видео_автокран</span>
                        </span>
                        <div className="vehicle-icon-container">
                          <a className="vehicle-tree-oko">
                            <span />
                          </a>
                        </div>
                      </li>
                      <li className="gloriousObject                   unknown                       FAS">
                        <a className="gloriousCheckbox object ">
                          <span />
                        </a>
                        <span className="gloriousTitle">
                          <span className="txc2">
                            Видео_специальный автокран
                          </span>
                        </span>
                        <div className="vehicle-icon-container">
                          <a className="vehicle-tree-oko">
                            <span />
                          </a>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li className="gloriousGroup" count={1}>
                    <a className="gloriousOpener">
                      <span />
                    </a>
                    <a className="gloriousCheckbox group ">
                      <span />
                    </a>
                    <span className="gloriousTitle">
                      <span className="txc2">АЗС</span>
                    </span>
                    <ul style={{ display: 'none' }}>
                      <li className="gloriousObject                   unknown                       FTC">
                        <a className="gloriousCheckbox object ">
                          <span />
                        </a>
                        <span className="gloriousTitle">
                          <span className="txc2">АЗС</span>
                        </span>
                        <div className="vehicle-icon-container">
                          <a className="vehicle-tree-ftc">
                            <span />
                          </a>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li className="gloriousGroup" count={1}>
                    <a className="gloriousOpener">
                      <span />
                    </a>
                    <a className="gloriousCheckbox group ">
                      <span />
                    </a>
                    <span className="gloriousTitle">
                      <span className="txc2">АТЗ</span>
                    </span>
                    <ul style={{ display: 'none' }}>
                      <li className="gloriousObject                   unknown                       FTC">
                        <a className="gloriousCheckbox object ">
                          <span />
                        </a>
                        <span className="gloriousTitle">
                          <span className="txc2">АТЗ</span>
                        </span>
                        <div className="vehicle-icon-container">
                          <a className="vehicle-tree-ftc">
                            <span />
                          </a>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li className="gloriousGroup" count={2}>
                    <a className="gloriousOpener">
                      <span />
                    </a>
                    <a className="gloriousCheckbox group ">
                      <span />
                    </a>
                    <span className="gloriousTitle">
                      <span className="txc2">Группа тест</span>
                    </span>
                    <ul style={{ display: 'none' }}>
                      <li className="gloriousGroup" count={2}>
                        <a className="gloriousOpener">
                          <span />
                        </a>
                        <a className="gloriousCheckbox group ">
                          <span />
                        </a>
                        <span className="gloriousTitle">
                          <span className="txc2">Подгруппа</span>
                        </span>
                        <ul style={{ display: 'none' }}>
                          <li className="gloriousGroup" count={2}>
                            <a className="gloriousOpener">
                              <span />
                            </a>
                            <a className="gloriousCheckbox group ">
                              <span />
                            </a>
                            <span className="gloriousTitle">
                              <span className="txc2">Подгруппа 1</span>
                            </span>
                            <ul style={{ display: 'none' }}>
                              <li className="gloriousGroup" count={2}>
                                <a className="gloriousOpener">
                                  <span />
                                </a>
                                <a className="gloriousCheckbox group ">
                                  <span />
                                </a>
                                <span className="gloriousTitle">
                                  <span className="txc2">Подгруппа 2</span>
                                </span>
                                <ul style={{ display: 'none' }}>
                                  <li className="gloriousGroup" count={2}>
                                    <a className="gloriousOpener">
                                      <span />
                                    </a>
                                    <a className="gloriousCheckbox group ">
                                      <span />
                                    </a>
                                    <span className="gloriousTitle">
                                      <span className="txc2">Подгруппа 3</span>
                                    </span>
                                    <ul style={{ display: 'none' }}>
                                      <li className="gloriousGroup" count={0}>
                                        <a className="gloriousVoid">
                                          <span />
                                        </a>
                                        <a className="gloriousCheckbox group ">
                                          <span />
                                        </a>
                                        <span className="gloriousTitle">
                                          <span className="txc2">
                                            Подгруппа 1111
                                          </span>
                                        </span>
                                        <ul style={{ display: 'none' }} />
                                      </li>
                                      <li className="gloriousObject                                         FAS green">
                                        <a className="gloriousCheckbox object ">
                                          <span />
                                        </a>
                                        <span className="gloriousTitle">
                                          <span className="txc2">DAF 1</span>
                                        </span>
                                      </li>
                                      <li className="gloriousObject                                         FAS green">
                                        <a className="gloriousCheckbox object ">
                                          <span />
                                        </a>
                                        <span className="gloriousTitle">
                                          <span className="txc2">DAF 2</span>
                                        </span>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li className="gloriousObject                                         FAS green">
                    <a className="gloriousCheckbox object ">
                      <span />
                    </a>
                    <span className="gloriousTitle">
                      <span className="txc2">DAF 1</span>
                    </span>
                  </li>
                  <li className="gloriousObject                                         FAS green">
                    <a className="gloriousCheckbox object ">
                      <span />
                    </a>
                    <span className="gloriousTitle">
                      <span className="txc2">DAF 2</span>
                    </span>
                  </li>
                  <li className="gloriousObject                                         FAS black">
                    <a className="gloriousCheckbox object ">
                      <span />
                    </a>
                    <span className="gloriousTitle">
                      <span className="txc2">FORD</span>
                    </span>
                  </li>
                  <li className="gloriousObject                                         FAS black">
                    <a className="gloriousCheckbox object ">
                      <span />
                    </a>
                    <span className="gloriousTitle">
                      <span className="txc2">MAN</span>
                    </span>
                  </li>
                  <li className="gloriousObject                                         FAS green">
                    <a className="gloriousCheckbox object ">
                      <span />
                    </a>
                    <span className="gloriousTitle">
                      <span className="txc2">SHACMAH</span>
                    </span>
                  </li>
                  <li className="gloriousObject                                         FAS black">
                    <a className="gloriousCheckbox object ">
                      <span />
                    </a>
                    <span className="gloriousTitle">
                      <span className="txc2">VOLVO</span>
                    </span>
                  </li>
                  <li className="gloriousObject                                         FAS green">
                    <a className="gloriousCheckbox object ">
                      <span />
                    </a>
                    <span className="gloriousTitle">
                      <span className="txc2">ДГУ_1</span>
                    </span>
                  </li>
                  <li className="gloriousObject                                         FAS yellow">
                    <a className="gloriousCheckbox object ">
                      <span />
                    </a>
                    <span className="gloriousTitle">
                      <span className="txc2">ДГУ_2</span>
                    </span>
                  </li>
                  <li className="gloriousObject                                         FAS black">
                    <a className="gloriousCheckbox object ">
                      <span />
                    </a>
                    <span className="gloriousTitle">
                      <span className="txc2">ДГУ_4</span>
                    </span>
                    <div className="vehicle-icon-container">
                      <a className="vehicle-tree-oko">
                        <span />
                      </a>
                    </div>
                  </li>
                  <li className="gloriousObject                                         FAS green">
                    <a className="gloriousCheckbox object ">
                      <span />
                    </a>
                    <span className="gloriousTitle">
                      <span className="txc2">ДГУ_5</span>
                    </span>
                  </li>
                  <li className="gloriousObject                                         FAS yellow">
                    <a className="gloriousCheckbox object ">
                      <span />
                    </a>
                    <span className="gloriousTitle">
                      <span className="txc2">КАМАЗ 1</span>
                    </span>
                  </li>
                  <li className="gloriousObject                                         FAS black">
                    <a className="gloriousCheckbox object ">
                      <span />
                    </a>
                    <span className="gloriousTitle">
                      <span className="txc2">МАЗ</span>
                    </span>
                  </li>
                </ul>
              </section>
            </div>
            <div
              className="txc3 omn-tree drivers"
              style={{ display: 'none', height: 793 }}
            />
            <div
              className="txc3 omn-tree geozones"
              style={{ display: 'none', height: 793 }}
            />
            <div
              className="txc3 omn-tree routes"
              style={{ display: 'none', height: 793 }}
            />
          </div>
        </div>
      </div>
      <div style={{ display: 'none' }} />
      <div className="">
        <div className="omn-panel-toggler">
          <span className="icon-container">
            <i className="icon icon-left-double" />
          </span>
        </div>
      </div>
      <div className="">
        <div
          className="left-panel-resizer"
          style={{ left: 257, width: 5, height: '100vh', top: 0 }}
        />
      </div>
    </div>
    <div className="omn-topPanel bgc2 brd1" style={{ paddingLeft: 260, width: '100vw' }}>
      <div className="tab-container brd1">
        <div style={{ marginLeft: 0 }}>
          <span
            title="Меню выбора отчётов"
            className="icon-container openNewTab"
          >
            <i className="icon icon-plus" />
          </span>
          <span className="openNewTab-tip txc1">Добавить отчёт</span>
        </div>
        <span className="icon-container tab-scroller left">
          <i className="icon icon-left-scroller" />
        </span>
        <span
          className="icon-container tab-scroller right"
          style={{ right: 90 }}
        >
          <i className="icon icon-right-scroller" />
        </span>
      </div>
      <div className="omn-servicePanel">
        <span title="Меню выбора отчётов" className="icon-container openNewTab">
          <i className="icon icon-plus" />
        </span>
        {/* <div className="top-bar-iconcontainer omn-map-settings">
          <div className="modal-content-custom ">
            <p>Приложения теперь находятся здесь</p>
            <button>OK</button>
          </div>
          <span className="txc1 app-link-no-close" style={{ fontSize: 13 }}>
            Приложения
          </span>
          <div style={{ display: 'none' }} />
        </div> */}
        <span title="Уведомления" className="icon-container notification-icon">
          <i className="icon-message" />
          <div style={{ display: 'none' }} />
        </span>
        <span title="Помощь" className="icon-container">
          <i className="icon icon-help" />
        </span>
      </div>
      <div style={{ display: 'none' }} />
      <div style={{ display: 'none' }} />
    </div>
    <div className="">
      <div
        className="omn-report-container bgc2"
        style={{ width: '100vw', paddingLeft: 260 }}
      >
        <div className="omn-quick-select brd1" style={{ paddingTop: 245 }}>
          <div className="omn-quick-select-reports">
            <label>Популярные:</label>
            <div className="row">
              <div className="col quick-report-wrapper">
                <div>
                  <label>Трек</label>
                  <div className="omn-quickselect-placeholder map2" />
                </div>
              </div>
              <div className="col quick-report-wrapper">
                <div>
                  <label>Местоположение</label>
                  <div className="omn-quickselect-placeholder location" />
                </div>
              </div>
              <div className="col quick-report-wrapper">
                <div>
                  <label>Статистика</label>
                  <div className="omn-quickselect-placeholder groupstat" />
                </div>
              </div>
            </div>
          </div>
          <div className="omn-quick-select-reports">
            <label>Рабочие столы:</label>
            <div className="row">
              <div className="col quick-report-wrapper">
                <div>
                  <label>Рабочий стол Руководителя</label>
                  <div className="omn-quickselect-placeholder managerReport" />
                </div>
              </div>
              <div className="col quick-report-wrapper">
                <div>
                  <label>Трек + Объём топлива</label>
                  <div className="omn-quickselect-placeholder map2_fuellevels" />
                </div>
              </div>
              <div className="col quick-report-wrapper">
                <div>
                  <label>Трек + Скорость</label>
                  <div className="omn-quickselect-placeholder map2_speed" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style={{ display: 'none' }} />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div className="omn-float-window" />
    <div style={{ display: 'none' }} />
    <div style={{ display: 'none' }} />
    <div />
    {/* <div id="omn-loading-block" style={{ width: 1195, height: 1007 }}>
      <div>
        <p>Обработка запроса...</p>
      </div>
    </div> */}
    <div />
  </div>
</div>

  )
}

export default Tracking
