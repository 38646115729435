import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as FA from '@fortawesome/free-solid-svg-icons'
import { getUsers, getKeys, generateRegKey } from '../http/API'
import { Input, Alert, Button, ControlLabel } from 'rsuite'
import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'
import { useTranslation } from 'react-i18next'

const AdminSettings = () => {
  const { t, i18n } = useTranslation()
  const [curTheme, setTheme] = React.useState(Cookies.get('theme') === 'black' ? 'black' : 'white')
  if (curTheme === 'white') {
    document.documentElement.style.setProperty('--theme-main', 'darkblue')
    document.documentElement.style.setProperty('--theme-text', 'white')
    document.documentElement.style.setProperty('--theme-secondary', 'lightgray')
    document.documentElement.style.setProperty('--theme-contrast', 'black')
  } else if (curTheme === 'black') {
    document.documentElement.style.setProperty('--theme-main', 'lime')
    document.documentElement.style.setProperty('--theme-text', 'black')
    document.documentElement.style.setProperty('--theme-secondary', 'darkslategray')
    document.documentElement.style.setProperty('--theme-contrast', 'white')
  }
  const themeChangeFunc = () => {
    if (curTheme === 'black') {
      Cookies.set('theme', 'white')
      setTheme('white')
      document.documentElement.style.setProperty('--theme-main', 'darkblue')
      document.documentElement.style.setProperty('--theme-text', 'white')
      document.documentElement.style.setProperty('--theme-secondary', 'lightgray')
      document.documentElement.style.setProperty('--theme-contrast', 'black')
    } else {
      Cookies.set('theme', 'black')
      setTheme('black')
      document.documentElement.style.setProperty('--theme-main', 'lime')
      document.documentElement.style.setProperty('--theme-text', 'black')
      document.documentElement.style.setProperty('--theme-secondary', 'darkslategray')
      document.documentElement.style.setProperty('--theme-contrast', 'white')
    }
  }
  const [data, setData] = useState({
    role: '',
    userid: jwtDecode(Cookies.get('token')).id,
    key: ''
  })
  const [updateKey, update] = useState(0)
  const [rkeys, setRKeys] = useState([])
  const [users, setUserlist] = useState([])
  useEffect(() => {
  }, [updateKey])
  useEffect(async () => {
    const fieldsList = await getKeys()
    if (fieldsList.response.success) {
      setRKeys(fieldsList.response.fields)
    }
    const usersList = await getUsers()
    if (usersList.response.success) {
      setUserlist(usersList.response.users)
    }
  }, [updateKey])
  const newKey = async () => {
    try {
      if (document.getElementById('key').value !== '' && document.getElementById('role').value !== '') {
        const resp = await generateRegKey(data).then((res) => {
          const response = res.response
          if (response.success) {
            Alert.success(response.message)
            update(updateKey + 1)
          } else {
            Alert.error(response.message)
          }
        })
      } else {
        Alert.error(t('error.adminkey'))
      }
    } catch (e) {
      Alert.error(t('error.connectionerror'))
      console.log(e)
    }
  }
  return (
    <div className='data calcmodal-body'>
      <a style={{ width: 'fit-content', float: 'left', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' onClick={themeChangeFunc}>
        <FontAwesomeIcon icon={FA.faPalette} />
      </a>
      <a style={{ width: 'fit-content', float: 'right', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' href='/profile'>
        <FontAwesomeIcon icon={FA.faHome} />
      </a>
      <a style={{ width: 'fit-content', float: 'right', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' onClick={() => i18n.changeLanguage(i18n.language === 'ru' ? 'kz' : i18n.language === 'kz' ? 'en' : 'ru')}>
        <h5 style={{ margin: 'auto' }}>{i18n.language.toUpperCase()}</h5>
      </a>
      <h3 align='center'>{t('adminpanel.title')}</h3>
      <hr />
      <p>{t('adminpanel.regkey.title')}</p>
      <br />
      <div style={{ display: 'flex' }}>
        <Input id='key' style={{ width: '30%', float: 'left' }} placeholder={t('adminpanel.regkey.placeholder')} onChange={e => setData({ ...data, key: e })} />
        <button id='btn-confirm' style={{ width: '20%', float: 'center' }} onClick={newKey}>{t('adminpanel.regkey.button')}</button>
        <Input
          id='role' list='roles' style={{ width: '30%' }} placeholder={t('adminpanel.role.placeholder')} onChange={e => {
            setData({ ...data, role: e })
          }}
        />
        <datalist id='roles'>
          <option value='ADMIN'>{t('common.usertype.admin')}</option>
          <option value='USER'>{t('common.usertype.user')}</option>
        </datalist>
      </div>
      <br />
      <hr />
      <p>{t('adminpanel.regkey.list')} </p>
      {rkeys.length === 0
        ? t('adminpanel.nokeys')
        : rkeys.map((f, key) => {
          return (
            <div className='user-panel' key={key} style={{ cursor: 'default', background: f.used ? 'red' : 'lime' }}>
              {t('common.key')} ({f.keytype === 'ADMIN' ? t('common.usertype.admin_adj') : t('common.usertype.user_adj')}) &quot;{f.key}&quot;- {t('adminpanel.regkey.createdby')} {f.email} ({f.name}), {f.updatedAt.replace('T', ' ').slice(0, -8)}
            </div>
          )
        })}
      <hr />
      <p>{t('adminpanel.users.title')}</p>
      {users?.length === 0
        ? t('adminpanel.users.notfound')
        : users?.map((f, key) => {
          return (
            <div className='user-panel' key={key} style={{ cursor: 'default', background: f.role === 'ADMIN' ? 'yellow' : 'orange' }}>
              {t('common.names')}: {f.first_name} {f.last_name}<br />
              ID: {f.id}<br />
              {t('common.email')}: {f.email}<br />
              {t('common.place')}: {f.place}<br />
              {t('common.key')}: {f.regkey}<br />
              {t('adminpanel.role.placeholder')}: {f.role}
            </div>
          )
        })}
      <hr />
    </div>
  )
}

export default AdminSettings
