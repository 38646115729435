import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as FA from '@fortawesome/free-solid-svg-icons'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'
import { Alert, Button, Input } from 'rsuite'
import { addRecommendation, getFields, getGeoJSON, getRecommendation, removeRecommendation } from '../http/API'
import ChartModal from './charts/Chart'

const Recommend = ({ slave, fieldId_master, adClass, hideFunc }) => {
  const [fields, updateFields] = useState()
  const [fieldsData, updateFieldsData] = useState()
  const [fieldName, setFieldName] = useState()
  const [dataAnalysisName, setDataName] = useState()
  const [data, setData] = useState({
    soiltype: null,
    humus: null,
    nitrogen: null,
    phosphorus: null,
    potassium: null,
    pH: null,
    yield: null,
    density: null,
    prev: null,
    rotation: null,
    time: null,
    mods: null,
    type: null,
    herbs: null,
    pests: null
  })
  const [fieldsArray, setFields] = useState([])
  const [updateKey, update] = useState(0)
  const [checker, sureness] = useState(false)
  const deleteRec = async (id) => {
    if (!checker) {
      Alert.warning('Нажмите еще раз для подтверждения!')
      sureness(true)
    } else {
      sureness(false)
      try {
        console.log(id)
        const resp = await removeRecommendation(id).then((res) => {
          const response = res.response
          if (response.success) {
            Alert.success(response.message)
            update(() => updateKey + 1)
          } else {
            Alert.error(response.message)
          }
        })
      } catch (e) {
        Alert.error('Ошибка соединения с сервером!')
      }
    }
  }
  useEffect(async () => {
    const res = await getGeoJSON(true)
    const res2 = await getFields()
    console.log(res2)
    const p = res.response.fields
    updateFieldsData(res2.response.fields)
    updateFields(p.map((e) => {
      return e.properties.name
    }))
  }
  , [])
  useEffect(async () => {
    const resp = await getRecommendation()
    if (resp.response.success) {
      setFields(resp.response.recs)
    }
  }, [updateKey])
  const { t, i18n } = useTranslation()
  const saveRec = async () => {
    if (fieldName) {
      recOut()
      const res = await addRecommendation({
        soiltype: window.soilType,
        humus: document.getElementById('firstNumber').value,
        nitrogen: document.getElementById('secondNumber').value,
        phosphorus: document.getElementById('thirdNumber').value,
        potassium: document.getElementById('fourNumber').value,
        pH: document.getElementById('fiveNumber').value,
        yield: document.getElementById('sixNumber').value,
        density: document.getElementById('sevenNumber').value,
        prev: document.getElementById('select1').value,
        rotation: document.getElementById('select2').value,
        time: window.num10,
        mods: window.num11,
        type: window.num12,
        herbs: window.num13,
        pests: window.num14,
        field_id: document.getElementById('fieldname').value
      }).then(e => {
        if (e.response.success) {
          Alert.success(e.response.message)
          update(() => updateKey + 1)
        } else {
          Alert.error(e.response.message)
        }
      })
    } else {
      Alert.error('Введите имя поля!')
    }
  }
  const soilStandarts = {
    humus: {
      type1: {
        max: 7,
        palegreen: 6,
        yellowgreen: 4,
        goldenrod: 3.5,
        orange: 2.5
      },
      type2: {
        max: 4,
        palegreen: 3.5,
        yellowgreen: 3,
        goldenrod: 1.5,
        orange: 0.5
      },
      type3: {
        max: 4,
        palegreen: 3.5,
        yellowgreen: 3,
        goldenrod: 1.5,
        orange: 0.5
      }
    },
    nitrogen: {
      type1: {
        max: 150,
        palegreen: 120,
        yellowgreen: 100,
        goldenrod: 50,
        orange: 30
      },
      type2: {
        max: 150,
        palegreen: 120,
        yellowgreen: 100,
        goldenrod: 50,
        orange: 30
      },
      type3: {
        max: 150,
        palegreen: 120,
        yellowgreen: 100,
        goldenrod: 50,
        orange: 30
      }
    },
    phosphorus: {
      type1: {
        max: 60,
        palegreen: 45,
        yellowgreen: 30,
        goldenrod: 15,
        orange: 10
      },
      type2: {
        max: 60,
        palegreen: 45,
        yellowgreen: 30,
        goldenrod: 15,
        orange: 10
      },
      type3: {
        max: 60,
        palegreen: 45,
        yellowgreen: 30,
        goldenrod: 15,
        orange: 10
      }
    },
    potassium: {
      type1: {
        max: 500,
        palegreen: 400,
        yellowgreen: 300,
        goldenrod: 200,
        orange: 100
      },
      type2: {
        max: 400,
        palegreen: 300,
        yellowgreen: 200,
        goldenrod: 100,
        orange: 50
      },
      type3: {
        max: 400,
        palegreen: 300,
        yellowgreen: 200,
        goldenrod: 100,
        orange: 50
      }
    },
    pH: {
      type1: {
        max: 7.5,
        palegreen: 7,
        yellowgreen: 6.9,
        goldenrod: 6.8,
        orange: 6
      },
      type2: {
        max: 7.5,
        palegreen: 7,
        yellowgreen: 6.9,
        goldenrod: 6.8,
        orange: 6
      },
      type3: {
        max: 7.5,
        palegreen: 7,
        yellowgreen: 6.9,
        goldenrod: 6.8,
        orange: 6
      }
    },
    yield: {
      type1: {
        max: 55,
        palegreen: 30,
        yellowgreen: 20,
        goldenrod: 10,
        orange: 5
      },
      type2: {
        max: 55,
        palegreen: 35,
        yellowgreen: 27,
        goldenrod: 20,
        orange: 10
      },
      type3: {
        max: 550,
        palegreen: 320,
        yellowgreen: 200,
        goldenrod: 100,
        orange: 50
      }
    },
    density: {
      type1: {
        max: 55,
        palegreen: 45,
        yellowgreen: 35,
        goldenrod: 20,
        orange: 10
      },
      type2: {
        max: 55,
        palegreen: 45,
        yellowgreen: 27,
        goldenrod: 20,
        orange: 10
      },
      type3: {
        max: 55,
        palegreen: 45,
        yellowgreen: 27,
        goldenrod: 20,
        orange: 10
      }
    }
  }
  // var num1 = 0
  // const [num2, setNum2] = useState(0)
  // const [num3, setNum3] = useState(0)
  // const [num4, setNum4] = useState(0)
  // const [num5, setNum5] = useState(0)
  // const [num6, setNum6] = useState(0)
  // const [num7, setNum7] = useState('')
  // const [num8, setNum8] = useState(0)
  // const [num9, setNum9] = useState('')
  // const [num10, setNum10] = useState('')
  // const [num11, setNum11] = useState('')
  // const [num12, setNum12] = useState('')
  // const [num13, setNum13] = useState('')
  // const [num14, setNum14] = useState('')

  // const [rec1, setRec1] = useState('Не введены данные')
  // const [rec2, setRec2] = useState('Не введены данные')
  // const [rec3, setRec3] = useState('Не введены данные')
  // const [rec4, setRec4] = useState('Не введены данные')
  // const [rec5, setRec5] = useState('Не введены данные')
  // const [rec6, setRec6] = useState('Не введены данные')
  // const [rec7, setRec7] = useState('Не введены данные')
  // const [rec8, setRec8] = useState('Рекомендованы 4,5-5,0-5,5-6,0-6,5-7,0. Глубина заделки семян 4-7 см в зависимости от механического состава почвы, влажности почвы и крупности семян. Посев производить пневматическими сеялками точного высева. Семена сортов подсолнечника высевают на глубину 5-8 см с отклонением не более ±1 см, семена гибридов на глубину 4-6 см. Скорость движения посевного агрегата – 5-6 км/час. ')
  // const [rec9, setRec9] = useState('Не введены данные')
  // const [rec10, setRec10] = useState('Не введены данные')
  // const [rec11, setRec11] = useState('Не введены данные')
  // const [rec12, setRec12] = useState('Не введены данные')
  // const [rec13, setRec13] = useState('Не введены данные')
  // const [rec14, setRec14] = useState('Не введены данные')

  // const [recColor1, setRecColor1] = useState('white')
  // const [recColor2, setRecColor2] = useState('white')
  // const [recColor3, setRecColor3] = useState('white')
  // const [recColor4, setRecColor4] = useState('white')
  // const [recColor5, setRecColor5] = useState('white')
  // const [recColor6, setRecColor6] = useState('white')
  // const [recColor7, setRecColor7] = useState('white')
  // const [recColor8, setRecColor8] = useState('white')
  // const [recColor9, setRecColor9] = useState('white')
  // const [recColor10, setRecColor10] = useState('white')
  // const [recColor11, setRecColor11] = useState('white')
  // const [recColor12, setRecColor12] = useState('white')
  // const [recColor13, setRecColor13] = useState('white')
  // const [recColor14, setRecColor14] = useState('white')
  let num1 = 0
  let num2 = 0
  let num3 = 0
  let num4 = 0
  let num5 = 0
  let num6 = 0
  let num7 = 0
  let num8 = ''
  let num9 = ''
  let num10 = ''
  let num11 = ''
  let num12 = ''
  let num13 = ''
  let num14 = ''
  let rec1 = t('recommend.nodata')
  let rec2 = t('recommend.nodata')
  let rec3 = t('recommend.nodata')
  let rec4 = t('recommend.nodata')
  let rec5 = t('recommend.nodata')
  let rec6 = t('recommend.nodata')
  let rec7 = t('recommend.nodata')
  let rec8 = t('rec.1')
  let rec9 = t('recommend.nodata')
  let rec10 = t('recommend.nodata')
  let rec11 = t('recommend.nodata')
  let rec12 = t('recommend.nodata')
  let rec13 = t('recommend.nodata')
  let rec14 = 'Не введены данные'
  let rec1Color = 'white'
  let rec2Color = 'white'
  let rec3Color = 'white'
  let rec4Color = 'white'
  let rec5Color = 'white'
  let rec6Color = 'white'
  let rec7Color = 'white'
  let rec8Color = 'white'
  let rec9Color = 'white'
  let rec10Color = 'white'
  let rec11Color = 'white'
  let rec12Color = 'white'
  let rec13Color = 'white'
  let rec14Color = 'white'
  function resetInput () {
    window.soilType = document.getElementById('cropSelect').value
    // setRec8('Рекомендованы 4,5-5,0-5,5-6,0-6,5-7,0. Глубина заделки семян 4-7 см в зависимости от механического состава почвы, влажности почвы и крупности семян. Посев производить пневматическими сеялками точного высева. Семена сортов подсолнечника высевают на глубину 5-8 см с отклонением не более ±1 см, семена гибридов на глубину 4-6 см. Скорость движения посевного агрегата – 5-6 км/час. ')
    // setRecColor1('white')
    // setRecColor2('white')
    // setRecColor3('white')
    // setRecColor4('white')
    // setRecColor5('white')
    // setRecColor6('white')
    // setRecColor7('white')
    // setRecColor8('white')
    // setRecColor9('white')
    // setRecColor10('white')
    // setRecColor11('white')
    // setRecColor12('white')
    // setRecColor13('white')
    // setRecColor14('white')
    num1 = num2 = num3 = num4 = num5 = num6 = num8 = 0
    num7 = num9 = num10 = num11 = num12 = num13 = num14 = ''
    rec1 = rec2 = rec3 = rec4 = rec5 = rec6 = rec7 = rec9 = rec10 = rec11 = rec12 = rec13 = rec14 = t('recommend.nodata')
    rec8 = t('rec.1')
    rec1Color = rec2Color = rec3Color = rec4Color = rec5Color = rec6Color = rec7Color = rec8Color = rec9Color = rec10Color = rec11Color = rec12Color = rec13Color = rec14Color = 'white'
    // setNum1(0)
    // setNum2(0)
    // setNum3(0)
    // setNum4(0)
    // setNum5(0)
    // setNum6(0)
    // setNum7(0)
    // setNum8(0)
    // setNum9(0)
    // setNum10(0)
    // setNum11(0)
    // setNum12(0)
    // setNum13(0)
    // setNum14(0)
    document.getElementById('result-div').style.display = 'none'

    document.getElementById('result1f').innerHTML = document.getElementById('firstNumber').value = ''
    document.getElementById('result1f').style.backgroundColor = document.getElementById('firstNumber').style.backgroundColor = 'white'

    document.getElementById('result2').innerHTML = document.getElementById('secondNumber').value = ''
    document.getElementById('result2').style.backgroundColor = document.getElementById('secondNumber').style.backgroundColor = 'white'

    document.getElementById('result3').innerHTML = document.getElementById('thirdNumber').value = ''
    document.getElementById('result3').style.backgroundColor = document.getElementById('thirdNumber').style.backgroundColor = 'white'

    document.getElementById('result4').innerHTML = document.getElementById('fourNumber').value = ''
    document.getElementById('result4').style.backgroundColor = document.getElementById('fourNumber').style.backgroundColor = 'white'

    document.getElementById('result5').innerHTML = document.getElementById('fiveNumber').value = ''
    document.getElementById('result5').style.backgroundColor = document.getElementById('fiveNumber').style.backgroundColor = 'white'

    document.getElementById('result6').innerHTML = document.getElementById('sixNumber').value = ''
    document.getElementById('result6').style.backgroundColor = document.getElementById('sixNumber').style.backgroundColor = 'white'

    document.getElementById('sevenNumber').value = ''
    document.getElementById('sevenNumber').style.backgroundColor = 'white'

    // document.getElementById('result7').innerHTML = document.getElementById('select1').value = ''
    // document.getElementById('result7').style.backgroundColor = document.getElementById('select1').style.backgroundColor = recColor7

    // document.getElementById('result8').innerHTML = document.getElementById('select2').value = ''
    // document.getElementById('result8').style.backgroundColor = document.getElementById('select2').style.backgroundColor = recColor8

    // document.getElementById('result9').innerHTML = document.getElementById('select3').value = ''
    // document.getElementById('result9').style.backgroundColor = document.getElementById('select3').style.backgroundColor = recColor9
  }

  function recOut () {
    setFieldName(document.getElementById('fieldname').value)
    setData({
      soiltype: window.soilType,
      humus: document.getElementById('firstNumber').value,
      nitrogen: document.getElementById('secondNumber').value,
      phosphorus: document.getElementById('thirdNumber').value,
      potassium: document.getElementById('fourNumber').value,
      pH: document.getElementById('fiveNumber').value,
      yield: document.getElementById('sixNumber').value,
      density: document.getElementById('sevenNumber').value,
      prev: document.getElementById('select1').value,
      rotation: document.getElementById('select2').value,
      time: window.num10,
      mods: window.num11,
      type: window.num12,
      herbs: window.num13,
      pests: window.num14,
      field_id: document.getElementById('fieldname').value
    })
    count1()
    count2()
    count3()
    count4()
    count5()
    count6()
    count7()
    count8()
    count9()
    document.getElementById('result-div').style.display = 'block'
    document.getElementById('result1f').innerHTML = `Гумус (${num1}%): ` + rec1
    document.getElementById('result1f').style.backgroundColor = rec1Color
    document.getElementById('result2').innerHTML = `Группировка по Конфильту (${num2}):  ` + rec2
    document.getElementById('result2').style.backgroundColor = rec2Color
    document.getElementById('result3').innerHTML = `Оксид фосфора (${num3} мг/кг): ` + rec3
    document.getElementById('result3').style.backgroundColor = rec3Color
    document.getElementById('result4').innerHTML = `Оксид калия (${num4} мг/кг): ` + rec4
    document.getElementById('result4').style.backgroundColor = rec4Color
    document.getElementById('result5').innerHTML = `pH (${num5}): ` + rec5
    document.getElementById('result5').style.backgroundColor = rec5Color
    document.getElementById('result6').innerHTML = `Урожайность (${num6} ц/га): ` + rec6 + '. Рекоменедованы почвозащитные севообороты'
    document.getElementById('result6').style.backgroundColor = rec6Color
    document.getElementById('result7').innerHTML = `Густота посева (${num8} тыс/га): ` + rec8
    document.getElementById('result7').style.backgroundColor = rec7Color
    document.getElementById('result8').innerHTML = `Предшественник (${num7}): ` + rec7
    document.getElementById('result8').style.backgroundColor = rec8Color
    document.getElementById('result9').innerHTML = `Севооборот (${num9}): ` + rec9
    document.getElementById('result9').style.backgroundColor = rec9Color
    console.log(data)
  }
  const [curTheme, setTheme] = React.useState(Cookies.get('theme') === 'black' ? 'black' : 'white')
  if (!slave) {
    if (curTheme === 'white') {
      document.documentElement.style.setProperty('--theme-main', 'darkblue')
      document.documentElement.style.setProperty('--theme-text', 'white')
      document.documentElement.style.setProperty('--theme-secondary', 'lightgray')
      document.documentElement.style.setProperty('--theme-contrast', 'black')
    } else if (curTheme === 'black') {
      document.documentElement.style.setProperty('--theme-main', 'lime')
      document.documentElement.style.setProperty('--theme-text', 'black')
      document.documentElement.style.setProperty('--theme-secondary', 'darkslategray')
      document.documentElement.style.setProperty('--theme-contrast', 'white')
    }
  }
  const themeChangeFunc = () => {
    if (curTheme === 'black') {
      Cookies.set('theme', 'white')
      setTheme('white')
      document.documentElement.style.setProperty('--theme-main', 'darkblue')
      document.documentElement.style.setProperty('--theme-text', 'white')
      document.documentElement.style.setProperty('--theme-secondary', 'lightgray')
      document.documentElement.style.setProperty('--theme-contrast', 'black')
    } else {
      Cookies.set('theme', 'black')
      setTheme('black')
      document.documentElement.style.setProperty('--theme-main', 'lime')
      document.documentElement.style.setProperty('--theme-text', 'black')
      document.documentElement.style.setProperty('--theme-secondary', 'darkslategray')
      document.documentElement.style.setProperty('--theme-contrast', 'white')
    }
  }
  function singleSelectChangeValue (n) {
    try {
      const selObj = document.getElementById(`singleSelectValueDDJS${n}`)
      const selValue = selObj.options[selObj.selectedIndex].value
      window['num' + (n + 9)] = selObj.options[selObj.selectedIndex].text
      console.log('num' + (n + 9), window['num' + (n + 9)])
      if (window.soilType !== undefined) {
        document.getElementById(`textFieldValueJS${n}`).innerHTML = selValue
      }
    } catch {
      console.log()
    }
  }
  function count1 () {
    const curInp = document.getElementById('firstNumber')
    num1 = curInp.value
    const curModal = document.getElementById('result1f')
    if (window.soilType !== undefined) {
      if (num1 > 0) {
        rec1Color = 'brown'
        if (window.soilType === 'type1') {
          rec1 = 'Рекомендуется довести показатели 4,1-7,0, внести органические удобрения 60т/га'
        } else {
          rec1 = 'Рекомендуется довести показатели 4,1-4,5'
        }
        if (num1 > soilStandarts.humus[window.soilType].orange) {
          rec1Color = 'orange'
          if (window.soilType === 'type1') {
            rec1 = 'Рекомендуется довести показатели до 4,1-7,0. Внести органические удобрения 40т/га'
          } else {
            rec1 = 'Рекомендуется довести показатели до 3,1-4. Внести органические удобрения 20т/га'
          }
          if (num1 > soilStandarts.humus[window.soilType].goldenrod) {
            rec1Color = 'goldenrod'
            if (window.soilType === 'type1') {
              rec1 = 'Рекомендуется довести показатели до 5,1-7. Внести органические удобрения 30 т/га'
            } else {
              rec1 = 'Рекомендуется довести показатели до 2,1-3. Внести органические удобрения 30 т/га'
            }
            if (num1 > soilStandarts.humus[window.soilType].yellowgreen) {
              rec1Color = 'yellowgreen'
              if (window.soilType === 'type1') {
                rec1 = 'Рекомендуется довести показатели до 6,1-7. Внести органические удобрения 30 т/га.'
              } else {
                rec1 = 'Рекомендуется довести показатели до 2,1-3,0. Внести органические удобрения 40 т/га.'
              }
              if (num1 > soilStandarts.humus[window.soilType].palegreen) {
                rec1Color = 'palegreen'
                if (window.soilType === 'type1') {
                  rec1 = 'Рекомендуется довести показатели до 7,1 - 7,5.'
                } else {
                  rec1 = 'Рекомендуется довести показатели 1,1-2,0, внести оранические удобрения 50т/га'
                }
              }
              if (num1 > soilStandarts.humus[window.soilType].max) {
                // curInp.value = null
                rec1Color = 'white'
                // alert('Слишком большое значение содержания гумуса!')
              }
            }
          }
        }
      } else {
        // curInp.value = null
        rec1Color = 'white'
        // alert('Слишком маленькое значение содержания гумуса!')
      }
    } else {
      curInp.value = null
      rec1Color = 'white'
      // alert('Не выбран тип почвы!')
    }
    curInp.style.background = rec1Color
  }
  function count2 () {
    const curInp = document.getElementById('secondNumber')
    num2 = curInp.value
    const curModal = document.getElementById('result2')
    if (window.soilType !== undefined) {
      if (num2 > 0) {
        rec2Color = 'brown'
        rec2 = 'Рекомендуется довести показатели до 30-150: НИЗКОЕ'
        if (num2 > soilStandarts.nitrogen[window.soilType].orange) {
          rec2Color = 'orange'
          rec2 = 'Рекомендуется довести показатели до 50-150: ПОНИЖЕННОЕ'
          if (num2 > soilStandarts.nitrogen[window.soilType].goldenrod) {
            rec2Color = 'goldenrod'
            rec2 = 'Рекомендуется довести показатели до 100-150: СРЕДНЕЕ'
            if (num2 > soilStandarts.nitrogen[window.soilType].yellowgreen) {
              rec2Color = 'yellowgreen'
              rec2 = 'Рекомендуется довести показатели до 120-150: ВЫСОКОЕ'
              if (num2 > soilStandarts.nitrogen[window.soilType].palegreen) {
                rec2Color = 'palegreen'
                rec2 = 'Рекомендуется довести показатели до 150-170: ОЧЕНЬ ВЫСОКОЕ'
              }
              if (num2 > soilStandarts.nitrogen[window.soilType].max) {
                // curInp.value = null
                rec2Color = 'white'
                // alert('Слишком большое значение содержания азота!')
              }
            }
          }
        }
      } else {
        // curInp.value = null
        rec2Color = 'white'
        // alert('Слишком маленькое значение содержания азота!')
      }
    } else {
      curInp.value = null
      rec2Color = 'white'
      // alert('Не выбран тип почвы!')
    }
    curInp.style.background = rec2Color
  }

  function count3 () {
    const curInp = document.getElementById('thirdNumber')
    num3 = curInp.value
    const curModal = document.getElementById('result3')
    if (window.soilType !== undefined) {
      if (num3 > 0) {
        rec3Color = 'brown'
        rec3 = 'Рекомендованы показатели 10-60: ОЧЕНЬ НИЗКОЕ'
        if (num3 > soilStandarts.phosphorus[window.soilType].orange) {
          rec3Color = 'orange'
          rec3 = 'Рекомендованы показатели 15-60: НИЗКОЕ'
          if (num3 > soilStandarts.phosphorus[window.soilType].goldenrod) {
            rec3Color = 'goldenrod'
            rec3 = 'Рекомендованы показатели 30-60: СРЕДНЕЕ'
            if (num3 > soilStandarts.phosphorus[window.soilType].yellowgreen) {
              rec3Color = 'yellowgreen'
              rec3 = 'Рекомендованы показатели 45-60: ВЫСОКОЕ'
              if (num3 > soilStandarts.phosphorus[window.soilType].palegreen) {
                rec3Color = 'palegreen'
                rec3 = 'Рекомендованы показатели 45-60: ОЧЕНЬ ВЫСОКОЕ'
              }
              if (num3 > soilStandarts.phosphorus[window.soilType].max) {
                // curInp.value = null
                rec3Color = 'white'
                // alert('Слишком большое значение содержания фосфора!')
              }
            }
          }
        }
      } else {
        // curInp.value = null
        rec3Color = 'white'
        // alert('Слишком маленькое значение содержания фосфора!')
      }
    } else {
      curInp.value = null
      rec3Color = 'white'
      // alert('Не выбран тип почвы!')
    }
    curInp.style.background = rec3Color
  }

  function count4 () {
    const curInp = document.getElementById('fourNumber')
    num4 = curInp.value
    const curModal = document.getElementById('result4')
    if (window.soilType !== undefined) {
      if (num4 > 0) {
        rec4Color = 'brown'
        rec4 = 'Рекомендованы показатели 100-500: ОЧЕНЬ НИЗКОЕ'
        if (num4 > soilStandarts.potassium[window.soilType].orange) {
          rec4Color = 'orange'
          rec4 = 'Рекомендованы показатели 200-500: НИЗКОЕ'
          if (num4 > soilStandarts.potassium[window.soilType].goldenrod) {
            rec4Color = 'goldenrod'
            rec4 = 'Рекомендованы показатели 300-500: СРЕДНЕЕ'
            if (num4 > soilStandarts.potassium[window.soilType].yellowgreen) {
              rec4Color = 'yellowgreen'
              rec4 = 'Рекомендованы показатели 400-500: ВЫСОКОЕ'
              if (num4 > soilStandarts.potassium[window.soilType].palegreen) {
                rec4Color = 'palegreen'
                rec4 = 'Рекомендованы показатели 400-500: ОЧЕНЬ ВЫСОКОЕ'
              }
              if (num4 > soilStandarts.potassium[window.soilType].max) {
                // curInp.value = null
                rec4Color = 'white'
                // alert('Слишком большое значение содержания оксида калия!')
              }
            }
          }
        }
      } else {
        // curInp.value = null
        rec4Color = 'white'
        // alert('Слишком маленькое значение содержания оксида калия!')
      }
    } else {
      curInp.value = null
      rec4Color = 'white'
      // alert('Не выбран тип почвы!')
    }
    curInp.style.background = rec4Color
  }

  function count5 () {
    const curInp = document.getElementById('fiveNumber')
    num5 = curInp.value
    const curModal = document.getElementById('result5')
    if (window.soilType !== undefined) {
      if (num5 > 0) {
        rec5Color = 'brown'
        rec5 = 'Рекомендуется довести показатели: 6.9 - 7.2'
        if (num5 > soilStandarts.pH[window.soilType].orange) {
          rec5Color = 'orange'
          rec5 = 'Рекомендуется довести показатели: 6.9 - 7.2'
          if (num5 > soilStandarts.pH[window.soilType].goldenrod) {
            rec5Color = 'goldenrod'
            rec5 = 'Рекомендуется довести показатели: 6.9 - 7.2'
            if (num5 > soilStandarts.pH[window.soilType].yellowgreen) {
              rec5Color = 'yellowgreen'
              rec5 = 'Рекомендуется довести показатели: 6.9 - 7.2'
              if (num5 > soilStandarts.pH[window.soilType].palegreen) {
                rec5Color = 'palegreen'
                rec5 = 'Рекомендуется довести показатели: 6.9 - 7.2'
              }
              if (num5 > soilStandarts.pH[window.soilType].max) {
                // curInp.value = null
                rec5Color = 'white'
                // alert('Слишком большое значение pH!')
              }
            }
          }
        }
      } else {
        // curInp.value = null
        rec5Color = 'white'
        // alert('Слишком маленькое значение pH!')
      }
    } else {
      curInp.value = null
      rec5Color = 'white'
      // alert('Не выбран тип почвы!')
    }
    curInp.style.background = rec5Color
  }

  function count6 () {
    const curInp = document.getElementById('sixNumber')
    num6 = curInp.value
    const curModal = document.getElementById('result6')
    if (window.soilType !== undefined) {
      if (num6 > 0) {
        rec6Color = 'brown'
        rec6 = 'Рекомендовано 22 - 35 ц/га'
        if (num6 > soilStandarts.yield[window.soilType].orange) {
          rec6Color = 'orange'
          rec6 = 'Рекомендовано 22 - 35 ц/га'
          if (num6 > soilStandarts.yield[window.soilType].goldenrod) {
            rec6Color = 'goldenrod'
            rec6 = 'Рекомендовано 22 - 35 ц/га'
            if (num6 > soilStandarts.yield[window.soilType].yellowgreen) {
              rec6Color = 'yellowgreen'
              rec6 = 'Рекомендовано 22 - 35 ц/га'
              if (num6 > soilStandarts.yield[window.soilType].palegreen) {
                rec6Color = 'palegreen'
                rec6 = 'Рекомендовано 22 - 35 ц/га'
              }
              if (num6 > soilStandarts.yield[window.soilType].max) {
                curInp.value = null
                rec6Color = 'white'
                // alert('Слишком большое значение урожайности!')
              }
            }
          }
        }
      } else {
        curInp.value = null
        rec6Color = 'white'
        // alert('Слишком маленькое значение урожайности!')
      }
    } else {
      curInp.value = null
      rec5Color = 'white'
      // alert('Не выбран тип почвы!')
    }
    curInp.style.background = rec6Color
  }

  function count7 () {
    const curInp = document.getElementById('select1')
    num7 = curInp.value
    if (document.getElementById('cropSelect').value !== undefined) {
      if (num7 === 'Пар') {
        num7 = 'Пар'
        rec7Color = 'palegreen'
        rec7 = 'Рекомендуется довести показатели Зерновые, пропашные Пар, яровые и озимые , кукуруза на силос оптимальное'
      } else if (num7 === 'Яровые, пар') {
        num7 = 'Яровые, пар'
        rec7Color = 'palegreen'
        rec7 = 'Рекомендуется довести показатели Зерновые, пропашные Пар, яровые и озимые , кукуруза на силос оптимальное'
      } else if (num7 === 'Бобовые') {
        num7 = 'Бобовые'
        rec7Color = 'palegreen'
        rec7 = 'Рекомендуется довести показатели Зерновые, пропашные Пар, яровые и озимые , кукуруза на силос оптимальное'
      } else if (num7 === 'Подсолнечник') {
        num7 = 'Подсолнечник'
        rec7Color = 'palegreen'
        rec7 = 'Рекомендуется довести показатели Зерновые, пропашные Пар, яровые и озимые , кукуруза на силос оптимальное'
      } else {
        curInp.value = null
        rec7Color = 'palegreen'
      }
      curInp.style.background = rec7Color
    }
  }

  function count8 () {
    const curInp = document.getElementById('sevenNumber')
    num8 = curInp.value
    const curModal = document.getElementById('result8')
    rec8 = 'Глубина заделки семян 4-7 см в зависимости от механического состава почвы, влажности почвы и крупности семян. Посадку производять картофелесажалками точного высева. Семена сортов картофеля на супесчаных почвах сажают на глубину 12-14 см с отклонением не более ±1 см, семена гибридов на глубину 8-12 см. Скорость движения посевного агрегата – 5-6 км/час.'
    if (window.soilType !== undefined) {
      if (num8 > 0) {
        rec8Color = 'brown'

        if (num8 > soilStandarts.density[window.soilType].orange) {
          rec8Color = 'orange'

          if (num8 > soilStandarts.density[window.soilType].goldenrod) {
            rec8Color = 'goldenrod'

            if (num8 > soilStandarts.density[window.soilType].yellowgreen) {
              rec8Color = 'yellowgreen'

              if (num8 > soilStandarts.density[window.soilType].palegreen) {
                rec8Color = 'palegreen'
              }
              if (num8 > soilStandarts.density[window.soilType].max) {
                curInp.value = null
                rec8Color = 'white'
                // alert('Слишком большое значение густоты!')
              }
            }
          }
        }
      } else {
        curInp.value = null
        rec8Color = 'white'
        // alert('Слишком маленькое значение густоты!')
      }
    } else {
      // alert('Не выбран тип почвы!')
    }
    curInp.style.background = rec8Color
  }

  function count9 () {
    const curInp = document.getElementById('select2')
    num9 = curInp.value
    if (document.getElementById('cropSelect').value !== undefined) {
      if (num9 === '8-польный') {
        num9 = '8-польный'
        rec9Color = 'palegreen'
        rec9 = 'Рекомендованы 5-й,6-ти,7-ми,8 ми польные Подсолнечник следует возвращать на прежнее место не раньше чем через 8 лет, в противном случае он поражается болезнями (заразихой, гнилями и др'
      } else if (num9 === '4-польный') {
        num9 = '4-польный'
        rec9Color = 'palegreen'
        rec9 = 'Рекомендованы 5-й,6-ти,7-ми,8 ми польные Подсолнечник следует возвращать на прежнее место не раньше чем через 8 лет, в противном случае он поражается болезнями (заразихой, гнилями и др'
      } else if (num9 === 'Нет севооборота') {
        num9 = 'Нет севооборота'
        rec9Color = 'palegreen'
        rec9 = 'Рекомендованы 5-й,6-ти,7-ми,8 ми польные Подсолнечник следует возвращать на прежнее место не раньше чем через 8 лет, в противном случае он поражается болезнями (заразихой, гнилями и др'
      } else {
        curInp.value = null
        rec9Color = 'palegreen'
      }
      curInp.style.background = rec9Color
    }
  }

  return (
    <>
    <div className={slave ? `dropdown-content ${adClass}` : 'data calcmodal-body'} style={slave && { width: '500px', overflowY: 'auto', maxHeight: '80vh' }}>
    <h3 align='center'>{t('rec.title')}</h3>
{
fieldsArray.length === 0
  ? t('rec.norecs')
  : fieldsArray.map((f, key) => {
    // console.log(JSON.parse(selField.json).properties.name, f.field_name)
    return (
            <div
              className='note-div' key={key} onClick={() => {
                window.soilType = f.soiltype
                num1 = f.humus
                num2 = f.nitrogen
                num3 = f.phosphorus
                num4 = f.potassium
                num5 = f.pH
                num6 = f.yield
                num8 = f.density
                num7 = f.prev
                num9 = f.rotation
                num10 = f.time
                num11 = f.mods
                num12 = f.type
                num13 = f.herbs
                num14 = f.pests
                const multivalues = [0, f.time, f.mods, f.type, f.herbs, f.pests]
                document.getElementById('firstNumber').value = f.humus
                document.getElementById('secondNumber').value = f.nitrogen
                document.getElementById('thirdNumber').value = f.phosphorus
                document.getElementById('fourNumber').value = f.potassium
                document.getElementById('fiveNumber').value = f.pH
                document.getElementById('sixNumber').value = f.yield
                document.getElementById('sevenNumber').value = f.density
                document.getElementById('select1').value = f.prev
                document.getElementById('select2').value = f.rotation
                document.getElementById('fieldname').value = f.name
                document.getElementById('singleSelectValueDDJS1').value = f.time
                document.getElementById('singleSelectValueDDJS2').value = f.mods
                document.getElementById('singleSelectValueDDJS3').value = f.type
                document.getElementById('singleSelectValueDDJS4').value = f.herbs
                document.getElementById('singleSelectValueDDJS5').value = f.pests
                setDataName(f.name)
                setFieldName(f.name)
                for (let n = 1; n <= 5; n++) {
                  const selObj = document.getElementById(`singleSelectValueDDJS${n}`)
                  const selValues = Array.from(selObj.options)
                  selValues.forEach(e => {
                    if (e.text === multivalues[n]) {
                      document.getElementById(`singleSelectValueDDJS${n}`).value = e.value
                      // document.getElementById(`singleSelectValueDDJS${n}`).text = e.text
                    }
                  })
                  singleSelectChangeValue(n)
                }
                // document.getElementById('fieldname').value = f.field_name
                count1()
                count2()
                count3()
                count4()
                count5()
                count6()
                count7()
                count8()
                count9()
                document.getElementById('rec-out').click()
                // for (let i = 0; i <= 5; i++) {
                //   singleSelectChangeValue(i)
                // }
              }}
            >
              <p style={{ width: '90%' }}>Рекомендация для поля {f.name}, обновлено:  {f.updatedAt.replace('T', ' ').slice(0, -8)}</p>
              <Button
                style={{ width: 'fit-content', fontSize: '10px', padding: '2px', float: 'right', margin: 0 }} id='delete' onClick={() => {
                  console.log(f.id)
                  deleteRec(f.id)
                }}
              ><FontAwesomeIcon icon={FA.faTrashAlt} />
              </Button>
            </div>
    )
  })
    }
    </div>
    <div className={slave ? `dropdown-content ${adClass}` : 'data calcmodal-body'} style={slave && { width: '500px', overflowY: 'auto', maxHeight: '71vh' }}>
      <datalist id='fields'>
        {fields && fields.map((a, key) => {
          return (
            <option key={key} value={a}>{a}</option>
          )
        })}
      </datalist>
      {
slave
  ? <a style={{ width: 'fit-content', float: 'right', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' onClick={() => hideFunc(false)}>
    <FontAwesomeIcon icon={FA.faTimes} />
  </a>
  : <><a style={{ width: 'fit-content', float: 'left', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' onClick={themeChangeFunc}>
    <FontAwesomeIcon icon={FA.faPalette} />
  </a>
    <a style={{ width: 'fit-content', float: 'right', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' href='/profile'>
      <FontAwesomeIcon icon={FA.faHome} />
    </a>
    <a style={{ width: 'fit-content', float: 'right', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' onClick={() => i18n.changeLanguage(i18n.language === 'ru' ? 'kz' : i18n.language === 'kz' ? 'en' : 'ru')}>
      <h5 style={{ margin: 'auto' }}>{i18n.language.toUpperCase()}</h5>
    </a>
  </>
}
      <h3 align='center'>{t('rec.t2')}</h3>
      {/* <p>
        Используя внесенные данные, мы улучшаем программу на основе машинного
        обучения, помогая вам улучшить урожайность сельскохозяйственных культур
      </p> */}
      <div className='form-group col-sm-12'>
        <Input id='fieldname' list='fields' placeholder={t('rec.sel')} className='form-control' style={{ margin: '15px auto' }} onChange={e => { setFieldName(e) }}/>
        <select
          className='selectpicker form-control'
          name='demoSelect'
          id='cropSelect'
          onChange={resetInput}
        >
          <option value='disable'>{t('rec.selcul')}</option>
          <option value='type1'>Чернозем обыкновенный - подсолнечник</option>
          <option value='type2'>Каштановые почвы - подсолнечник</option>
          <option value='type3'>Каштановые почвы - картофель</option>
        </select>
        <div id='colorscale'>
          <div className='colorSq'>{t('rec.op')} {} -&gt;</div>
          <div className='colorSq' style={{ backgroundColor: 'palegreen' }} />
          <div className='colorSq' style={{ backgroundColor: 'yellowgreen' }} />
          <div className='colorSq' style={{ backgroundColor: 'goldenrod' }} />
          <div className='colorSq' style={{ backgroundColor: 'orange' }} />
          <div className='colorSq' style={{ backgroundColor: 'brown' }} />
          <div className='colorSq'>&lt;- {} {t('rec.neg')}</div>
        </div>
        <div id='colorscale'>
          <div>{t('rec.grad')}</div>
        </div>
        <hr />
        <form>
          <label> {t('rec.humus')} </label>
          <input
            type='number'
            step='0.1'
            id='firstNumber'
            onChange={count1}
            className='form-control input-md'
            placeholder='Например: 2'
          />
          <br />
          <label>
            {t('rec.nitr')} </label>
          <input
            type='number'
            id='secondNumber'
            onChange={count2}
            className='form-control input-md'
            placeholder='Например: 140'
          />{' '}
          <br />
          <label>{t('rec.ph')}</label>{' '}
          <input
            type='number'
            id='thirdNumber'
            onChange={count3}
            className='form-control input-md'
            placeholder='Например: 45'
          />
          <br />
          <label> {t('rec.pot')} </label>
          <input
            type='number'
            id='fourNumber'
            onChange={count4}
            className='form-control input-md'
            placeholder='Например: 300'
          />
          <br />
          <label> рН:</label>{' '}
          <input
            type='number'
            step='0.1'
            onChange={count5}
            id='fiveNumber'
            className='form-control input-md'
            placeholder='Например: 6.7'
          />{' '}
          <br />
          <label> {t('rec.yield')} </label>
          <input
            type='number'
            onChange={count6}
            id='sixNumber'
            className='form-control input-md'
            placeholder='Например: 30'
          />
          <br />
          <label> {t('rec.g')} </label>
          <input
            type='number'
            onChange={count8}
            id='sevenNumber'
            className='form-control input-md'
            placeholder='Например: 30'
          />
          <br />
        </form>
        <label>{t('rec.prev')}</label>
        <br />
        <select
          id='select1'
          className='selectpicker form-control'
          onChange={count7}
        >
          <option value='disable'>{t('yield.selecttitle')}</option>
          <option value='Пар'>Пар</option>
          <option value='Яровые, пар'>Яровые, пар</option>
          <option value='Бобовые'>Бобовые</option>
          <option value='Подсолнечник'>Подсолнечник</option>
        </select>
        <br />
        <label> {t('rec.sev')}</label>
        <select
          id='select2'
          className='selectpicker form-control'
          onChange={count9}
        >
          <option value='disable'>Выберите севооборот</option>
          <option value='8-польный'>8-польный</option>
          <option value='4-польный'>4-польный</option>
          <option value='Нет севооборота'>Нет севооборота</option>
        </select>
        <label>
          <br /> {t('yield.trange')}
        </label>
        <br />
        <select
          id='singleSelectValueDDJS1'
          className='selectpicker form-control'
          onChange={() => singleSelectChangeValue(1)}
        >
          <option value='Не введены данные по срокам'>Выберите сроки посева</option>
          <option value='Рекомендованы 1-11,12,13,14-20 мая Лучший срок посева – при температуре почвы на глубине заделки семян 8-12 °С (обычно первая-вторая декада мая). Ширина междурядий обычно составляет 70 см'>
            Первая-вторая декада мая
          </option>
          <option value='Рекомендованы 1-11,12,13,14-20 мая Лучший срок посева – при температуре почвы на глубине заделки семян 8-12 °С (обычно первая-вторая декада мая). Ширина междурядий обычно составляет 70 см'>
            Третья декада мая
          </option>
          <option value='Рекомендованы 1-11,12,13,14-20 мая Лучший срок посева – при температуре почвы на глубине заделки семян 8-12 °С (обычно первая-вторая декада мая). Ширина междурядий обычно составляет 70 см'>
            Четвертая декада мая
          </option>
        </select>
        <label>
          <br /> Внесенные удобрения:
        </label>
        <br />
        <select
          id='singleSelectValueDDJS2'
          className='selectpicker form-control'
          onChange={() => singleSelectChangeValue(2)}
        >
          <option value='Не введены данные по удобрениям'>
            Выберите удобрения
          </option>
          <option value='Рекомендуется довести показатели N60Р50, N50Р60, N60Р40 Подсолнечник является культурой, отзывчивой на удобрения. В предгорно-степной зоне оптимальными дозами являются N40-60P60. Калийные удобрения под подсолнечник вносить нецелесообразно. '>
            N60P60
          </option>
          <option value='Рекомендуется довести показатели N70Р70, N50Р90, N60Р90 Подсолнечник является культурой, отзывчивой на удобрения. В предгорно-степной зоне оптимальными дозами являются N40-60P60. Калийные удобрения под подсолнечник вносить нецелесообразно. '>
            N90P90
          </option>
          <option value='Рекомендуется довести показатели N90Р90, N100100, N110Р110 Подсолнечник является культурой, отзывчивой на удобрения. В предгорно-степной зоне оптимальными дозами являются N40-60P60. Калийные удобрения под подсолнечник вносить нецелесообразно. '>
            N120P120
          </option>
        </select>
        <label>
          <br /> Сорты и гибриды:
        </label>
        <br />
        <select
          id='singleSelectValueDDJS3'
          className='selectpicker form-control'
          onChange={() => singleSelectChangeValue(3)}
        >
          <option value='Не введены данные по сорту'>Выберите сорт:</option>
          <option value='Рекомендованы Казахстанский,1, Казахстанский 465, Солнечный-20'>
            Казахстанский 465
          </option>
          <option value='Рекомендованы Казахстанский,1, Казахстанский 465, Солнечный-20'>
            Сункар
          </option>
          <option value='Рекомендованы Невский, Гала и Розара '>
            Невский, Гала
          </option>
        </select>
        <label>
          <br /> Гербициды:
        </label>
        <br />
        <select
          id='singleSelectValueDDJS4'
          className='selectpicker form-control'
          onChange={() => singleSelectChangeValue(4)}
        >
          <option value='Не введены данные по гербицидам'>
            Выберите тип гербицидов:
          </option>
          <option value='Рекомендуется Ураган форте  1,2-1,8 л/га - за 2, 3 и 4 дня до посева культуры. В весенний период в посевах подсолнечника применяются гербициды почвенного действия Гезагард КС (2-4 л/га) и его аналоги, Дуал Голд 960 г/л (1,3-1,6 л/га), Фронтьер, 90 КЭ (1,1-1,7 л/га) и другие.'>
            Гезагард КС (2-4 л/га)
          </option>
          <option value='Рекомендуется Ураган форте  1,2-1,8 л/га - за 2, 3 и 4 дня до посева культуры. В весенний период в посевах подсолнечника применяются гербициды почвенного действия Гезагард КС (2-4 л/га) и его аналоги, Дуал Голд 960 г/л (1,3-1,6 л/га), Фронтьер, 90 КЭ (1,1-1,7 л/га) и другие.'>
            Ураган форте &nbsp; (1,2-1,8 л/га)
          </option>
        </select>
        <label>
          <br />
          Болезни и вредители:
        </label>
        <br />
        <select
          id='singleSelectValueDDJS5'
          className='selectpicker form-control'
          onChange={() => singleSelectChangeValue(5)}
        >
          <option value='Не введены данные по болезням'>
            Выберите присутствующие болезни/вредителей:
          </option>
          <option value='Ложная мучнистая роса, серая гниль, фомоз, ржавчина, бактериоз'>
            Белая гниль
          </option>
          <option value='Ложная мучнистая роса, серая гниль, фомоз, ржавчина, бактериоз'>
            Серая гниль
          </option>
          <option value='Ложная мучнистая роса, серая гниль, фомоз, ржавчина, бактериоз'>
            Заразиха
          </option>
          <option value='Ложная мучнистая роса, серая гниль, фомоз, ржавчина, бактериоз'>
            Бактериоз
          </option>
          <option value='Ложная мучнистая роса, серая гниль, фомоз, ржавчина, бактериоз'>
            Болезней нет
          </option>
        </select>
        <div style={{ display: 'none' }} id='result-div'>
          <hr /> <label className='microtext'>Вывод :</label>
          <span id='result1f' className='rec-result' /> <br />
          <span id='result2' className='rec-result' /> <br />
          <span id='result3' className='rec-result' /> <br />
          <span id='result4' className='rec-result' />
          <br />
          <span id='result5' className='rec-result' />
          <br />
          <span id='result6' className='rec-result' />
          <br />
          <span id='result7' className='rec-result' />
          <br />
          <span id='result8' className='rec-result' />
          <br />
          <span id='result9' className='rec-result' />
          <br />
          Рекомендуемые сроки для посева:
          <div
            type='text'
            id='textFieldValueJS1'
            className='form-control'
            placeholder=''
          >
            Не введены данные по срокам
          </div>
          Рекомендации по внесению удобрений:
          <div
            type='text'
            id='textFieldValueJS2'
            className='form-control'
            placeholder=''
          >
            Не введены данные по удобрениям
          </div>
          Рекомендации по выбору сорта:
          <div
            type='text'
            id='textFieldValueJS3'
            className='form-control'
            placeholder=''
          >
            Не введены данные по сорту
          </div>
          Рекомендации по выбору гербицидов:
          <div
            type='text'
            id='textFieldValueJS4'
            className='form-control'
            placeholder=''
          >
            Не введены данные по гербицидам
          </div>
          Рекомендации по противодействию болезням/вредителям:
          <div
            type='text'
            id='textFieldValueJS5'
            className='form-control'
            placeholder=''
          >
            Не введены данные по болезням
          </div>
          <p id='firstP'>&nbsp;</p>
        {dataAnalysisName &&
          <div className='calcmodal-body data' style={slave && { width: '100%', margin: 0 }}>
            <ChartModal id={dataAnalysisName} fields={fieldsData.filter((e) => e.field_name === dataAnalysisName)} update={updateKey} />
          </div>
      }
      <br />
      </div>
        <button className='btn btn-default' id='rec-out' onClick={recOut}>
          <i className='fa fa-fw fa-cog  fa-spin icon-black' /> Вычислить рекомендацию
        </button>
        <br />
        <button className='btn btn-default' onClick={saveRec}>
          <i className='fa fa-fw fa-plus icon-black' />
          Сохранить рекомендацию
        </button>
        <br />
      </div>
    </div>
  </>
  )
}

export default Recommend
