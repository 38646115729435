import React from 'react'
import { CanvasJSChart } from 'canvasjs-react-charts'
import Cookies from 'js-cookie'

const ChartModal = ({ id, fields, update }) => {
  const [updateKey, Aupdate] = React.useState(0)
  // const colors = ['#B1A2CA', '#D291BC', '#FF6D6A', '#E9EC6B', '#EFBE7D']
  const colors = ['#8BD3E6', '#FF6D6A', '#E9EC6B', '#EFBE7D', '#B1A2CA', '#E0BBE4', '#957DAD', '#D291BC']
  const match = {}
  const seasons = fields.filter((e) => e.field_name === id).map(e => {
    return Number(e.season)
  })
  const options = {
    animationEnabled: true,
    exportEnabled: true,
    dataPointMaxWidth: 50,
    theme: Cookies.get('theme') === 'black' ? 'dark1' : 'light1', // "light1", "dark1", "dark2"
    title: {
      text: `Динамика урожайности для поля ${id} c ${Math.min(...seasons)} по ${Math.max(...seasons)} (ц/га)`
    },
    axisY: {
      includeZero: true
    },
    scales:
    {
      xAxis: [
        {
          barPercentage: 0.3
        }]
    },
    data: [{
      type: 'column', // change type to bar, line, area, pie, etc
      // indexLabel: "{y}", //Shows y value on all Data Points
      indexLabelFontColor: '#5A5757',
      indexLabelPlacement: 'outside',
      indexLabel: '{y}',
      dataPoints: fields && fields.filter((e) => e.field_name === id).map((e) => {
        if (!Object.keys(match).includes(e.croptype)) {
          match[e.croptype] = colors[0]
          colors.shift()
        }
        return (
          { color: match[e.croptype], label: `${e.season} (${e.croptype})`, y: e.yield ? Number(e.yield.replace(',', '.')) : 0 }
        )
      })
    }]
  }

  return (
    <div>
      <CanvasJSChart options={options} />

    </div>
  )
}
export default ChartModal
