import L, { Icon } from 'leaflet'
import 'leaflet-draw/dist/leaflet.draw-src.css'
import 'leaflet-draw'
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch'
import { Alert } from 'rsuite'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'

const MapControls = (map, t) => {
  const myIcon = new Icon({
    iconUrl: './locationicon.svg',
    iconSize: [25, 41],
    iconAnchor: [10, 41],
    popupAnchor: [2, -40]
  })
  try {
    //   onAdd: function (map) {
    //     const provider = new OpenStreetMapProvider()
    //     const searchControl = new GeoSearchControl({
    //       provider: provider,
    //       showMarker: false,
    //       style: 'bar',
    //     })
    //     return searchControl;
    //   }
    // });
    // L.control.search = function (options) {
    //   return new L.Control.Search(options);
    // };
    // L.control.search({ position: 'bottomleft' }).addTo(map)
    const markers = null
    let $locmarker
    const markerIcon = L.icon({
      iconSize: [25, 41],
      iconAnchor: [10, 41],
      popupAnchor: [2, -40],
      // specify the path here
      iconUrl: 'https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png',
      shadowUrl: 'https://unpkg.com/leaflet@1.5.1/dist/images/marker-shadow.png'
    })

    const provider = new OpenStreetMapProvider()
    const searchControl = new GeoSearchControl({
      provider: provider,
      showMarker: true,
      style: 'button',
      position: 'topright'
    })

    L.control.scale({
      position: 'bottomleft'
    }).addTo(map)
    L.control.zoom({
      position: 'topright'
    }).addTo(map)
    const CustomControl = L.Control.extend({
      options: {
        position: 'topright'
      },
      onAdd: function (map) {
        const container = L.DomUtil.create('div', 'leaflet-bar leaflet-control leaflet-control-custom')
        container.style.backgroundColor = 'white'
        container.style.backgroundSize = '30px 30px'
        container.style.backgroundImage = 'url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDY1IDY1IiBoZWlnaHQ9IjY1cHgiIGlkPSJMYXllcl8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCA2NSA2NSIgd2lkdGg9IjY1cHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxwYXRoIGQ9Ik0zMi41LDIzLjcwNmMtNC44NDksMC04Ljc5NCwzLjk0NS04Ljc5NCw4Ljc5NGMwLDQuODQ5LDMuOTQ1LDguNzk0LDguNzk0LDguNzk0YzQuODQ5LDAsOC43OTQtMy45NDUsOC43OTQtOC43OTQgIEM0MS4yOTQsMjcuNjUxLDM3LjM0OSwyMy43MDYsMzIuNSwyMy43MDZ6Ii8+PHBhdGggZD0iTTYyLjg3NSwzMWgtOC4zNDNDNTMuNzkxLDIwLjAxNCw0NC45ODYsMTEuMjA5LDM0LDEwLjQ2OFYyLjEyNWMwLTAuODI5LTAuNjcyLTEuNS0xLjUtMS41Yy0wLjgyOSwwLTEuNSwwLjY3MS0xLjUsMS41ICB2OC4zNDNDMjAuMDE0LDExLjIwOSwxMS4yMDksMjAuMDE0LDEwLjQ2OCwzMUgyLjEyNWMtMC44MjksMC0xLjUsMC42NzEtMS41LDEuNWMwLDAuODI4LDAuNjcxLDEuNSwxLjUsMS41aDguMzQzICBDMTEuMjA5LDQ0Ljk4NiwyMC4wMTQsNTMuNzkxLDMxLDU0LjUzMnY4LjM0M2MwLDAuODI4LDAuNjcxLDEuNSwxLjUsMS41YzAuODI4LDAsMS41LTAuNjcyLDEuNS0xLjV2LTguMzQzICBDNDQuOTg2LDUzLjc5MSw1My43OTEsNDQuOTg2LDU0LjUzMiwzNGg4LjM0M2MwLjgyOCwwLDEuNS0wLjY3MiwxLjUtMS41QzY0LjM3NSwzMS42NzEsNjMuNzAzLDMxLDYyLjg3NSwzMXogTTMyLjUsNDQuMjk0ICBjLTYuNTAzLDAtMTEuNzk0LTUuMjkxLTExLjc5NC0xMS43OTRjMC02LjUwMyw1LjI5MS0xMS43OTQsMTEuNzk0LTExLjc5NGM2LjUwMywwLDExLjc5NCw1LjI5MSwxMS43OTQsMTEuNzk0ICBDNDQuMjk0LDM5LjAwMywzOS4wMDMsNDQuMjk0LDMyLjUsNDQuMjk0eiIvPjwvc3ZnPg==)'
        container.style.width = '30px'
        container.style.height = '30px'
        container.style.border = '0'
        container.style.cursor = 'pointer'
        container.onclick = async () => {
          if (navigator.geolocation) {
            await navigator.geolocation.getCurrentPosition((e) => {
              !$locmarker ? $locmarker = map.addLayer(L.marker([e.coords.latitude, e.coords.longitude], { icon: myIcon })) : console.log('exists!')
              map.panTo(new L.LatLng(e.coords.latitude, e.coords.longitude))
            })
          } else {
            Alert.error('Geolocation is not supported by this browser.')
          }
        }
        return container
      }
    })
    searchControl.addTo(map)
    // Initialise the FeatureGroup to store editable layers
    const editableLayers = new L.FeatureGroup()
    map.addLayer(editableLayers)
    const drawPluginOptions = {
      position: 'topright',
      draw: {
        // polyline: {
        //   shapeOptions: {
        //     color: '#f357a1',
        //     weight: 10
        //   }
        // },
        polygon: {
          allowIntersection: false, // Restricts shapes to simple polygons
          precision: {
            km: 1,
            ha: 1
          },
          drawError: {
            color: '#00FF00', // '#010101', // Color the shape will turn when intersects
            message: '<strong>Polygon draw does not allow intersections!<strong>' // Message that will show when intersect
          },
          shapeOptions: {
            color: '#00FF00' // '#fefefe'
          }
        },
        circle: false, // Turns off this drawing tool
        rectangle: {
          shapeOptions: {
            clickable: false,
            color: '#00FF00' // '#fefefe'
          }
        },
        marker: true,
        circlemarker: false,
        polyline: false
      }
      // edit: {
      //   featureGroup: editableLayers, // REQUIRED!!
      //   remove: false
      // }
    }
    // Initialise the draw control and pass it the FeatureGroup of editable layers
    const drawControl = new L.Control.Draw(drawPluginOptions)
    map.addControl(drawControl)
    map.addControl(new CustomControl())
    L.drawLocal = {
      draw: {
        toolbar: {
          // #TODO: this should be reorganized where actions are nested in actions
          // ex: actions.undo  or actions.cancel
          actions: {
            title: t('leaflet.draw.toolbar.actions.title'),
            text: t('leaflet.draw.toolbar.actions.text')
          },
          finish: {
            title: t('leaflet.draw.toolbar.finish.title'),
            text: t('leaflet.draw.toolbar.finish.text')
          },
          undo: {
            title: t('leaflet.draw.toolbar.undo.title'),
            text: t('leaflet.draw.toolbar.undo.text')
          },
          buttons: {
            polyline: t('leaflet.draw.toolbar.buttons.polyline'),
            polygon: t('leaflet.draw.toolbar.buttons.polygon'),
            rectangle: t('leaflet.draw.toolbar.buttons.rectangle'),
            circle: t('leaflet.draw.toolbar.buttons.circle'),
            marker: t('leaflet.draw.toolbar.buttons.marker'),
            circlemarker: t('leaflet.draw.toolbar.buttons.circlemarker')
          }
        },
        handlers: {
          circle: {
            tooltip: {
              start: t('leaflet.draw.handlers.circle.tooltip.start')
            },
            radius: t('leaflet.draw.handlers.circle.radius')
          },
          circlemarker: {
            tooltip: {
              start: t('leaflet.draw.handlers.circlemarker.tooltip.start')
            }
          },
          marker: {
            tooltip: {
              start: t('leaflet.draw.handlers.marker.tooltip.start')
            }
          },
          polygon: {
            tooltip: {
              start: t('leaflet.draw.handlers.polygon.tooltip.start'),
              cont: t('leaflet.draw.handlers.polygon.tooltip.cont'),
              end: t('leaflet.draw.handlers.polygon.tooltip.end')
            }
          },
          polyline: {
            error: t('leaflet.draw.handlers.polyline.error'),
            tooltip: {
              start: t('leaflet.draw.handlers.polyline.tooltip.start'),
              cont: t('leaflet.draw.handlers.polyline.tooltip.cont'),
              end: t('leaflet.draw.handlers.polyline.tooltip.end')
            }
          },
          rectangle: {
            tooltip: {
              start: t('leaflet.draw.handlers.rectangle.tooltip.start')
            }
          },
          simpleshape: {
            tooltip: {
              end: t('leaflet.draw.handlers.simpleshape.tooltip.end')
            }
          }
        }
      },
      edit: {
        toolbar: {
          actions: {
            save: {
              title: t('leaflet.edit.toolbar.actions.save.title'),
              text: t('leaflet.edit.toolbar.actions.save.text')
            },
            cancel: {
              title: t('leaflet.edit.toolbar.actions.cancel.title'),
              text: t('leaflet.edit.toolbar.actions.cancel.text')
            },
            clearAll: {
              title: t('leaflet.edit.toolbar.actions.clearAll.title'),
              text: t('leaflet.edit.toolbar.actions.clearAll.text')
            }
          },
          buttons: {
            edit: t('leaflet.edit.toolbar.buttons.edit'),
            editDisabled: t('leaflet.edit.toolbar.buttons.editDisabled'),
            remove: t('leaflet.edit.toolbar.buttons.remove'),
            removeDisabled: t('leaflet.edit.toolbar.buttons.removeDisabled')
          }
        },
        handlers: {
          edit: {
            tooltip: {
              text: t('leaflet.edit.handlers.edit.tooltip.text'),
              subtext: t('leaflet.edit.handlers.edit.tooltip.subtext')
            }
          },
          remove: {
            tooltip: {
              text: t('leaflet.edit.handlers.remove.tooltip.text')
            }
          }
        }
      }
    }

    // L.Control.Watermark = L.Control.extend({
    //   onAdd: function (map) {
    //     const img = L.DomUtil.create('img')
    //     img.src = '../../../img/site_logo.png'
    //     img.style.width = '100px'
    //     return img
    //   },
    //   onRemove: function (map) {
    //     // Nothing to do here
    //   }
    // })
    // L.control.watermark = function (opts) {
    //   return new L.Control.Watermark(opts)
    // }
    // L.control.watermark({ position: 'bottomleft' }).addTo(map)
  } catch (e) {
    console.log(e)
  }
}
export default MapControls
