import React, { useContext, useRef, useEffect, useState } from 'react'
import L from 'leaflet'
// import 'leaflet/dist/leaflet.css'
import 'leaflet-imageoverlay-rotated'
import 'leaflet-toolbar'
import 'leaflet-distortableimage'
import MapControls from './MapControls'
import { Loader, Alert, Whisper, Tooltip, DateRangePicker } from 'rsuite'
import * as turf from '@turf/turf'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as FA from '@fortawesome/free-solid-svg-icons'
import '../styles/sidenav.css'
import Cookies from 'js-cookie'
import { addGeoJSON, deleteGeoJSON, getGeoJSON, getTempDay } from '../http/API'
import moment from 'moment'
// import { HeatmapLayer } from 'react-leaflet-heatmap-layer-v3'

const MapEmpty = () => {
  const [curTheme, setTheme] = useState(Cookies.get('theme') === 'black' ? 'black' : 'white')
  if (curTheme === 'white') {
    document.documentElement.style.setProperty('--theme-main', 'darkblue')
    document.documentElement.style.setProperty('--theme-text', 'white')
    document.documentElement.style.setProperty('--theme-secondary', 'lightgray')
    document.documentElement.style.setProperty('--theme-contrast', 'black')
  } else if (curTheme === 'black') {
    document.documentElement.style.setProperty('--theme-main', 'lime')
    document.documentElement.style.setProperty('--theme-text', 'black')
    document.documentElement.style.setProperty('--theme-secondary', 'darkslategray')
    document.documentElement.style.setProperty('--theme-contrast', 'white')
  }
  const themeChangeFunc = () => {
    if (curTheme === 'black') {
      Cookies.set('theme', 'white')
      setTheme('white')
      document.documentElement.style.setProperty('--theme-main', 'darkblue')
      document.documentElement.style.setProperty('--theme-text', 'white')
      document.documentElement.style.setProperty('--theme-secondary', 'lightgray')
      document.documentElement.style.setProperty('--theme-contrast', 'black')
    } else {
      Cookies.set('theme', 'black')
      setTheme('black')
      document.documentElement.style.setProperty('--theme-main', 'lime')
      document.documentElement.style.setProperty('--theme-text', 'black')
      document.documentElement.style.setProperty('--theme-secondary', 'darkslategray')
      document.documentElement.style.setProperty('--theme-contrast', 'white')
    }
  }
  const [jsonToSave, saveJSON] = useState('')
  const [markerJSON, setMarkerJSON] = useState('')
  const [jsonToDisplay, setJSON] = useState([])
  const [fieldToUpdate, setFieldToUpdate] = useState()
  const [updateKey, update] = useState(0)
  const [checker, sureness] = useState(false)

  const [mapType, setMap] = useState(Cookies.get('pref-map') || 'http://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}')
  function sortByProperty (property) {
    return function (a, b) {
      if (a[property] > b[property]) {
        return 1
      } else if (a[property] < b[property]) {
        return -1
      }
      return 0
    }
  }
  // useEffect(async () => {
  //   const fieldsList = await getGeoJSON()
  //     .catch((e) => {
  //       if (String(e).includes('401')) {
  //         Cookies.remove('token')
  //         window.location.href = '/map_main'
  //       }
  //     })
  //   if (fieldsList.response.success) {
  //     setJSON(fieldsList.response.fields.sort(sortByProperty('id')))
  //     try {
  //       if (updateKey === 0) {
  //         fitBounds(JSON.parse(fieldsList.response.fields[0].json))
  //       }
  //     } catch (e) {
  //       console.log(e)
  //     }
  //   }
  //   showLayerRef.current.clearLayers()
  // }, [updateKey])
  const addField = async (field, notClear) => {
    try {
      let response
      const resp = await addGeoJSON(field).then((res) => {
        response = res.response
        if (response.success) {
          Alert.success(response.message)
          !notClear && clearLayers()
          !notClear && update(updateKey + 1)
        } else {
          Alert.error(response.message)
        }
      })
    } catch (e) {
      Alert.error(t('error.noconnection'))
      console.log(e)
    }
  }
  const addMarker = async (field) => {
    try {
      let response
      const resp = await addGeoJSON(field).then((res) => {
        response = res.response
        if (response.success) {
          Alert.success(response.message)
          clearLayers()
          update(updateKey + 1)
        } else {
          Alert.error(response.message)
        }
      })
    } catch (e) {
      Alert.error(t('error.noconnection'))
      console.log(e)
    }
  }
  const deleteFields = async (field) => {
    if (!checker) {
      Alert.warning(t('warning.clickagain'))
      sureness(true)
    } else {
      sureness(false)
      try {
        let response
        const resp = await deleteGeoJSON(field).then((res) => {
          response = res.response
          if (response.success) {
            Alert.success(response.message)
            clearLayers()
            update(updateKey + 1)
          } else {
            Alert.error(response.message)
          }
        })
      } catch (e) {
        Alert.error(t('error.noconnection'))
        console.log(e)
      }
    }
  }

  const [selField, setSelField] = useState()
  const [fieldMaster, setfieldMaster] = useState()
  const [fieldNameMaster, setfieldNameMaster] = useState()
  const { t, i18n } = useTranslation()
  // const { dataTiles, fetchDataTiles, activeTile, setActiveTile } = useContext(SatImgContext)
  // const { searchOptions, setSearchOptions, jsonLayers, newOrder, setNewOrder } = useContext(SearchOptionsContext)
  const [isLoading, setIsLoading] = useState(false)

  const MAX_AREA = 6313063483978.778// in ha
  const footprintOptions = {
    stroke: true,
    // color: '#4185f4',
    color: '#E3AF34',
    weight: 3,
    opacity: 0.6,
    fill: true,
    fillColor: null,
    fillOpacity: 0,
    clickable: true
  }

  const mapRef = useRef(null) // map
  const paneRef = useRef(null) // tiles order on top
  const drawnLayerRef = useRef(null) // drawn footrpints - paths (lines) = featureGroup
  const markerRef = useRef(null) // drawn footrpints - paths (lines) = featureGroup
  const showLayerRef = useRef(null) // drawn footrpints - paths (lines) = featureGroup
  const tileLayerRef = useRef(null) // tiles in tilelayer = featureGroup
  const heatmapLayer = useRef(null) // tiles in tilelayer = featureGroup/
  const searchGeometryRef = useRef(null) // search geometries on the map = featureGroup
  // const [maptype, changeMap] = useState('alidade_smooth_dark')
  let map

  const handlePolygonClick = (e) => {
    // setCreateModalShow(true)
    const point = turf.point([e.latlng.lng, e.latlng.lat])
    // dataTiles?.forEach(scene => {
    //   if (turf.booleanPointInPolygon(point, scene.geometry)) {
    //     setActiveTile(scene, true)
    //   }
    // })
  }

  // TODO: remove if not used in production = map cleaning - alternative method
  // function removeLayers () {
  //   if (mapRef?.current) {
  //     if (mapRef.current.hasLayer(drawnLayerRef.current)) {
  //       mapRef.current.removeLayer(drawnLayerRef.current)
  //     }
  //     if (mapRef.current.hasLayer(tileLayerRef.current)) {
  //       mapRef.current.removeLayer(tileLayerRef.current)
  //     }
  //   }
  // }

  function clearLayers () {
    if (mapRef?.current) {
      // if (mapRef.current.hasLayer(searchGeometryRef.current)) searchGeometryRef.current.clearLayers()
      if (mapRef.current.hasLayer(drawnLayerRef.current)) drawnLayerRef.current.clearLayers()
      // if (mapRef.current.hasLayer(markerRef.current)) markerRef.current.clearLayers()
      if (mapRef.current.hasLayer(tileLayerRef.current)) tileLayerRef.current.clearLayers()
      // if (mapRef.current.hasLayer(heatmapLayer.current)) heatmapLayer.current.clearLayers()
    }
  }

  /** map initialization */
  useEffect(() => {
    try {
      map = L.map('map', {
        center: [49.945963, 82.613922],
        zoom: 10,
        minZoom: 1,
        zoomControl: false,
        attributionControl: false
      })
      L.tileLayer(mapType, {
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
        maxZoom: 18,
        id: 'baselayer'
      }).addTo(map)
      L.control.attribution({
        position: 'bottomright'
      }).addTo(map)
      // map.fitBounds([[47.12995075666307, 52.064208984375], [51.1807, 71.461]])

      // Searched geometries
      searchGeometryRef.current = L.featureGroup().addTo(map)
      showLayerRef.current = L.featureGroup().addTo(map)
      drawnLayerRef.current = L.featureGroup().addTo(map)
      tileLayerRef.current = L.featureGroup().addTo(map)
      // heatmapLayer.current = HeatmapLayer()
      // heatmapLayer.current.addTo(map)

      mapRef.current = map
      // Pane for tiles topping on the map
      const topPane = mapRef?.current.createPane('leaflet-top-pane', mapRef.current.getPanes().mapPane)
      paneRef.current = topPane

      // Adding Controls
      MapControls(mapRef.current, t)
      mapRef.current.invalidateSize(9)
    } catch (e) {
      console.log(e)
    }
  }, [])
  useEffect(() => {
    // mapRef.current.removeLayer('baselayer')
    mapRef.current.attributionControl.setPrefix(Cookies.get('pref-attr') || `&copy; ${t('common.tablet')} ${t('common.agro')} v 3.0 , &copy; <a href="https://google.com/">Google</a> &copy; <a href="http://maps.google.com">Google Map</a> contributors`)
    L.tileLayer(mapType, {
      maxZoom: 18,
      id: 'baselayer'
    }).addTo(mapRef.current)
  }, [mapType])
  // useEffect(() => {
  //   searchGeometryRef.current.clearLayers()
  //   jsonToDisplay.forEach((f) => {
  //     const mask = L.geoJSON(JSON.parse(f.json))
  //     const name = JSON.parse(f.json).properties.name
  //     console.log()
  //     if (JSON.parse(f.json).geometry?.type === 'Point') {
  //       mask.bindTooltip(() => name, { permanent: true })
  //       mask.addEventListener('mouseover', () => {
  //         mask.unbindTooltip()
  //         const time = new Date(f.createdAt)
  //         mask.bindTooltip(() => `Создано: ${time.toLocaleString('ru-RU')}`, { permanent: true })
  //       })
  //       mask.addEventListener('mouseout', () => {
  //         mask.unbindTooltip()
  //         mask.bindTooltip(() => name, { permanent: true })
  //       })
  //     } else {
  //       mask.bindTooltip(() => name)
  //       mask.addEventListener('click', () => { setfieldMaster(f.id); setSelField(f) })
  //       mask.addEventListener('dblclick', () => {
  //         if (window.innerWidth > 840) {
  //           fitBounds(JSON.parse(f.json))
  //           CloseOtherModals()
  //           // setDataModalShow(true)
  //           setFieldsListShow(true)
  //           setSelField(f)
  //           setFieldDataViewerShow(true)
  //           setfieldMaster(f.id)
  //         } else {
  //           window.open(`/datasave/${f.id}`, '_blank')
  //         }
  //       })
  //     }
  //     mask.addEventListener('mouseover', () => (mask.openTooltip()))
  //     searchGeometryRef.current.addLayer(mask)
  //   })
  // }, [jsonToDisplay])
  /** Handling drawing on map */
  // const handleDrawCreated = React.useCallback(e => {
  //   console.log(e)
  //   clearLayers()
  //   const layer = e.layer
  //   console.log(layer)
  //   if (e.layerType === 'marker') {
  //     console.log(e)
  //     CloseOtherModals()
  //     setCreateMarkerModalShow(true)
  //     const gjson = layer.toGeoJSON()
  //     gjson.properties.name = '???'
  //     setMarkerJSON(JSON.stringify(gjson))
  //     drawnLayerRef.current.addLayer(layer)
  //   } else {
  //     const area = L.GeometryUtil.geodesicArea(layer.getLatLngs()[0])
  //     const gjson = layer.toGeoJSON()
  //     gjson.properties = {
  //       name: 'test',
  //       area: area
  //     }
  //     saveJSON(JSON.stringify(gjson))
  //     if (area <= MAX_AREA) {
  //       // setSearchLayer(() => layer.toGeoJSON())
  //     } else {
  //       Alert.info(t('errors.bigarea'), 5000)
  //     }
  //     drawnLayerRef.current.addLayer(layer)
  //   }
  // }, [])
  // useEffect(() => {
  //   // mapRef.current.on('draw:created', function (e) {
  //   //   // const type = e.layerType
  //   //   const layer = e.layer
  //   //   setSearchLayer(layer.toGeoJSON())
  //   //   searchGeometryRef.current.clearLayers()
  //   //   searchGeometryRef.current.addLayer(e.layer)
  //   // })
  //   mapRef.current.on('draw:created', handleDrawCreated)
  // }, [handleDrawCreated])

  const fitBounds = (field) => {
    const mask = L.geoJSON(field)
    console.log(field)
    if (field.geometry?.type === 'Point') {
      const lat = field.geometry.coordinates[1]
      const lng = field.geometry.coordinates[0]
      mapRef.current.panTo([lat, lng], 10)
    } else {
      mapRef.current.fitBounds(mask.getLayers()[0].getLatLngs())
    }
  }
  return (
    <>
          <div className='navbar-side-wrapper'>
        <nav className='navbar-side'>
          <div className='navbar nav-side'>
          <Whisper
              trigger='hover' placement={window.innerWidth > 840 ? 'bottom' : 'right'} speaker={
                <Tooltip>{t('common.return')}</Tooltip>
                    }
            >
              <button onClick={() => { window.location.href = '/profile' }} className='new-link-element'>
                <FontAwesomeIcon icon={FA.faHome} />
              </button>
            </Whisper>
          </div>
          </nav></div>
      <div id='map'>
        {isLoading && <Loader inverse size='md' center content='loading' className='z-600' />}
      </div>
    </>
  )
}

export default MapEmpty
