import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as FA from '@fortawesome/free-solid-svg-icons'
import { getFields, addField, removeField, getGeoJSON, getFieldProps } from '../http/API'
import { Input, Alert, Button, InputGroup, SelectPicker } from 'rsuite'
import Cookies from 'js-cookie'
import { useParams } from 'react-router'
import { useTranslation } from 'react-i18next'

function DataSave ({ slave, fieldId_master, adClass, hideFunc }) {
  const { t, i18n } = useTranslation()
  function sortByProperty (property) {
    return function (a, b) {
      if (a[property] > b[property]) {
        return 1
      } else if (a[property] < b[property]) {
        return -1
      }
      return 0
    }
  }
  const fieldID = useParams().id
  const [selField, selectField] = useState()
  const [chartID, setChartID] = useState()
  const [chartYear, setChartYear] = useState()
  const [fieldsToSend, setFieldsToSend] = useState()
  const [fieldsToDisplay, setFieldsToDisplay] = useState()
  const [isUpdating, setUpdate] = useState(false)
  useEffect(async () => {
    if (slave && fieldId_master) {
      await getField(fieldId_master)
    } else if (slave) {
      await getField(1)
    } else {
      await getField(fieldID)
    }
  }, [fieldId_master])
  const getField = async (id) => {
    const resp = await getGeoJSON(true)
    setFieldsToDisplay(resp.response.fields)
    console.log(resp.response.fields)
    resp.response.fields.forEach(e => {
      if (e.id === Number(id)) {
        selectField(e)
        const name = e.properties.name
        document.getElementById('field_name').value = name
        setData({ ...data, field_name: name })
      }
    })
  }
  const [fieldsArray, setFields] = useState([])
  const [updateKey, update] = useState(0)
  useEffect(async () => {
    const fieldsList = await getFields()
    if (fieldsList.response.success) {
      setFields(fieldsList.response.fields.sort(sortByProperty('season')))
    }
  }, [updateKey])
  const [curTheme, setTheme] = useState(Cookies.get('theme') === 'black' ? 'black' : 'white')
  if (!slave) {
    if (curTheme === 'white') {
      document.documentElement.style.setProperty('--theme-main', 'darkblue')
      document.documentElement.style.setProperty('--theme-text', 'white')
      document.documentElement.style.setProperty('--theme-secondary', 'lightgray')
      document.documentElement.style.setProperty('--theme-contrast', 'black')
    } else if (curTheme === 'black') {
      document.documentElement.style.setProperty('--theme-main', 'lime')
      document.documentElement.style.setProperty('--theme-text', 'black')
      document.documentElement.style.setProperty('--theme-secondary', 'darkslategray')
      document.documentElement.style.setProperty('--theme-contrast', 'white')
    }
  }
  const themeChangeFunc = () => {
    if (curTheme === 'black') {
      Cookies.set('theme', 'white')
      setTheme('white')
      document.documentElement.style.setProperty('--theme-main', 'darkblue')
      document.documentElement.style.setProperty('--theme-text', 'white')
      document.documentElement.style.setProperty('--theme-secondary', 'lightgray')
      document.documentElement.style.setProperty('--theme-contrast', 'black')
    } else {
      Cookies.set('theme', 'black')
      setTheme('black')
      document.documentElement.style.setProperty('--theme-main', 'lime')
      document.documentElement.style.setProperty('--theme-text', 'black')
      document.documentElement.style.setProperty('--theme-secondary', 'darkslategray')
      document.documentElement.style.setProperty('--theme-contrast', 'white')
    }
  }
  const cropData = {
    'Пшеница яровая': {
      'Казахстанская-1': {
        additives: ['Нитроаммофоска', 'Диаммофоска'],
        classes: [1, 2, 3]
      },
      'Ульбинка-25': {
        additives: ['Аммиачная селитра', 'Двойной суперфосфат'],
        classes: [1, 2, 3]
      },
      'Саратовская-29': {
        additives: ['Диаммофоска'],
        classes: [1, 2, 3]
      },
      'Саратовская-36': {
        additives: ['Диаммофоска'],
        classes: [1, 2, 3]
      }
    },
    'Пшеница озимая': {
      'Безостая-100': {
        additives: ['Нитроаммофоска', 'Диаммофоска'],
        classes: [1, 2, 3]
      },
      Граф: {
        additives: ['Аммиачная селитра', 'Двойной суперфосфат'],
        classes: [1, 2, 3]
      },
      Собербаш: {
        additives: ['Диаммофоска'],
        classes: [1, 2, 3]
      }
    },
    'Яровой ячмень': {
      'Омский-90': {
        additives: ['Нитроаммофоска', 'Суперфосфат'],
        classes: [1, 2, 3]
      },
      'Целинный-5': {
        additives: ['Нитроаммофоска', 'Суперфосфат'],
        classes: [1, 2, 3]
      },
      'Астана-17': {
        additives: ['Нитроаммофоска', 'Суперфосфат'],
        classes: [1, 2, 3]
      }
    },
    Подсолнечник: {
      Заря: {
        additives: ['Нитроаммофоска', 'Суперфосфат'],
        classes: [1, 2, 3]
      },
      'Казахстан-25': {
        additives: ['Нитроаммофоска', 'Суперфосфат'],
        classes: [1, 2, 3]
      },
      Астана: {
        additives: ['Нитроаммофоска', 'Суперфосфат'],
        classes: [1, 2, 3]
      }
    },
    'Горох посевной': {
      'Аксайский усатый 55': {
        additives: ['Аммофос', 'Суперфосфат'],
        classes: [1, 2, 3]
      },
      Ямал: {
        additives: ['Аммофос', 'Суперфосфат'],
        classes: [1, 2, 3]
      },
      Губернатор: {
        additives: ['Аммофос', 'Суперфосфат'],
        classes: [1, 2, 3]
      }
    },
    'Кукуруза на силос': {
      'Казахстанская-45': {
        additives: ['Аммиачная селитра', 'Сульфат аммония'],
        classes: [1, 2, 3]
      },
      Днепропетровский: {
        additives: ['Аммиачная селитра', 'Сульфат аммония'],
        classes: [1, 2, 3]
      },
      Кабардинка: {
        additives: ['Аммиачная селитра', 'Сульфат аммония'],
        classes: [1, 2, 3]
      }
    }
  }
  const [data, setData] = useState({
    season: '',
    date_b: '',
    date_e: '',
    croptype: '',
    class: '',
    sort: '',
    area: '',
    yield: '',
    processing: '',
    misc: '',
    field_name: ''
  })
  // useEffect(() => {
  //   console.log(data)
  // }, [data])
  const submitField = async () => {
    if (document.getElementById('season').value !== '' && document.getElementById('croptype').value !== '') {
      try {
        const resp = await addField(data).then((res) => {
          const response = res.response
          if (response.success) {
            Alert.success(response.message)
            update(() => updateKey + 1)
          } else {
            Alert.error(response.message)
          }
        })
      } catch (e) {
        Alert.error('Ошибка соединения с сервером!')
        console.log(e)
      }
    } else {
      Alert.error('Введите тип посева и сезон!')
    }
  }
  const [checker, sureness] = useState(false)
  const deleteField = async (id) => {
    if (!checker) {
      Alert.warning('Нажмите еще раз для подтверждения!')
      sureness(true)
    } else {
      sureness(false)
      try {
        const resp = await removeField(id).then((res) => {
          const response = res.response
          if (response.success) {
            Alert.success(response.message)
            update(() => updateKey + 1)
          } else {
            Alert.error(response.message)
          }
        })
      } catch (e) {
        Alert.error('Ошибка соединения с сервером!')
      }
    }
  }
  return (
    <div className={slave && `dropdown-content ${adClass}`} style={slave && { width: '400px', overflowY: 'auto', maxHeight: '80vh' }}>
      <datalist id='classes'>
        {Object.keys(cropData).includes(data.croptype) && Object.keys(cropData[data.croptype]).includes(data.sort)
          ? cropData[data.croptype][data.sort].classes.map((a, key) => {
            return (
                <option key={key + 400} value={a}>{a}</option>
            )
          })
          : <>
            <option value='1'>1</option>
            <option value='2'>2</option>
            <option value='3'>3</option>
            </>}
      </datalist>
      <datalist id='additives'>
        {Object.keys(cropData).includes(data.croptype) && Object.keys(cropData[data.croptype]).includes(data.sort) &&
      cropData[data.croptype][data.sort].additives.map((a, key) => {
        return (
          <option key={key + 300} value={a}>{a}</option>
        )
      })}
      </datalist>
      <datalist id='sorts'>
        {Object.keys(cropData).includes(data.croptype)
          ? Object.keys(cropData[data.croptype]).map((a, key) => {
            return (
                <option key={key + 200} value={a}>{a}</option>
            )
          })
          : ''}
      </datalist>
      <datalist id='cultures'>
        {Object.keys(cropData).map((a, key) => {
          return (
            <option key={key + 100} value={a}>{a}</option>
          )
        })}
      </datalist>
      <datalist id='fields'>
        {fieldsToDisplay?.map((a, key) => {
          return (
            <option key={key + 400} value={a.properties.name}>{a.properties.name}</option>
          )
        })}
      </datalist>
      <div className='calcmodal-body data' key={updateKey} style={slave && { width: '100%', margin: 0 }}>
        {
slave
  ? <a style={{ width: 'fit-content', float: 'right', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' onClick={() => hideFunc(false)}>
    <FontAwesomeIcon icon={FA.faTimes} />
  </a>
  : <><a style={{ width: 'fit-content', float: 'left', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' onClick={themeChangeFunc}>
    <FontAwesomeIcon icon={FA.faPalette} />
  </a>
    <a style={{ width: 'fit-content', float: 'right', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' href='/profile'>
      <FontAwesomeIcon icon={FA.faHome} />
    </a>
    <a style={{ width: 'fit-content', float: 'right', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' onClick={() => i18n.changeLanguage(i18n.language === 'ru' ? 'kz' : i18n.language === 'kz' ? 'en' : 'ru')}>
      <h5 style={{ margin: 'auto' }}>{i18n.language.toUpperCase()}</h5>
    </a>
  </>
}
        <h3 align='center'>{`Севооборот для ${Cookies.get('place')}`} {(slave || fieldID !== 'all') && selField ? `(поле ${selField.properties.name})` : ''}</h3>
        <br />
        {
    fieldsArray.length === 0
      ? 'У вашего хозяйства пока нет заметок!'
      : fieldsArray.map((f, key) => {
        // console.log(JSON.parse(selField.json).properties.name, f.field_name)
        if (((fieldId_master || fieldID !== 'all') && data.field_name === f.field_name) || fieldID === 'all') {
          return (
            <div key={key}>
              <div
                className='note-div'
                onClick={() => {
                  f.display = !f.display
                  const cl = document.getElementById(`note-div-${f.id}`).className
                  if (cl === 'hidden') {
                    document.getElementById(`note-div-${f.id}`).className = 'note-div-child'
                  } else {
                    document.getElementById(`note-div-${f.id}`).className = 'hidden'
                  }
                }}
              >
                <p style={{ width: '90%' }}>{f.season}: Поле {f.field_name} - {f.croptype} (Автор: {f.username}, обновлено:  {f.updatedAt.replace('T', ' ').slice(0, -8)})</p>
                <Button
                  style={{ width: 'fit-content', fontSize: '10px', padding: '2px', float: 'right', margin: 0 }} id='delete' onClick={() => {
                    deleteField(f.id)
                  }}
                ><FontAwesomeIcon icon={FA.faTrashAlt} />
                </Button>
              </div>
              <div id={`note-div-${f.id}`} className='hidden'>
              <p>Дата сева: {f.date_b}</p>
              <p>Дата уборки: {f.date_e}</p>
              <p>Класс: {f.class}</p>
              <p>Сорт: {f.sort}</p>
              <p>Площадь сева: {(f.area / 10000).toFixed(3)} га</p>
              <p>Урожайность: {f.yield} ц/га</p>
              <p>Обработка почвы: {f.processing}</p>
              <p>Заметки: {f.misc}</p>
              <br />
              <button style={{ background: 'var(--theme-text)', color: 'var(--theme-contrast)' }} onClick={() => {
                setUpdate(true)
                setChartID(f.field_name)
                setChartYear(f.season)
                setFieldsToSend(fieldsArray.filter((e) => e.field_name === f.field_name))
                setData(() => ({ season: f.season, date_b: f.date_b, date_e: f.date_e, croptype: f.croptype, class: f.class, sort: f.sort, area: f.area, yield: f.yield, processing: f.processing, misc: f.misc, field_name: f.field_name }))
                document.getElementById('season').value = f.season
                document.getElementById('date_b').value = f.date_b
                document.getElementById('date_e').value = f.date_e
                document.getElementById('croptype').value = f.croptype
                document.getElementById('class').value = f.class
                document.getElementById('sort').value = f.sort
                document.getElementById('area').value = (f.area / 10000).toFixed(3)
                document.getElementById('yield').value = f.yield
                document.getElementById('processing').value = f.processing
                document.getElementById('misc').value = f.misc
                document.getElementById('field_name').value = f.field_name
              }}>Редактировать</button>
              </div>
            </div>
          )
        }
      })
    }
      </div>
      <div id='datasave' className='calcmodal-body data' style={slave && { width: '100%', margin: 0 }}>
        <h3 align='center'>{isUpdating ? `Просмотр / обновление заметки для поля ${data.field_name}` : 'Создание новой заметки'}</h3>
        {/* <p>
          Используя внесенные данные, мы улучшаем программу на основе машинного
          обучения
        </p> */}
        <br />Номер(имя) поля <SelectPicker style={{ width: '100%' }} id='field_name' data={fieldsToDisplay?.map(e => ({ label: e.properties.name, value: e.properties.name }))} type='text' onChange={
          async (e) => {
            isUpdating && setUpdate(false)
            try {
              await getFieldProps(e).then(j => {
                if (j.response.properties && j.response.success) {
                  const area = j.response.properties.area
                  setData({ ...data, area: area, field_name: e })
                  document.getElementById('area').value = (area / 10000).toFixed(3)
                } else {
                  document.getElementById('area').value = ''
                  setData({ ...data, field_name: e })
                }
              })
            } catch (e) {
              console.log(e)
              setData({ ...data, field_name: e })
            }
          }
          } />
        Сезон (год) <Input id='season' type='number' onChange={e => { setData({ ...data, season: e }); isUpdating && setUpdate(false) }} />
        Культура <Input id='croptype' list='cultures' type='text' onChange={e => { setData({ ...data, croptype: e }); isUpdating && setUpdate(false) }} />
        <hr />
        <br />
        Дата сева <Input id='date_b' type='date' placeholder='0' onChange={e => setData({ ...data, date_b: e })} />
        Дата уборки <Input id='date_e' type='date' onChange={e => setData({ ...data, date_e: e })} />
        Класс <Input id='class' type='text' list='classes' onChange={e => setData({ ...data, class: e })} />
        Сорт <Input id='sort' type='text' list='sorts' onChange={e => setData({ ...data, sort: e })} />
        Площадь сева <InputGroup>
      <Input id='area' type='text' onChange={e => setData({ ...data, area: e })} />
      <InputGroup.Addon>га</InputGroup.Addon>
    </InputGroup>
    Урожайность<InputGroup>
      <Input id='yield' type='text' onChange={e => setData({ ...data, yield: e })} />
      <InputGroup.Addon>ц/га</InputGroup.Addon>
    </InputGroup>
        Обработка почвы  <Input id='processing' type='text' list='additives' onChange={e => setData({ ...data, processing: e })} />
        Заметки <Input id='misc' type='text' onChange={e => setData({ ...data, misc: e })} />
        <br />
        <br />
        <button
          id='save' onClick={() => {
            submitField()
          }}
        >
          {isUpdating ? 'Обновить данные' : 'Сохранить данные'}
        </button>
        <br />
      </div>
    </div>
  )
}

export default DataSave
