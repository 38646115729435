import React, { createContext, Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import App from './App'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import 'leaflet-geosearch/dist/geosearch.css'
import './styles/index.css'
import { CookiesProvider } from 'react-cookie'
import './i18n'

export const Context = createContext(null)
// TODO: get rid of unneccessary redux
// require('dotenv').config()
ReactDOM.render(
  <Suspense fallback={(<div>Loading</div>)}>

    <CookiesProvider>
      <App />
    </CookiesProvider>
  </Suspense>,
  document.getElementById('root')
)
