import React from 'react'
import Cookies from 'js-cookie'

const ExternalLinks = () => {
  const [curTheme, setTheme] = React.useState(Cookies.get('theme') === 'black' ? 'black' : 'white')
  if (curTheme === 'white') {
    document.documentElement.style.setProperty('--theme-main', 'darkblue')
    document.documentElement.style.setProperty('--theme-text', 'white')
    document.documentElement.style.setProperty('--theme-secondary', 'lightgray')
    document.documentElement.style.setProperty('--theme-contrast', 'black')
  } else if (curTheme === 'black') {
    document.documentElement.style.setProperty('--theme-main', 'lime')
    document.documentElement.style.setProperty('--theme-text', 'black')
    document.documentElement.style.setProperty('--theme-secondary', 'darkslategray')
    document.documentElement.style.setProperty('--theme-contrast', 'white')
  }
  const themeChangeFunc = () => {
    if (curTheme === 'black') {
      Cookies.set('theme', 'white')
      setTheme('white')
      document.documentElement.style.setProperty('--theme-main', 'darkblue')
      document.documentElement.style.setProperty('--theme-text', 'white')
      document.documentElement.style.setProperty('--theme-secondary', 'lightgray')
      document.documentElement.style.setProperty('--theme-contrast', 'black')
    } else {
      Cookies.set('theme', 'black')
      setTheme('black')
      document.documentElement.style.setProperty('--theme-main', 'lime')
      document.documentElement.style.setProperty('--theme-text', 'black')
      document.documentElement.style.setProperty('--theme-secondary', 'darkslategray')
      document.documentElement.style.setProperty('--theme-contrast', 'white')
    }
  }
  return (
    <div className='original-wrapper'>
      <section>
        <div className='gallery'>
          <div className='gallery__unit large'>
            <div className='gallery__unit--text'>
              <div>
                <h2 className='__head'>
                  Внешние <br />
                  ресурсы
                </h2>
                <br />
                <br />
                <br />
                <br />
                <a className='transparent-button' href='/profile'>
                  НАЗАД
                </a>
              </div>
            </div>
          </div>
          <div className='gallery__unit'>
            <div className='gallery__unit--img'>
              <div />
              <h3>QOLDAU</h3>
              <img src='img/ph1.png' alt='img' />
              <a href='https://authz.qoldau.kz/Account/Login?ReturnUrl=%2Fconnect%2Fauthorize%2Fcallback%3Fclient_id%3DIdentityUsersClient%26redirect_uri%3Dhttps%253A%252F%252Fqmarket.qoldau.kz%252Fsignin-oidc%26response_type%3Dcode%2520id_token%26scope%3Dopenid%2520profile%2520offline_access%26response_mode%3Dform_post%26nonce%3D637696527647452073.ODAzMGMwNjMtNDA4MC00ZWFmLThlMmQtY2RlMWQ2YTZjNmRjNDU0ZDI4MTctMjRlNi00ZDkwLWIwZTAtNWZlNzg2ZTA2ZTZk%26state%3DCfDJ8LVQvne65ytNpYjWWKwziNrbagy9wsn9npa_r7y5gN4R5_luLfoB1TUj83oLM624pWqVVWm5Yro6_8ll08Jb-3K64OXFcPflug3Eb040ZfmCzsdO9M2PHrdyRrrY047enrufxLL1VZzBp4noCQyb0I7yZM2yF91hbIvCxfVvBjrDnhwRAGsIe10459drQoAF8QIFqGZW-EbVmHpVn2tnIlKtAYrYlc3IKNCsrK1NoPNPtFEErj1y7ijs-qAgBbuM483u5k2ddgrvAcPYwaO5b86E_cJg9g08OU0b7XO9_aX9IRG9YUk1DmcKTxCSGyx_X3nDNbi98dqrvF7-GiT8-2A%26x-client-SKU%3DID_NETSTANDARD2_0%26x-client-ver%3D5.5.0.0' />
            </div>
          </div>
          <div className='gallery__unit large'>
            <div className='gallery__unit--img'>
              <div />
              <h3>
                Управление <br /> земельного кадастра (aisgzk)
              </h3>
              <img src='img/ph1.png' alt='img' />
              <a href='http://www.aisgzk.kz/aisgzk/ru/content/maps/' />
            </div>
          </div>
          <div className='gallery__unit large'>
            <div className='gallery__unit--img'>
              <div />
              <h3>
                Погода
              </h3>
              <a href='https://www.ventusky.com/?p=45.2;57.7;4&l=temperature-2m' />
            </div>
          </div>
        </div>
      </section>
    </div>

  )
}

export default ExternalLinks
