import React from 'react'
import { CanvasJSChart } from 'canvasjs-react-charts'
import Cookies from 'js-cookie'

const ChartModalCrop = ({ year, fields, update }) => {
  const [updateKey, Aupdate] = React.useState(0)
  const crops = {}
  const detYield = () => {
    fields.filter((e) => e.season === year).map((e) => {
      crops[e.croptype] ? crops[e.croptype] += Number(e.yield.replace(',', '.')) : crops[e.croptype] = Number(e.yield.replace(',', '.'))
      console.log(crops)
    })
    return Object.keys(crops).map((e) => {
      console.log(e)
      return (
        { label: e, y: crops[e] }
      )
    })
  }
  const options = {
    dataPointMaxWidth: 50,
    animationEnabled: true,
    exportEnabled: true,
    theme: Cookies.get('theme') === 'black' ? 'dark1' : 'light1', // "light1", "dark1", "dark2"
    title: {
      text: `Суммарная урожайность по культурам за сезон ${year} (ц/га)`
    },
    axisY: {
      includeZero: true
    },
    barThickness: 1,
    data: [{
      type: 'column', // change type to bar, line, area, pie, etc
      // indexLabel: "{y}", //Shows y value on all Data Points
      indexLabelFontColor: '#5A5757',
      indexLabelPlacement: 'outside',
      indexLabel: '{y}',
      dataPoints: fields && detYield()
    }]
  }

  return (
    <div>
      <CanvasJSChart options={options} />

    </div>
  )
}
export default ChartModalCrop
