import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as FA from '@fortawesome/free-solid-svg-icons'
import Cookies from 'js-cookie'

const Manual = () => {
  const [curTheme, setTheme] = React.useState(Cookies.get('theme') === 'black' ? 'black' : 'white')
  if (curTheme === 'white') {
    document.documentElement.style.setProperty('--theme-main', 'darkblue')
    document.documentElement.style.setProperty('--theme-text', 'white')
    document.documentElement.style.setProperty('--theme-secondary', 'lightgray')
    document.documentElement.style.setProperty('--theme-contrast', 'black')
  } else if (curTheme === 'black') {
    document.documentElement.style.setProperty('--theme-main', 'lime')
    document.documentElement.style.setProperty('--theme-text', 'black')
    document.documentElement.style.setProperty('--theme-secondary', 'darkslategray')
    document.documentElement.style.setProperty('--theme-contrast', 'white')
  }
  const themeChangeFunc = () => {
    if (curTheme === 'black') {
      Cookies.set('theme', 'white')
      setTheme('white')
      document.documentElement.style.setProperty('--theme-main', 'darkblue')
      document.documentElement.style.setProperty('--theme-text', 'white')
      document.documentElement.style.setProperty('--theme-secondary', 'lightgray')
      document.documentElement.style.setProperty('--theme-contrast', 'black')
    } else {
      Cookies.set('theme', 'black')
      setTheme('black')
      document.documentElement.style.setProperty('--theme-main', 'lime')
      document.documentElement.style.setProperty('--theme-text', 'black')
      document.documentElement.style.setProperty('--theme-secondary', 'darkslategray')
      document.documentElement.style.setProperty('--theme-contrast', 'white')
    }
  }
  return (
    <div className='data calcmodal-body manual'>
      <a style={{ width: 'fit-content', float: 'left', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' onClick={themeChangeFunc}>
        <FontAwesomeIcon icon={FA.faPalette} />
      </a>
      <a style={{ width: 'fit-content', float: 'right', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' href='/profile'>
        <FontAwesomeIcon icon={FA.faHome} />
      </a>
      <h3 align='center'>РУКОВОДСТВО ПО ПРОГРАММЕ</h3>
      <br />
      <div style={{ position: 'sticky' }}>
      <h4>Содержание</h4>
      <ul>
        <li><a href='#header1'>1. Создание полей рисованием</a></li>
        <li><a href='#header2'>2. Создание полей загрузкой KML/SHP</a></li>
        <li><a href='#header3'>3. Функции полей</a></li>
        <li><a href='#header4'>4. Создание маркеров-заметок на карте</a></li>
      </ul>
      </div>
      <hr />
      <h5 id='header1'>1. Создание полей рисованием</h5>
      <br />
      <p>1.1.</p>
      <img src="img/manual/1_1.png" />
      <p>1.2. Выберите подходящий инструмент рисования - прямоугольник или многоугольник</p>
      <img src="img/manual/1_2.png" />
      <p>1.3. Нарисуйте поле на карте. Для прямоугольника необходимо нажать на один угол поля и протянуть до противоположного. Для многоугольника - поочередно нажимать по углам поля и замкнуть на начальной точке</p>
      <img src="img/manual/1_3.png" />
      <p>1.4. Введите данные о поле в форму (кадастровый номер и тип почвы - необязятельно) и нажмите на кнопку &quot;Сохранить&quot;</p>
      <img src="img/manual/1_4.png" />
      <hr />
      <h5 id='header2'>2. Создание полей загрузкой KML/SHP</h5>
      <br />
      <p>2.1.</p>
      <img src="img/manual/2_1.png" />
      <p>2.2.</p>
      <img src="img/manual/2_2.png" />
      <p>2.3. На карте отобразятся поля из загруженного файла. Каждое из полей можно отдельно сохранить с помощью меню аналогично сохранению рисованных полей</p>
      <img src="img/manual/2_3.png" />
      <hr />
      <h5 id='header3'>3. Функции полей</h5>
      <br />
      <p>3.1.</p>
      <img src="img/manual/3_1.png" />
      <p>3.2. Функции меню (слева направо):
        <ul className='ul-dot'>
          <li>Найти поле на карте</li>
          <li>Удалить поле</li>
          <li>Редактировать свойства поля</li>
          <li>Отобразить заметки по полю</li>
          <li>Получить погодные данные по региону поля</li>
          <li>Просмотреть динамику урожайности </li>
        </ul>
      </p>
      <img src="img/manual/3_2.png" />
      <hr />
      <h5 id='header4'>4. Создание маркеров-заметок на карте</h5>
      <br />
      <p>4.1.</p>
      <img src="img/manual/4_1.png" />
      <p>4.2. Нажмите на нужное место карты, чтобы поместить маркер</p>
      <img src="img/manual/4_2.png" />
      <p>4.3. Введите название заметки</p>
      <img src="img/manual/4_3.png" />
      <p>4.4. Маркер-заметка будет отображаться на карте и в списке с функциями, аналогичными полям</p>
      <img src="img/manual/4_4.png" />
    </div>
  )
}

export default Manual
