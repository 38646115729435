import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as FA from '@fortawesome/free-solid-svg-icons'
import { getGeoJSON, removeWeather, getWeather, addWeather } from '../http/API'
import { Input, Alert, Button, ControlLabel, InputGroup, Checkbox, SelectPicker, Uploader, Table, DateRangePicker } from 'rsuite'
import Cookies from 'js-cookie'
import { useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import ChartModal from './charts/Chart'
import ChartModalYear from './charts/ChartYear'
import * as XLSX from 'xlsx/xlsx.mjs'
import { Tab } from 'docx'
import moment from 'moment'
import WeatherAnalysis from './WeatherAnalysis'

function WeatherSave ({ slave, fieldId_master, adClass, hideFunc }) {
  const { t, i18n } = useTranslation()
  const [table, setTable] = useState()
  const [tableJSON, setTableJSON] = useState()
  const [noteDiv, setNoteDiv] = useState()
  const [dateRange, setDateRange] = useState()
  function sortByDateProperty (property) {
    return function (a, b) {
      let moma, momb
      if (a[property].match('.')) {
        const date_a = a[property].split('.')
        moma = moment(`${date_a[2]}-${date_a[1]}-${date_a[0]}`)
      } else {
        moma = moment(a[property])
      }
      if (a[property].match('.')) {
        const date_b = b[property].split('.')
        momb = moment(`${date_b[2]}-${date_b[1]}-${date_b[0]}`)
      } else {
        momb = moment(b[property])
      }
      if (moma.isAfter(momb)) {
        return 1
      } else if (momb.isAfter(moma)) {
        return -1
      }
      return 0
    }
  }
  const handleXLSX = (f) => {
    console.log(f)
    const name = f.name
    const reader = new FileReader()
    reader.onload = (evt) => {
      const bstr = evt.target.result
      const wb = XLSX.read(bstr, { type: 'binary' })
      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]
      // setTable(XLSX.utils.sheet_to_html(ws, { header: 1 }))
      setTableJSON(XLSX.utils.sheet_to_json(ws, { header: 1 }).map((g, key) => {
        return (
          {
            tableCellid: key,
            // field_id: String(g[12]),
            type: g[1] === 'Исторические данные' ? 'historical' : 'prediction',
            ndvi: g[2] === '-' ? null : g[2],
            maxt: g[3] === '-' ? null : g[3],
            mint: g[4] === '-' ? null : g[4],
            humiditytop: g[5] === '-' ? null : g[5],
            humiditybottom: g[6] === '-' ? null : g[6],
            windspeed: g[7] === '-' ? null : g[7],
            humidity: g[8] === '-' ? null : g[8],
            percip: g[9] === '-' ? null : g[9],
            overheat: g[10] === '1',
            overcold: g[11] === '1',
            date: getJsDateFromExcel(g[0]).toLocaleString('ru-RU').slice(0, 10)
          }
        )
      }
      ).slice(1, -1)
      )
      // document.getElementById('import-div').innerHTML = XLSX.utils.sheet_to_html(ws, { header: 1 })
      /* Update state */
    }
    reader.readAsBinaryString(f[0].blobFile)
  }
  const types = [
    {
      label: 'Прогнозные данные',
      value: 'prediction'
    },
    {
      label: 'Исторические данные',
      value: 'historical'
    }
  ]
  const fieldID = useParams().id
  const [selField, selectField] = useState()
  const [chartID, setChartID] = useState()
  const [fieldsToSend, setFieldsToSend] = useState()
  const [fieldsToShow, setFieldsToShow] = useState([])
  const [fieldsToDisplay, setFieldsToDisplay] = useState()
  const [isUpdating, setUpdate] = useState(false)
  const [isLoading, setLoading] = useState(true)
  useEffect(() => {
    setFieldsToShow(fieldsArray.filter(e => {
      let date, mom
      if (e.date.match('.')) {
        date = e.date.split('.')
        mom = moment(`${date[2]}-${date[1]}-${date[0]}`)
      } else {
        mom = moment(e.date)
      }
      return (mom.isBetween(dateRange[0], dateRange[1]))
    }).sort(sortByDateProperty('date')))
  }, [dateRange])
  useEffect(async () => {
    if (slave && fieldId_master) {
      await getField(fieldId_master)
    } else if (slave) {
      await getField(1)
    } else {
      await getField(fieldID)
    }
  }, [fieldId_master])
  const getField = async (id) => {
    const resp = await getGeoJSON(true)
    setFieldsToDisplay(resp.response.fields)
    resp.response.fields.forEach(e => {
      if (e.id === Number(id)) {
        selectField(e)
        const name = e.properties.name
        document.getElementById('field_name').value = name
        setData({ ...data, field_name: name })
      }
    })
  }
  const [fieldsArray, setFields] = useState([])
  const [updateKey, update] = useState(0)
  useEffect(async () => {
    setLoading(true)
    const fieldsList = await getWeather().then(e => {
      if (e.response.success) {
        setFields(e.response.fields)
        setLoading(false)
      }
    })
  }, [updateKey])
  const [curTheme, setTheme] = useState(Cookies.get('theme') === 'black' ? 'black' : 'white')
  if (!slave) {
    if (curTheme === 'white') {
      document.documentElement.style.setProperty('--theme-main', 'darkblue')
      document.documentElement.style.setProperty('--theme-text', 'white')
      document.documentElement.style.setProperty('--theme-secondary', 'lightgray')
      document.documentElement.style.setProperty('--theme-contrast', 'black')
    } else if (curTheme === 'black') {
      document.documentElement.style.setProperty('--theme-main', 'lime')
      document.documentElement.style.setProperty('--theme-text', 'black')
      document.documentElement.style.setProperty('--theme-secondary', 'darkslategray')
      document.documentElement.style.setProperty('--theme-contrast', 'white')
    }
  }
  const themeChangeFunc = () => {
    if (curTheme === 'black') {
      Cookies.set('theme', 'white')
      setTheme('white')
      document.documentElement.style.setProperty('--theme-main', 'darkblue')
      document.documentElement.style.setProperty('--theme-text', 'white')
      document.documentElement.style.setProperty('--theme-secondary', 'lightgray')
      document.documentElement.style.setProperty('--theme-contrast', 'black')
    } else {
      Cookies.set('theme', 'black')
      setTheme('black')
      document.documentElement.style.setProperty('--theme-main', 'lime')
      document.documentElement.style.setProperty('--theme-text', 'black')
      document.documentElement.style.setProperty('--theme-secondary', 'darkslategray')
      document.documentElement.style.setProperty('--theme-contrast', 'white')
    }
  }
  const CheckCell = ({ rowData, onChange, checkedKeys, dataKey, ...props }) => (
    <Table.Cell {...props} style={{ padding: 0 }}>
      <div style={{ lineHeight: '-35px' }}>
        <Checkbox
          value={rowData[dataKey]}
          inline
          onChange={onChange}
          checked={checkedKeys.some(item => item === rowData[dataKey])}
        />
      </div>
    </Table.Cell>
  )
  const handleCheckAll = (value, checked) => {
    const keys = checked ? tableJSON.map(item => item.tableCellid) : []
    setCheckedKeys(keys)
  }
  const handleCheck = (value, checked) => {
    const keys = checked ? [...checkedKeys, value] : checkedKeys.filter(item => item !== value)
    setCheckedKeys(keys)
  }
  const [checkedKeys, setCheckedKeys] = useState([])
  let checked = false
  let indeterminate = false

  if (checkedKeys.length === tableJSON?.length) {
    checked = true
  } else if (checkedKeys.length === 0) {
    checked = false
  } else if (checkedKeys.length > 0 && checkedKeys.length < tableJSON?.length) {
    indeterminate = true
  }
  const [data, setData] = useState({
    // field_id: false,
    type: '',
    ndvi: '',
    maxt: '',
    mint: '',
    humiditytop: '',
    humiditybottom: '',
    windspeed: '',
    humidity: '',
    percip: '',
    overheat: false,
    overcold: false,
    date: ''
  })
  // useEffect(() => {
  //   console.log(data)
  // }, [data])
  const submitField = async () => {
    if (document.getElementById('date').value !== null) {
      try {
        const resp = await addWeather(data).then((res) => {
          const response = res.response
          if (response.success) {
            Alert.success(response.message)
            update(() => updateKey + 1)
          } else {
            Alert.error(response.message)
          }
        })
      } catch (e) {
        Alert.error('Ошибка соединения с сервером!')
        console.log(e)
      }
      console.log(data)
    } else {
      Alert.error('Введите поле и дату!')
    }
  }
  const [checker, sureness] = useState(false)
  const deleteField = async (id) => {
    console.log(id)
    if (!checker) {
      Alert.warning('Нажмите еще раз для подтверждения!')
      sureness(true)
    } else {
      sureness(false)
      try {
        const resp = await removeWeather(id).then((res) => {
          const response = res.response
          if (response.success) {
            Alert.success(response.message)
            update(() => updateKey + 1)
          } else {
            Alert.error(response.message)
          }
        })
      } catch (e) {
        Alert.error('Ошибка соединения с сервером!')
      }
    }
  }
  function getJsDateFromExcel (excelDate) {
    return new Date((excelDate - (25567 + 1)) * 86400 * 1000)
  }
  const saveChecked = async () => {
    Promise.all(tableJSON.forEach(async (e) => {
      if (checkedKeys.includes(e.tableCellid)) {
        try {
          delete e.tableCellid
          const resp = await addWeather(e).then((res) => {
            const response = res.response
            if (response.success) {
              Alert.success(response.message)
            } else {
              Alert.error(response.message)
            }
          })
        } catch (e) {
          Alert.error('Ошибка соединения с сервером!')
          console.log(e)
        }
      }
    })).then(e => {
      update(updateKey + 1)
    })
    update(updateKey + 1)
  }
  return (
    <div>
<WeatherAnalysis fieldId_master={chartID} weatherData={fieldsArray} field_names={fieldsToDisplay}/>
    <div className={isLoading ? 'backdrop-loading' : 'hidden'}>
      <FontAwesomeIcon icon={FA.faSpinner} spin={true} size={'10x'}/>
    </div>
    <div className={slave && `dropdown-content ${adClass}`} style={slave && { width: '400px', overflowY: 'auto', maxHeight: '80vh' }}>
      <datalist id='fields'>
        {fieldsToDisplay?.map((a, key) => {
          return (
            <option key={key + 400} value={a.properties.name}>{a.properties.name}</option>
          )
        })}
      </datalist>
      <div className='calcmodal-body data' key={updateKey} style={slave && { width: '100%', margin: 0 }}>
        {
slave
  ? <a style={{ width: 'fit-content', float: 'right', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' onClick={() => hideFunc(false)}>
    <FontAwesomeIcon icon={FA.faTimes} />
  </a>
  : <><a style={{ width: 'fit-content', float: 'left', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' onClick={themeChangeFunc}>
    <FontAwesomeIcon icon={FA.faPalette} />
  </a>
    <a style={{ width: 'fit-content', float: 'right', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' href='/profile'>
      <FontAwesomeIcon icon={FA.faHome} />
    </a>
    <a style={{ width: 'fit-content', float: 'right', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' onClick={() => i18n.changeLanguage(i18n.language === 'ru' ? 'kz' : i18n.language === 'kz' ? 'en' : 'ru')}>
      <h5 style={{ margin: 'auto' }}>{i18n.language.toUpperCase()}</h5>
    </a>
  </>
}
        <h3 align='center'>{`Погодные данные для ${Cookies.get('place')}`} {(slave || fieldID !== 'all') && selField ? `(Погодные данные поля ${selField.properties.name})` : ''}</h3>
        <br />
        <h4>Выберите диапазон заметок: <DateRangePicker style={{ color: 'white' }} onChange={e => setDateRange([moment(e[0]), moment(e[1])])} /></h4><br />
        {
    (fieldsToShow?.length === 0 && dateRange)
      ? 'У вашего хозяйства пока нет заметок за данный период!'
      : fieldsToShow.map((f, key) => {
        // console.log(JSON.parse(selField.json).properties.name, f.field_name)
        if (((fieldId_master || fieldID !== 'all') && data.field_name === f.field_name) || fieldID === 'all') {
          return (
            <div key={key}>
              <div
                className='note-div'
                onClick={() => {
                  f.display = !f.display
                  const cl = document.getElementById(`note-div-${f.id}`).className
                  if (cl === 'hidden') {
                    document.getElementById(`note-div-${f.id}`).className = 'note-div-child'
                  } else {
                    document.getElementById(`note-div-${f.id}`).className = 'hidden'
                  }
                }}
              >
                <p style={{ width: 'fit-content' }}>{f.date}
                {/* (Автор: {f.username}, обновлено:  {f.updatedAt.replace('T', ' ').slice(0, -8)}) */}
                </p>
                <Button
                  style={{ width: 'fit-content', fontSize: '10px', padding: '2px', float: 'right', margin: 0 }} id='delete' onClick={() => {
                    deleteField(f.id)
                  }}
                ><FontAwesomeIcon icon={FA.faTrashAlt} />
                </Button>
              </div>
              <div id={`note-div-${f.id}`} className='hidden'>
              <p>Дата: {f.date}</p>
              <p>Тип данных: {f.type === 'prediction' ? 'Прогноз' : f.type === 'historical' ? 'История' : 'Неизвестно'}</p>
              <p>NDVI: {f.ndvi}</p>
              <p>Макс. температура: {f.maxt} °C</p>
              <p>Мин. температура: {f.mint} °C</p>
              <p>Влажность поверхностного слоя грунта: {f.humiditytop} %</p>
              <p>Влажность в корневой зоне: {f.humiditybottom} %</p>
              <p>Скорость ветра: {f.windspeed} м/с</p>
              <p>Влажность: {f.humidity} %</p>
              <p>Осадки: {f.percip}</p>
              <p>Тепловой шок: {f.overheat ? 'Да' : 'Нет'}</p>
              <p>Угроза заморозков: {f.overcold ? 'Да' : 'Нет'}</p>
              <br />
              <button style={{ background: 'var(--theme-text)', color: 'var(--theme-contrast)' }} onClick={() => {
                setUpdate(true)
                // setChartID(f.field_name)
                // setChartYear(f.season)
                // setFieldsToSend(fieldsArray.filter((e) => e.field_name === f.field_name))
                setData(() => (
                  {
                    // field_id: f.field_id,
                    type: f.type,
                    ndvi: f.ndvi,
                    maxt: f.maxt,
                    mint: f.mint,
                    humiditytop: f.humiditytop,
                    humiditybottom: f.humiditybottom,
                    windspeed: f.windspeed,
                    humidity: f.humidity,
                    percip: f.percip,
                    overheat: f.overheat,
                    overcold: f.overcold,
                    date: f.date
                  }
                ))
                // document.getElementById('field_name').value = f.name
                document.getElementById('type').value = f.type
                document.getElementById('type').innerHTML = f.type === 'prediction' ? 'Прогнозные данные' : f.type === 'historical' ? 'Исторические данные' : ''
                document.getElementById('ndvi').value = f.ndvi
                document.getElementById('maxt').value = f.maxt
                document.getElementById('mint').value = f.mint
                document.getElementById('humiditytop').value = f.humiditytop
                document.getElementById('humiditybottom').value = f.humiditybottom
                document.getElementById('windspeed').value = f.windspeed
                document.getElementById('humidity').value = f.humidity
                document.getElementById('percip').value = f.percip
              }}>Редактировать</button>
              </div>
            </div>
          )
        }
      })
    }
      </div>
      <div id='datasave' className='calcmodal-body data' style={slave && { width: '100%', margin: 0 }}>
        <h3 align='center'>{isUpdating ? 'Просмотр / обновление погоды' : 'Создание новой погодной заметки'}</h3>
        {/* <br />Номер(имя) поля <Input id='field_name' list='fields' type='text' onChange={e => { setData({ ...data, field_id: e }); setUpdate(false) }}/> */}
        Тип <SelectPicker id='type' className='rs-input' style={{ padding: 0, border: 0, marginBottom: 0 }} type='text' data={types} onChange={e => setData({ ...data, type: e })}/>
        Дата <Input id='date' type='date' onChange={e => { setData({ ...data, date: e }); isUpdating && setUpdate(false) }} />
        <hr />
        NDVI <Input id='ndvi' type='number' onChange={e => { setData({ ...data, ndvi: e }) }} />
        Макс. температура: <InputGroup>
      <Input id='maxt' type='number' onChange={e => setData({ ...data, maxt: e })} />
      <InputGroup.Addon>°C</InputGroup.Addon>
    </InputGroup>
        Мин. температура: <InputGroup>
      <Input id='mint' type='number' onChange={e => setData({ ...data, mint: e })} />
      <InputGroup.Addon>°C</InputGroup.Addon>
    </InputGroup>
    Скорость ветра<InputGroup>
      <Input id='windspeed' type='number' onChange={e => setData({ ...data, windspeed: e })} />
      <InputGroup.Addon>м/с</InputGroup.Addon>
    </InputGroup>
    Влажность поверхностного слоя грунта<InputGroup>
      <Input id='humiditytop' type='number' onChange={e => setData({ ...data, humiditytop: e })} />
      <InputGroup.Addon>%</InputGroup.Addon>
    </InputGroup>
    Влажность в корневой зоне<InputGroup>
      <Input id='humiditybottom' type='number' onChange={e => setData({ ...data, humiditybottom: e })} />
      <InputGroup.Addon>%</InputGroup.Addon>
    </InputGroup>
    Влажность<InputGroup>
      <Input id='humidity' type='number' onChange={e => setData({ ...data, humidity: e })} />
      <InputGroup.Addon>%</InputGroup.Addon>
    </InputGroup>
    Осадки<InputGroup>
      <Input id='percip' type='number' onChange={e => setData({ ...data, percip: e })} />
      <InputGroup.Addon>мм</InputGroup.Addon>
    </InputGroup>
    <Checkbox id='overheat' checked={data.overheat} onChange={(e, checked) => setData({ ...data, overheat: checked })}> Тепловой шок</Checkbox>
    <Checkbox id='overcold' checked={data.overcold} onChange={(e, checked) => setData({ ...data, overcold: checked })}> Угроза заморозков</Checkbox>
        <br />
        <button
          id='save' onClick={() => {
            submitField()
          }}
        >
          {isUpdating ? 'Обновить данные' : 'Сохранить данные'}
        </button>
        <a href="/weather.xlsx"><button>Скачать образец заполнения таблицы</button></a>
        <Uploader
        accept='.xlsx'
        type='file'
        onChange={(e) => handleXLSX(e)}><Button style={{ width: '100%' }}>Загрузка XLSX</Button></Uploader>

        <br />
      </div>
    </div>
    {/* { tableJSON &&
    // <div className='calcmodal-body data' >
    //   <button>Сохранить все</button>
    //   <div id='import-div' />
    // </div>
    <div className='calcmodal-body data' >
      {
    tableJSON.map((e, key) => {
      return (
        <>
        <div key = {key}>
          <p>Дата: {e[0]}</p>
        </div>
        </>
      )
    })
  }
    </div>
  } */}
  {tableJSON &&
  <div className='data'>
  <button onClick={saveChecked}>Сохранить все выделенное</button>
  <Table
      height={1000}
      data={tableJSON}
      onRowClick={data => {
        console.log(data, checkedKeys)
      }}
      style={{ color: 'white' }}
    >
      <Table.Column width={70} align="center" fixed>
        <Table.HeaderCell style={{ padding: 0 }}>
            <div style={{ lineHeight: '40px' }}>
              <Checkbox
                inline
                checked={checked}
                indeterminate={indeterminate}
                onChange={handleCheckAll}
              />
            </div>
        </Table.HeaderCell>
        <CheckCell dataKey="tableCellid" checkedKeys={checkedKeys} onChange={handleCheck} />
      </Table.Column>

      {/* <Table.Column width={100} align="center" fixed>
        <Table.HeaderCell>Имя поля</Table.HeaderCell>
        <Table.Cell dataKey="field_id" />
      </Table.Column> */}

      <Table.Column width={150} align="center" fixed>
        <Table.HeaderCell>Дата</Table.HeaderCell>
        <Table.Cell dataKey="date" />
      </Table.Column>

      <Table.Column width={100} fixed>
        <Table.HeaderCell>Тип</Table.HeaderCell>
        <Table.Cell dataKey="type" />
      </Table.Column>

      <Table.Column width={100}>
        <Table.HeaderCell>NDVI</Table.HeaderCell>
        <Table.Cell dataKey="ndvi" />
      </Table.Column>

      <Table.Column width={100}>
        <Table.HeaderCell>Макс. Т.</Table.HeaderCell>
        <Table.Cell dataKey="maxt" />
      </Table.Column>

      <Table.Column width={100}>
        <Table.HeaderCell>Мин. Т.</Table.HeaderCell>
        <Table.Cell dataKey="mint" />
      </Table.Column>

      <Table.Column width={200}>
        <Table.HeaderCell>Влажность грунта</Table.HeaderCell>
        <Table.Cell dataKey="humiditytop" />
      </Table.Column>

      <Table.Column width={200}>
        <Table.HeaderCell>Влажность корневой зоны</Table.HeaderCell>
        <Table.Cell dataKey="humiditybottom" />
      </Table.Column>
      <Table.Column width={200}>
        <Table.HeaderCell>Скорость ветра</Table.HeaderCell>
        <Table.Cell dataKey="windspeed" />
      </Table.Column>
      <Table.Column width={100}>
        <Table.HeaderCell>Влажность</Table.HeaderCell>
        <Table.Cell dataKey="humidity" />
      </Table.Column>
      <Table.Column width={100}>
        <Table.HeaderCell>Осадки</Table.HeaderCell>
        <Table.Cell dataKey="percip" />
      </Table.Column>
    </Table>
    </div>
}
    </div>
  )
}

export default WeatherSave
