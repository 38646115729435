/* eslint-disable i18next/no-literal-string */
import React, { useState } from 'react'
import { Modal, Button, Input, Alert, Form, FlexboxGrid, IconButton, Icon } from 'rsuite'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  BrowserRouter,
  Link
} from 'react-router-dom'

const ResetPasswordModal = (props) => {
  const [email, setEmail] = useState(false)
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  // const handleEmailSubmit = () => {
  //   // const errors = this.validate();
  //   // this.setState({ errors });
  //   // setLoading(true)
  //   props.sendEmail(email).then((res) => {
  //     setLoading(false)
  //     if (res.success) {
  //       Alert.success(t('ResetPassword.success'))
  //       props.onHide()
  //     } else {
  //       Alert.error(t('ResetPassword.error'))
  //     }
  //   })
  // }

  return (
    <div className='show-grid'>
      <Modal {...props} show={props.show} backdrop={false} keyboard={false}>
        <Modal.Header closeButton={false}>
          <Modal.Title className='text-center'>
            {t('Auth.ResetPassword.reset_message')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <FlexboxGrid>
              <FlexboxGrid.Item colspan={24} className='padding-15'>
                <span>
                  {t('Auth.ResetPassword.enter_email')}
                </span>
              </FlexboxGrid.Item>
              <FlexboxGrid.Item colspan={24} lassName='padding-15'>
                <Input placeholder='Email*' onChange={e => setEmail(e)} size='md' />
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <BrowserRouter forceRefresh>
            <Link to='/'>
              <IconButton appearance='ghost' size='sm' className='m-r-15' icon={<Icon icon='angle-left' />} />
            </Link>
          </BrowserRouter>
          <Button appearance='primary' color='green' size='sm' loading={loading}>
            {t('Auth.ResetPassword.send_password')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>

  )
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: !!state.user.token,
    user: state.user
  }
}

export default ResetPasswordModal
