import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as FA from '@fortawesome/free-solid-svg-icons'
import { getGeoJSON, getFieldProps, getFieldsSpecial, addFieldSpecial, removeFieldSpecial } from '../http/API'
import { Input, Alert, Button, ControlLabel, InputGroup } from 'rsuite'
import Cookies from 'js-cookie'
import { useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

function DataSaveSpecial ({ slave, fieldId_master, adClass, hideFunc }) {
  const { t, i18n } = useTranslation()
  function sortByProperty (property) {
    return function (a, b) {
      if (a[property] > b[property]) {
        return 1
      } else if (a[property] < b[property]) {
        return -1
      }
      return 0
    }
  }
  const fieldID = useParams().id
  const [selField, selectField] = useState()
  const [fieldsToDisplay, setFieldsToDisplay] = useState()
  const [isUpdating, setUpdate] = useState(false)
  useEffect(async () => {
    if (slave && fieldId_master) {
      await getField(fieldId_master)
    } else if (slave) {
      await getField(1)
    } else {
      await getField(fieldID)
    }
  }, [fieldId_master])
  const getField = async (id) => {
    const resp = await getGeoJSON(true)
    setFieldsToDisplay(resp.response.fields)
    resp.response.fields.forEach(e => {
      if (e.id === Number(id)) {
        selectField(e)
        const name = e.properties.name
        document.getElementById('field_name').value = name
        setData({ ...data, field_name: name })
      }
    })
  }
  const [fieldsArray, setFields] = useState([])
  const [updateKey, update] = useState(0)
  useEffect(async () => {
    const fieldsList = await getFieldsSpecial()
    if (fieldsList.response.success) {
      setFields(fieldsList.response.fields.sort(sortByProperty('season')))
    }
  }, [updateKey])
  const [curTheme, setTheme] = useState(Cookies.get('theme') === 'black' ? 'black' : 'white')
  if (!slave) {
    if (curTheme === 'white') {
      document.documentElement.style.setProperty('--theme-main', 'darkblue')
      document.documentElement.style.setProperty('--theme-text', 'white')
      document.documentElement.style.setProperty('--theme-secondary', 'lightgray')
      document.documentElement.style.setProperty('--theme-contrast', 'black')
    } else if (curTheme === 'black') {
      document.documentElement.style.setProperty('--theme-main', 'lime')
      document.documentElement.style.setProperty('--theme-text', 'black')
      document.documentElement.style.setProperty('--theme-secondary', 'darkslategray')
      document.documentElement.style.setProperty('--theme-contrast', 'white')
    }
  }
  const themeChangeFunc = () => {
    if (curTheme === 'black') {
      Cookies.set('theme', 'white')
      setTheme('white')
      document.documentElement.style.setProperty('--theme-main', 'darkblue')
      document.documentElement.style.setProperty('--theme-text', 'white')
      document.documentElement.style.setProperty('--theme-secondary', 'lightgray')
      document.documentElement.style.setProperty('--theme-contrast', 'black')
    } else {
      Cookies.set('theme', 'black')
      setTheme('black')
      document.documentElement.style.setProperty('--theme-main', 'lime')
      document.documentElement.style.setProperty('--theme-text', 'black')
      document.documentElement.style.setProperty('--theme-secondary', 'darkslategray')
      document.documentElement.style.setProperty('--theme-contrast', 'white')
    }
  }
  const cropData = {
    'Пшеница яровая': {

    },
    'Пшеница озимая': {

    },
    'Яровой ячмень': {
      stages: {
        Посев: {
          opti: '40-80'
        },
        'Посев-всходы': {
          opti: '120-180'
        },
        'Всходы-кущение': {
          opti: '160-200'
        },
        'Кущение-выход в трубку': {
          opti: '230-260'
        },
        'Выход в трубку-колошение': {
          opti: '200-260'
        },
        'Колошение-спелость': {
          opti: '800-850'
        }
      }
    },
    Подсолнечник: {
    },
    'Горох посевной': {
    },
    'Кукуруза на силос': {
    }
  }
  const [data, setData] = useState({
    field_id: '',
    season: '',
    date_b: '',
    date_e: '',
    averagetemp: '',
    humtop: '',
    humbottom: '',
    sum: '',
    type: '',
    croptype: ''
  })
  const submitField = async () => {
    if (document.getElementById('season').value !== '' && document.getElementById('croptype').value !== '') {
      try {
        const resp = await addFieldSpecial(data).then((res) => {
          const response = res.response
          if (response.success) {
            Alert.success(response.message)
            update(() => updateKey + 1)
          } else {
            Alert.error(response.message)
          }
        })
      } catch (e) {
        Alert.error('Ошибка соединения с сервером!')
        console.log(e)
      }
    } else {
      Alert.error('Введите тип посева и сезон!')
    }
  }
  const [checker, sureness] = useState(false)
  const deleteField = async (id) => {
    if (!checker) {
      Alert.warning('Нажмите еще раз для подтверждения!')
      sureness(true)
    } else {
      sureness(false)
      try {
        const resp = await removeFieldSpecial(id).then((res) => {
          const response = res.response
          if (response.success) {
            Alert.success(response.message)
            update(() => updateKey + 1)
          } else {
            Alert.error(response.message)
          }
        })
      } catch (e) {
        Alert.error('Ошибка соединения с сервером!')
      }
    }
  }
  return (
    <div className={slave && `dropdown-content ${adClass}`} style={slave && { width: '400px', overflowY: 'auto', maxHeight: '80vh' }}>
      <datalist id='cultures'>
        {Object.keys(cropData).map((a, key) => {
          return (
            <option key={key + 100} value={a}>{a}</option>
          )
        })}
      </datalist>
      <datalist id='stages'>
        {Object.keys(cropData).includes(data.croptype) && cropData[data.croptype].stages &&
          Object.keys(cropData[data.croptype].stages).map((a, key) => {
            return (
                <option key={key + 400} value={a}>{a}</option>
            )
          })}
      </datalist>
      <datalist id='fields'>
        {fieldsToDisplay?.map((a, key) => {
          return (
            <option key={key + 400} value={a.properties.name}>{a.properties.name}</option>
          )
        })}
      </datalist>
      <div className='calcmodal-body data' key={updateKey} style={slave && { width: '100%', margin: 0 }}>
        {
slave
  ? <a style={{ width: 'fit-content', float: 'right', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' onClick={() => hideFunc(false)}>
    <FontAwesomeIcon icon={FA.faTimes} />
  </a>
  : <><a style={{ width: 'fit-content', float: 'left', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' onClick={themeChangeFunc}>
    <FontAwesomeIcon icon={FA.faPalette} />
  </a>
    <a style={{ width: 'fit-content', float: 'right', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' href='/profile'>
      <FontAwesomeIcon icon={FA.faHome} />
    </a>
    <a style={{ width: 'fit-content', float: 'right', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' onClick={() => i18n.changeLanguage(i18n.language === 'ru' ? 'kz' : i18n.language === 'kz' ? 'en' : 'ru')}>
      <h5 style={{ margin: 'auto' }}>{i18n.language.toUpperCase()}</h5>
    </a>
  </>
}
        <h3 align='center'>{`Данные для ${Cookies.get('place')}`} {(slave || fieldID !== 'all') && selField ? `(поле ${selField.properties.name})` : ''}</h3>
        <br />
        {
    fieldsArray.length === 0
      ? 'У вашего хозяйства пока нет заметок!'
      : fieldsArray.map((f, key) => {
        // console.log(JSON.parse(selField.json).properties.name, f.field_name)
        if (((fieldId_master || fieldID !== 'all') && data.field_name === f.field_name) || fieldID === 'all') {
          return (
            <div key={key}>
              <div
                className='note-div'
                onClick={() => {
                  f.display = !f.display
                  const cl = document.getElementById(`note-div-${f.id}`).className
                  if (cl === 'hidden') {
                    document.getElementById(`note-div-${f.id}`).className = 'note-div-child'
                  } else {
                    document.getElementById(`note-div-${f.id}`).className = 'hidden'
                  }
                }}
              >
                <p style={{ width: '90%' }}>{f.season}: Поле {f.field_name} - {f.croptype}, {f.type} (Автор: {f.username}, обновлено:  {f.updatedAt.replace('T', ' ').slice(0, -8)})</p>
                <Button
                  style={{ width: 'fit-content', fontSize: '10px', padding: '2px', float: 'right', margin: 0 }} id='delete' onClick={() => {
                    deleteField(f.id)
                  }}
                ><FontAwesomeIcon icon={FA.faTrashAlt} />
                </Button>
              </div>
              <div id={`note-div-${f.id}`} className='hidden'>
              <p>Дата начала: {f.date_b}</p>
              <p>Дата конца: {f.date_e}</p>
              <p>Средняя температура: {f.averagetemp} °C</p>
              <p>Влажность поверхностного грунта: {f.humtop} %</p>
              <p>Влажность в корневой зоне: {f.humbottom} %</p>
              <p>Сумма активных температур: {f.sum} °C</p>
              {/* <br />
              <button style={{ background: 'var(--theme-text)', color: 'var(--theme-contrast)' }} onClick={() => {
                setUpdate(true)
                setData(() => ({ season: f.season, date_b: f.date_b, date_e: f.date_e, croptype: f.croptype, class: f.class, sort: f.sort, area: f.area, yield: f.yield, processing: f.processing, misc: f.misc, field_name: f.field_name }))
                document.getElementById('season').value = f.season
                document.getElementById('date_b').value = f.date_b
                document.getElementById('date_e').value = f.date_e
                document.getElementById('croptype').value = f.croptype
                document.getElementById('class').value = f.class
                document.getElementById('sort').value = f.sort
                document.getElementById('area').value = (f.area / 10000).toFixed(3)
                document.getElementById('yield').value = f.yield
                document.getElementById('processing').value = f.processing
                document.getElementById('misc').value = f.misc
                document.getElementById('field_name').value = f.field_name
              }}>Редактировать</button> */}
              </div>
            </div>
          )
        }
      })
    }
      </div>
      <div id='datasave' className='calcmodal-body data' style={slave && { width: '100%', margin: 0 }}>
        <h3 align='center'>{isUpdating ? `Просмотр / обновление заметки для поля ${data.field_id}` : 'Создание новой заметки'}</h3>
        {/* <p>
          Используя внесенные данные, мы улучшаем программу на основе машинного
          обучения
        </p> */}
        <br />Номер(имя) поля <Input id='field_name' list='fields' type='text' onChange={e => setData({ ...data, field_id: e })} />
        Сезон (год) <Input id='season' type='number' onChange={e => { setData({ ...data, season: e }); isUpdating && setUpdate(false) }} />
        Культура <Input id='croptype' list='cultures' type='text' onChange={e => { setData({ ...data, croptype: e }); isUpdating && setUpdate(false) }} />
        Фаза роста <Input id='type' type='text' list='stages' onChange={e => setData({ ...data, type: e })} />
        <hr />
        Дата начала периода <Input id='date_b' type='date' placeholder='0' onChange={e => setData({ ...data, date_b: e })} />
        Дата конца периода <Input id='date_e' type='date' onChange={e => setData({ ...data, date_e: e })} />
        Средняя температура в период посева
        <InputGroup>
        <Input id='averagetemp' type='text' onChange={e => setData({ ...data, averagetemp: e })} />
      <InputGroup.Addon>°C</InputGroup.Addon>
    </InputGroup>
        Влажность поверхностного грунта
        <InputGroup>
        <Input id='humtop' type='text' onChange={e => setData({ ...data, humtop: e })} />
      <InputGroup.Addon>%</InputGroup.Addon>
    </InputGroup>
        Влажность в корневой зоне
        <InputGroup>
        <Input id='humbottom' type='text' onChange={e => setData({ ...data, humbottom: e })} />
      <InputGroup.Addon>%</InputGroup.Addon>
    </InputGroup>
        Сумма активных температур
        {data.date_b !== '' && data.date_e !== '' && data.averagetemp !== '' && <p>{moment(data.date_e).diff(data.date_b, 'days') + 1} дней Х {data.averagetemp} °C = {(moment(data.date_e).diff(data.date_b, 'days') + 1) * data.averagetemp} °C</p>}
        <br />
        <br />
        <button
          id='save' onClick={() => {
            submitField()
          }}
        >
          {isUpdating ? 'Обновить данные' : 'Сохранить данные'}
        </button>
        <br />
      </div>
    </div>
  )
}

export default DataSaveSpecial
