import { faAlignJustify } from '@fortawesome/free-solid-svg-icons'
import React from 'react'

function DrawTools ({ adClass }) {
  return (
    <div className={`dropdown-content ${adClass}`}>
      <menu className='dropdown-lister'>
        <h4>Инструменты рисования:</h4>
        <div className='mapboxgl-ctrl-group' style={{ margin: '10px 0' }}>
          <button className='mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_line' title='Вектор' />
          <button className='mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_polygon' title='Многоугольник' />
          <button className='mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_point' title='Маркер' />
          <button className='mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_trash' title='Удалить' />
        </div>
        <div id='customDrawColor'>
          <div className='draw-color-change' id='draw-#000' style={{ backgroundColor: 'rgb(0, 0, 0)' }} />
          <div className='draw-color-change' id='draw-#c12123' style={{ backgroundColor: 'rgb(193, 33, 35)' }} />
          <div className='draw-color-change' id='draw-#ee4498' style={{ backgroundColor: 'rgb(238, 68, 152)' }} />
          <div className='draw-color-change' id='draw-#00924d' style={{ backgroundColor: 'rgb(0, 146, 77)' }} />
          <div className='draw-color-change' id='draw-#00afde' style={{ backgroundColor: 'rgb(0, 175, 222)' }} />
          <div className='draw-color-change' id='draw-#ccbe00' style={{ backgroundColor: 'rgb(204, 190, 0)' }} />
        </div>
        <hr />
        <div style={{ margin: '10px 0' }}>
          <h4>Размер выделенной местности:</h4>
          <h6>Площадь:</h6>
          <div id='calculated-area' />
          <h6>Периметр: </h6>
          <div id='calculated-length' />
          <h4 style={{ display: 'inline-flex' }}>
            <input type='radio' name='unit' id='feet' value='feet' /> Футы &nbsp;
            <input type='radio' name='unit' id='meter' value='meter' /> Метры &nbsp;
            <input type='radio' name='unit' id='mile' value='mile' /> Мили &nbsp;
            <input type='radio' name='unit' id='kilometer' value='kilometer' defaultChecked /> Километры &nbsp;
            <input type='radio' name='unit' id='acre' value='acre' /> Акры &nbsp;
          </h4>
        </div>
        <hr />
        <div style={{ margin: '10px 0' }}>
          <h4>Добавление текста</h4>
          <div id='edit-text'>
            <button id='textTool' active='false'><i className='fa fa-fw fa-plus' />Добавить текст</button>
            <button id='editTextTool' active='true' onClick='activateTool(this)'><i className='fa fa-fw fa-edit' />Редактировать текст</button>
          </div>
        </div>
      </menu>
    </div>
  )
}
export default DrawTools
