/* eslint-disable prefer-regex-literals */
import React, { useState, useEffect } from 'react'
import { Modal, Form, Button, FlexboxGrid, Input, Whisper, Tooltip, Alert, Icon, InputGroup, Checkbox, IconButton } from 'rsuite'
import { login } from '../http/API'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ResetPasswordModal from './ResetPasswordModal'
import { useTranslation } from 'react-i18next'
import {
  BrowserRouter,
  Link
} from 'react-router-dom'
import { useCookies } from 'react-cookie'

const SignInModal = (props) => {
  const { t } = useTranslation()
  const [data, setData] = useState({
    email: '',
    password: ''
  })
  const [eye, setEye] = useState('eye')
  const [passwordType, setPasswordType] = useState('password')

  // const [ errors, setErrors ] = useState([])
  const [loading, setLoading] = useState(false)
  const [passwordModal, setPasswordModalShow] = useState(false)
  let response
  const onSubmit = async () => {
    const tempErrors = validate(data)
    // setErrors(temp_errors);
    if (Object.keys(tempErrors).length === 0) {
      setLoading(true)
      try {
        const resp = await login(data.email, data.password).then((res) => {
          setLoading(false)
          response = res.response
          if (response.success) {
            Alert.success(response.message)
            setTimeout(2000)
            props.onHide()
            window.location.href = window.location
          } else {
            Alert.error(response.message)
          }
        })
      } catch (e) {
        setLoading(false)
        Alert.error(t('error.noconnection'))
        console.log(e)
      }
    } else {
      Alert.error(t('error.checkdata'))
    }
  }

  const validate = (data) => {
    const errors = {}
    if (!data.email) {
      errors.email = t('signin.emptyemail')
    }
    if (!data.password) {
      errors.password = t('signin.emptypassword')
    }
    return errors
  }

  const togglePassword = () => {
    if (eye === 'eye') {
      setEye('eye-slash')
      setPasswordType('text')
    } else {
      setEye('eye')
      setPasswordType('password')
    }
  }
  const handlePasswordReset = () => {
    setPasswordModalShow(true)
    props.onHide()
  }
  return (
    <div className='show-grid'>
      <Modal className='welcome-modals' {...props} show={props.show} backdrop={false} keyboard={false}>
        <Modal.Header closeButton={false}>
          <Modal.Title className='text-center'>
            {t('login.title')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <FlexboxGrid>
              <FlexboxGrid.Item colspan={24} className='p-15'>
                <Input
                  placeholder={t('common.email')} id={data.email} onChange={e => setData({
                    ...data,
                    email: e
                  })} size='md'
                />
              </FlexboxGrid.Item>
              <FlexboxGrid.Item colspan={24} className='p-15'>
                <InputGroup inside>
                  <Input
                    placeholder={t('common.password')} size='md'
                    id={data.password}
                    type={passwordType}
                    onChange={e => setData({
                      ...data,
                      password: e
                    })}
                  />
                  <InputGroup.Addon>
                    <Icon icon={eye} onClick={togglePassword} />
                  </InputGroup.Addon>
                </InputGroup>
                <div className='text-center text-underline'>
                  <br />
                  <p className='d-sign-clickable' onClick={handlePasswordReset}>{t('login.forgot')}</p>
                </div>
              </FlexboxGrid.Item>
              {/* <FlexboxGrid.Item colspan={24} className='p-15'>
                <Checkbox onChange={() => setRememberMe(!rememberMe)} checked={rememberMe} className='form-field-checkbox'>{t('Auth.Signin.remember_me')} </Checkbox>
              </FlexboxGrid.Item> */}
            </FlexboxGrid>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <BrowserRouter forceRefresh>
            <Link to='/profile'>
              <IconButton appearance='ghost' size='sm' className='m-r-15 m-b-15' icon={<Icon icon='angle-left' />} />
            </Link>
          </BrowserRouter>
          <Button
            appearance='primary' size='sm' onClick={onSubmit} loading={loading} className='m-r-15 m-b-15 inc'
          >
            {t('login.button')}
          </Button>
        </Modal.Footer>
      </Modal>
      <ResetPasswordModal show={passwordModal} onHide={() => setPasswordModalShow(false)} />
    </div>
  )
}

export default SignInModal
