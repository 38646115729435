import React, { useState } from 'react'
import { Modal, Button, IconButton, Icon } from 'rsuite'
import SignInModal from '../auth/SignIn'
import SignUpModal from '../auth/SignUp'
import { useTranslation } from 'react-i18next'
import {
  BrowserRouter,
  Link
} from 'react-router-dom'
import Cookies from 'js-cookie'

const WelcomeModal = (props) => {
  const [curTheme, setTheme] = React.useState(Cookies.get('theme') === 'black' ? 'black' : 'white')
  if (curTheme === 'white') {
    document.documentElement.style.setProperty('--theme-main', 'darkblue')
    document.documentElement.style.setProperty('--theme-text', 'white')
    document.documentElement.style.setProperty('--theme-secondary', 'lightgray')
    document.documentElement.style.setProperty('--theme-contrast', 'black')
  } else if (curTheme === 'black') {
    document.documentElement.style.setProperty('--theme-main', 'lime')
    document.documentElement.style.setProperty('--theme-text', 'black')
    document.documentElement.style.setProperty('--theme-secondary', 'darkslategray')
    document.documentElement.style.setProperty('--theme-contrast', 'white')
  }
  const themeChangeFunc = () => {
    if (curTheme === 'black') {
      Cookies.set('theme', 'white')
      setTheme('white')
      document.documentElement.style.setProperty('--theme-main', 'darkblue')
      document.documentElement.style.setProperty('--theme-text', 'white')
      document.documentElement.style.setProperty('--theme-secondary', 'lightgray')
      document.documentElement.style.setProperty('--theme-contrast', 'black')
    } else {
      Cookies.set('theme', 'black')
      setTheme('black')
      document.documentElement.style.setProperty('--theme-main', 'lime')
      document.documentElement.style.setProperty('--theme-text', 'black')
      document.documentElement.style.setProperty('--theme-secondary', 'darkslategray')
      document.documentElement.style.setProperty('--theme-contrast', 'white')
    }
  }
  const { t } = useTranslation()
  const [signUpShow, setSignUpShow] = useState(false)
  const [signInShow, setSignInShow] = useState(false)

  const onSignUpClicked = () => {
    setSignUpShow(true)
    props.onHide()
  }
  const onSignInClicked = () => {
    setSignInShow(true)
    props.onHide()
  }
  const handleDemoClick = () => {
    sessionStorage.setItem('demo', true)
    props.onHide()
  }
  return (
    <div className='show-grid'>

      <Modal className='welcome-modals' {...props} show={props.show} backdrop={false} keyboard={false}>
        <Modal.Header closeButton={false}>
          <Modal.Title className='text-center'>
            {t('welcome.title')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            {t('welcome.subtitle')}
          </span>
        </Modal.Body>
        <Modal.Footer>
          <BrowserRouter forceRefresh>
            <Link to='/'>
              <IconButton appearance='ghost' size='sm' className='m-r-15' icon={<Icon icon='angle-left' />} />
            </Link>
          </BrowserRouter>
          <Button appearance='primary' size='sm' className='inc' onClick={onSignUpClicked}>{t('welcome.signup')}</Button>
          <Button appearance='primary' color='orange' size='sm' className='inc' onClick={onSignInClicked}>{t('welcome.signin')}</Button>
          {/* <Button appearance='primary' color='green' size='sm' onClick={handleDemoClick}>{t('Auth.Signin.demo')}</Button> */}
        </Modal.Footer>
      </Modal>
      <SignUpModal show={signUpShow} onHide={() => setSignUpShow(false)} />
      <SignInModal show={signInShow} onHide={() => setSignInShow(false)} />
    </div>

  )
}

export default WelcomeModal
