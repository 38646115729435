import { useTranslation } from 'react-i18next'
// const { t } = useTranslation()

// baseuRL
export const HOST_URL = 'test'
export const API_PATH = '/API/v1'
// Aquistion demands
export const SCENE_SETTINGS = {
  LEVELS: [
    {
      label: 'L1',
      value: 'L1'
    },
    {
      label: 'L2',
      value: 'L2'
    },
    {
      label: 'L3',
      value: 'L3'
    },
    {
      label: 'L4',
      value: 'L4'
    },
    {
      label: 'L5',
      value: 'L5'
    }
  ],
  PRIORITIES: [
    {
      // label: 'standard'  || t('Common.Priority.standard'),
      label: 'standard',
      value: 'standard'
    },
    {
      label: 'high',
      value: 'high'
    },
    {
      label: 'urgent',
      value: 'urgent'
    }
  ],
  PURPOSE: [
    {
      label: 'Change detection (default)',
      value: 'Change detection'
    },
    {
      label: 'Agriculture',
      value: 'Agriculture'
    },
    {
      label: 'Cartography',
      value: 'Cartography'
    },
    {
      label: 'Other',
      value: 'Other'
    }
  ],
  MODES: [
    {
      label: 'mono',
      value: 'mono'
    },
    {
      label: 'stereo',
      value: 'stereo'
    }
  ],
  SATELLITES: [
    {
      label: 'high (KazEOSat-1)',
      value: 'KazEOSat-1'
    },
    {
      label: 'middle KazEOSat-2',
      value: 'KazEOSat-2'
    }
  ],
  RESOLUTION: [
    {
      label: 'high (KazEOSat-1)',
      value: 'HIGH'
    },
    {
      label: 'middle KazEOSat-2',
      value: 'MEDIUM'
    }
  ]
}
