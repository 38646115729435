import Cookies from 'js-cookie'
import jwt_decode from 'jwt-decode'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'rsuite'

const Extra = () => {
  const { t, i18n } = useTranslation()
  const token = Cookies.get('token')
  const userRole = token ? jwt_decode(token).role : undefined
  const decyph = token && jwt_decode(token)
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight
  })
  const [ready, setReady] = useState(false)
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight
    })
  }

  useEffect(() => {
    window.addEventListener('resize', setDimension)

    return () => {
      window.removeEventListener('resize', setDimension)
    }
  }, [screenSize])
  return (
    <div style={{ overflow: 'hidden' }}>
      <iframe title='C3IS' src='https://ee-ukgnail.projects.earthengine.app/view/collaborative-expert-knowledge-extraction-extra' width={screenSize.dynamicWidth} height={screenSize.dynamicHeight} style={{ overflow: 'hidden' }} onLoad={() => setReady(true)}/>
      {ready && <div className='inject-div'>
      <a href = 'https://hungermap.wfp.org/' target='__blank' className='inject-button'><Button>Socio-economic data</Button></a>
      <a href = 'https://talkai.info/chat/' target='__blank' className='inject-button'><Button>LLM</Button></a>
      <a href = 'https://forms.gle/JwyQ9ZnfjqZ7zwTcA' target='__blank' className='inject-button'><Button>Knowledge Engineering</Button></a>
      </div>
}
    </div>
  )
}

export default Extra
