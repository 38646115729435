import '../styles/title.css'
import { useEffect, useState } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'

function Title () {
  useEffect(() => {
    AOS.init()
    AOS.refresh()
  }, [])
  const { t, i18n } = useTranslation()
  const reveal = () => {
    const mobileMenuBg = document.getElementById('mobile-menu__bg') // mobile menu fullscreen background
    const scrollLine = document.querySelector('.scroll-line') // two vertical lines
    const menuMobile = document.querySelector('.header__hav') // mobile menu
    if (mobileMenuBg.style.display === 'block') {
      mobileMenuBg.style.display = 'none'
      scrollLine.style.display = 'block'
      menuMobile.style.display = 'none'
      document.body.style.overflowY = 'auto' // disable scrolling in menu
    } else {
      mobileMenuBg.style.display = 'block'
      scrollLine.style.display = 'none'
      menuMobile.style.display = 'flex'
      document.body.style.overflowY = 'hidden'
    }
  }
  const [curTheme, setTheme] = useState(Cookies.get('theme') === 'black' ? 'black' : 'white')
  if (curTheme === 'white') {
    document.documentElement.style.setProperty('--theme-main', 'darkblue')
    document.documentElement.style.setProperty('--theme-text', 'white')
    document.documentElement.style.setProperty('--theme-secondary', 'lightgray')
    document.documentElement.style.setProperty('--theme-contrast', 'black')
  } else if (curTheme === 'black') {
    document.documentElement.style.setProperty('--theme-main', 'lime')
    document.documentElement.style.setProperty('--theme-text', 'black')
    document.documentElement.style.setProperty('--theme-secondary', 'darkslategray')
    document.documentElement.style.setProperty('--theme-contrast', 'white')
  }
  const themeChangeFunc = () => {
    if (curTheme === 'black') {
      Cookies.set('theme', 'white')
      setTheme('white')
      document.documentElement.style.setProperty('--theme-main', 'darkblue')
      document.documentElement.style.setProperty('--theme-text', 'white')
      document.documentElement.style.setProperty('--theme-secondary', 'lightgray')
      document.documentElement.style.setProperty('--theme-contrast', 'black')
    } else {
      Cookies.set('theme', 'black')
      setTheme('black')
      document.documentElement.style.setProperty('--theme-main', 'lime')
      document.documentElement.style.setProperty('--theme-text', 'black')
      document.documentElement.style.setProperty('--theme-secondary', 'darkslategray')
      document.documentElement.style.setProperty('--theme-contrast', 'white')
    }
  }
  return (
    <div className='original-wrapper-title'>
      <header className='wrapper-full'>
        <div className='header'>
          {/* Mobile Menu */}
          <div id='toggle'>
            <input type='checkbox' onChange={reveal} />
            <span />
          </div>
          <div id='mobile-menu__bg' />
          {/* /Mobile Menu */}
          <a href=''>
            <img src='img/site_logo.png' alt='' width='150px' height='150px'/>
          </a>
          <div className='header__hav'>
            <nav className='header__hav--items'>
              <ul>
                <li>
                  <a className='--link' href=''>
                    {t('title.list.main')}
                  </a>
                </li>
                <li>
                  <a className='--link' href='/about_us'>
                    {t('title.list.aboutus')}
                  </a>
                </li>
                <li>
                  <a className='--link' href='/profile'>{t('title.list.profile')}</a>
                </li>
                <li>
                  <a className='--link' style={{ cursor: 'pointer' }} onClick={() => i18n.changeLanguage(i18n.language === 'ru' ? 'kz' : i18n.language === 'kz' ? 'en' : 'ru')}><font>{t('map.sidebar.language').toUpperCase()}: {i18n.language.toUpperCase()}</font></a>
                </li>
                {/* <li>
                  <a className='--link' href='/about_us'>
                    ОТБОР ПРОБ
                  </a>
                </li> */}
                {/* <li>
                          <a class='--link' href='javascript:void(0)'>АВТОРИЗАЦИЯ</a>
                          <ul class='submenu'>
                              <li>
                                  <a href='multimenu.html'>ВОЙТИ</a>
                              </li>

                          </ul>
                      </li> */}
              </ul>
            </nav>
            <a
              href='https://forms.gle/ZVNpMFMhtxgMbpFK9'
              target='_blank'
              rel='noreferrer'
              className='color-button'
            >
              {t('title.list.demo')}
            </a>
          </div>
        </div>
      </header>
      <div className='full-width-slider'>
        <ul className='slides'>
          <li className='selected'>
            <div>
              <h2 className='h2-title'>{t('common.tablet').toUpperCase()} {t('common.agro').toUpperCase()}</h2>
              <p className='selected__lead'>
                {t('title.subtitle')}
              </p>
            </div>
          </li>
          <li>
            <div>
              <p id='' className='__name '>
                ПЛАНШЕТ АГРОНОМА
              </p>
              <h2 className='h2-title'>
                ЛУЧШИЙ <br />
                <span>СЕРВИС</span>
              </h2>
              <p className='selected__lead'>
                Работайте продуктивно, выращивайте урожай с меньшими затратами и
                предотвращайте возможные потери — начните бесплатно прямо сейчас.
              </p>
            </div>
            <div>
              <a id='play' className='pulse' href='video\video2.mp4'>
                <span />
              </a>
            </div>
          </li>
          <li>
            <div>
              <p className='__name '>ПЛАНШЕТ АГРОНОМА</p>
              <h2 className='h2-title'>
                Ektu<span>Veritas</span>
              </h2>
            </div>
          </li>
        </ul>
        {/* slides */}
        {/* <ol className='slider-dots-navigation'>
          <li>
            <a href='#0' className='selected'>
              1
            </a>
          </li>
          <li>
            <a href='#0'>2</a>
          </li>
          <li>
            <a href='#0'>3</a>
          </li>
        </ol> */}
      </div>
      {/* full-width-slider */}
      <main>
        <section>
          <div className='scroll-line' />
          <a id='up-button' />
          <div className='wrapper'>
            <div className='special'>
              <div data-aos='fade' className='special--text'>
                <h2 className='__head h2-title'>Цифровой помощник агронома</h2>
                <p>
                  Помогать фермерам процветать - главное в нашей миссии. Именно
                  поэтому мы предоставляем обширную базу подробных статей поддержки,
                  которые помогут вам ответить на вопросы, которые у вас есть о
                  вашей учетной записи.{' '}
                </p>
              </div>
              <div className='special__icon'>
                <div
                  data-aos='fade'
                  data-aos-delay={200}
                  data-aos-offset={100}
                  className='special__icon--unit'
                >
                  <img src='img/icons/2.png' alt='img' />
                  <h5>Лучший сервис</h5>
                </div>
                <div
                  data-aos='fade'
                  data-aos-delay={300}
                  data-aos-offset={100}
                  className='special__icon--unit'
                >
                  <img src='img/icons/12.png' width={60} height={60} alt='img' />
                  <h5>Опыт</h5>
                </div>
                <div
                  data-aos='fade'
                  data-aos-delay={400}
                  data-aos-offset={100}
                  className='special__icon--unit'
                >
                  <img
                    src='img/icons/soil_PNG39.png'
                    width={70}
                    height={70}
                    alt='img'
                  />
                  <h5>Анализ посева и почвы</h5>
                </div>
                <div
                  data-aos='fade'
                  data-aos-delay={500}
                  data-aos-offset={100}
                  className='special__icon--unit'
                >
                  <img
                    src='img/icons/pngwing.com.png'
                    width={100}
                    height={50}
                    alt='img'
                  />
                  <h5>Мониторинг погоды</h5>
                </div>
                <div
                  data-aos='fade'
                  data-aos-delay={600}
                  data-aos-offset={100}
                  className='special__icon--unit'
                >
                  <img src='img/icons/6.png' alt='img' />
                  <h5>Мониторинг техники</h5>
                </div>
              </div>
              <div data-aos='zoom-in' className='special__animtext'>
                <p>МЫ ХОТИМ РАССКАЗАТЬ ВАМ О НАС</p>
              </div>
              <div className='special__about'>
                <div
                  data-aos='fade-left'
                  data-aos-delay={400}
                  data-aos-duration={500}
                  data-aos-offset={100}
                  className='special__about--button-box'
                >
                  <h2 className='__head h2-title'>ЛУЧШИЙ ПОМОЩНИК ФЕРМЕРА</h2>
                  <a href='/about_us' className='transparent-button'>
                    Больше
                  </a>
                </div>
                <div
                  data-aos='fade-up'
                  ddata-aos-duration={600}
                  data-aos-offset={100}
                  className='special__about--img-box'
                >
                  <img src='img/img_1.png' alt='img' />
                </div>
                <div
                  data-aos='fade-right'
                  data-aos-delay={400}
                  data-aos-duration={500}
                  data-aos-offset={100}
                  className='special__about--text-box'
                >
                  <p>
                    Мы сосредоточены на создании инновационных программных решений,
                    чтобы вы могли сосредоточиться на управлении прибыльной фермой.
                  </p>
                  <p>
                    Планшет фермера - платформа, позволяющая участникам эффективно
                    управлять агробизнесом, своевременно принимать решения и снижать
                    риски, существенно увеличивать прозрачность взаимодействия как
                    для конкретного клиента, так и между участниками платформы
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className='map'>
            <div id='mapFooter'><img src='img/map_google.png' /></div>
          </div>
        </section>
      </main>
      <footer>
        <div className='wrapper'>
          <div className='footer'>
            <div className='footer--top'>
              <div className='footer--top__links'>
                <div className='logo'>
                  <a href=''>
                  <img src='img/site_logo.png' alt='' width='150px' height='150px'/>
                  </a>
                </div>
                <div className='text'>
                  <p>
                    {t('title.place')}
                  </p>
                  <p>{t('title.address')}</p>
                  <p>8 (7232) 26-74-09</p>
                  <p>
                    <a href='mailto:ekstu_tz@mail.ru'>ekstu_tz@mail.ru</a>
                  </p>
                </div>
              </div>
              <div className='footer--bottom'>
                <div className='footer--bottom__copyright'>
                  <span>
                    <p>
                      © {t('title.place')} <br /> {t('title.rights')}{' '}
                      <br />
                      {t('common.kazakhstan')}, 070004, {t('title.address')} Тел./факс 8 (7232) 26-74-09
                    </p>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Title
