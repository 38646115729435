import React, { createContext, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert } from 'rsuite'
import { SearchOptionsContext } from './SearchDetailsContext'
import { NavigationContext } from '../contexts/NavigationContext'

export const SatImgContext = createContext()

const SatImgContextProvider = (props) => {
  const { setSearchOptions } = useContext(SearchOptionsContext)
  const { t } = useTranslation()
  const { navItemId } = useContext(NavigationContext)
  const [dataTiles, setDataTiles] = useState([])
  const [chosenTiles, setChosenTiles] = useState([])
  const [activeTile, setActiveTile] = useState({
    data: {},
    active: false
  })

  const updateSatelliteData = (data) => {
    setDataTiles(data)
  }

  const downloadBlob = (url, name) => {
    // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
    // const blobUrl = URL.createObjectURL(blob)

    // Create a link element
    const link = document.createElement('a')

    // Set link's href to point to the Blob URL
    // link.href = blobUrl
    link.href = url
    link.download = name

    // Append link to the body
    document.body.appendChild(link)

    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    )
    window.location.href = url
    // Remove link from body
    document.body.removeChild(link)
  }

  return (
    <SatImgContext.Provider value={{ dataTiles, setDataTiles, updateSatelliteData, activeTile, setActiveTile, chosenTiles, setChosenTiles }}>
      {props.children}
    </SatImgContext.Provider>
  )
}

export default SatImgContextProvider
