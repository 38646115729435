import React from 'react'
import { CanvasJSChart } from 'canvasjs-react-charts'
import Cookies from 'js-cookie'
import moment from 'moment'

const WeatherChartHumidity = ({ fields }) => {
  // const colors = ['#B1A2CA', '#D291BC', '#FF6D6A', '#E9EC6B', '#EFBE7D']
  const options = {
    animationEnabled: true,
    exportEnabled: true,
    zoomEnabled: true,
    theme: Cookies.get('theme') === 'black' ? 'dark1' : 'light1',
    title: {
      text: 'Динамика влажности'
    },
    axisY: {
      includeZero: true,
      suffix: '%'
    },
    toolTip: {
      shared: true
    },
    data: [{
      type: 'area',
      name: 'Влажность',
      showInLegend: true,
      indexLabelFontColor: '#5A5757',
      indexLabelPlacement: 'outside',
      yValueFormatString: '##.##',
      dataPoints: fields && fields.map((e) => {
        var date = e.date.split('.')
        var mom = moment(`${date[2]}-${date[1]}-${date[0]}`).toDate()
        return (
          { y: e.humidity || 0, x: mom }
        )
      })
    }]
  }

  return (
    <div>
      <CanvasJSChart options={options} />

    </div>
  )
}
export default WeatherChartHumidity
