import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as FA from '@fortawesome/free-solid-svg-icons'
import { getFields, addField, removeField, getGeoJSON } from '../http/API'
import { Input, Alert, Button, ControlLabel } from 'rsuite'
import Cookies from 'js-cookie'
import { useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import WeatherChartTemp from './charts/WeatherChartTemp'
import WeatherChartHumidity from './charts/WeatherChartHumidity'
import WeatherChartWind from './charts/WeatherChartWind'
import WeatherChartPercip from './charts/WeatherChartPercip'
import moment from 'moment'

function WeatherAnalysis ({ slave, fieldId_master, adClass, hideFunc, weatherData, field_names }) {
  const { t, i18n } = useTranslation()
  function sortByMoment (property) {
    return function (a, b) {
      const datea = a.date.split('.')
      const dateb = b.date.split('.')
      const moma = moment(`${datea[2]}-${datea[1]}-${datea[0]}`).toDate()
      const momb = moment(`${dateb[2]}-${dateb[1]}-${dateb[0]}`).toDate()
      if (moma > momb) {
        return 1
      } else if (moma < momb) {
        return -1
      }
      return 0
    }
  }
  const [selField, selectField] = useState()
  const [chartID, setChartID] = useState()
  const [chartYear, setChartYear] = useState()
  const [fieldsToSend, setFieldsToSend] = useState()

  const [fieldsArray, setFields] = useState([])
  const [updateKey, update] = useState(0)
  // useEffect(async () => {
  //   const fieldsList = await get()
  //   if (fieldsList.response.success) {
  //     setFields(fieldsList.response.fields.sort(sortByProperty('season')))
  //   }
  // }, [updateKey])
  useEffect(() => {
    // setFields(weatherData)
    // setChartID(fieldId_master)
    // console.log(weatherData.filter((e) => e.field_name === fieldId_master))
    console.log(weatherData.sort())
    setFieldsToSend(weatherData?.sort(sortByMoment('id')))
    // setFieldsToSend(weatherData)
  }, [weatherData])
  const [curTheme, setTheme] = useState(Cookies.get('theme') === 'black' ? 'black' : 'white')
  if (!slave) {
    if (curTheme === 'white') {
      document.documentElement.style.setProperty('--theme-main', 'darkblue')
      document.documentElement.style.setProperty('--theme-text', 'white')
      document.documentElement.style.setProperty('--theme-secondary', 'lightgray')
      document.documentElement.style.setProperty('--theme-contrast', 'black')
    } else if (curTheme === 'black') {
      document.documentElement.style.setProperty('--theme-main', 'lime')
      document.documentElement.style.setProperty('--theme-text', 'black')
      document.documentElement.style.setProperty('--theme-secondary', 'darkslategray')
      document.documentElement.style.setProperty('--theme-contrast', 'white')
    }
  }
  const themeChangeFunc = () => {
    if (curTheme === 'black') {
      Cookies.set('theme', 'white')
      setTheme('white')
      document.documentElement.style.setProperty('--theme-main', 'darkblue')
      document.documentElement.style.setProperty('--theme-text', 'white')
      document.documentElement.style.setProperty('--theme-secondary', 'lightgray')
      document.documentElement.style.setProperty('--theme-contrast', 'black')
    } else {
      Cookies.set('theme', 'black')
      setTheme('black')
      document.documentElement.style.setProperty('--theme-main', 'lime')
      document.documentElement.style.setProperty('--theme-text', 'black')
      document.documentElement.style.setProperty('--theme-secondary', 'darkslategray')
      document.documentElement.style.setProperty('--theme-contrast', 'white')
    }
  }
  return (
    <div className={slave && `dropdown-content ${adClass}`} style={slave && { width: '400px', overflowY: 'auto', maxHeight: '80vh' }}>
{ slave && <a style={{ width: 'fit-content', float: 'right', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' onClick={() => hideFunc(false)}>
    <FontAwesomeIcon icon={FA.faTimes} />
  </a>}
      <div className='calcmodal-body data' style={slave && { display: 'none' }} key={updateKey}>
        <a style={{ width: 'fit-content', float: 'left', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' onClick={themeChangeFunc}>
          <FontAwesomeIcon icon={FA.faPalette} />
        </a>
        <a style={{ width: 'fit-content', float: 'right', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' href='/profile'>
          <FontAwesomeIcon icon={FA.faHome} />
        </a>
        <a style={{ width: 'fit-content', float: 'right', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' onClick={() => i18n.changeLanguage(i18n.language === 'ru' ? 'kz' : i18n.language === 'kz' ? 'en' : 'ru')}>
          <h5 style={{ margin: 'auto' }}>{i18n.language.toUpperCase()}</h5>
        </a>
        <h3 align='center'>Мониторинг погоды</h3>
        <br />
        {/* <h5 align='center'>Выберите поле</h5> */}
        {/* <div className='note-div-parent'>
        {field_names?.sort(sortByProperty('name'))?.map((f, key) => {
          return (
              <div className='note-div' style={{ width: 'min-content', whiteSpace: 'nowrap' }} key={key + 450} onClick={() => {
                // setChartID(f.id)
                setFieldsToSend(weatherData)
              }}
            >{f.properties.name}</div>
          )
        })}
      </div> */}
        {/* <div className='note-div-parent'>
          {
    fieldsArray.length === 0
      ? 'У вашего хозяйства пока нет заметок!'
      : fieldsArray.map((f, key) => {
        // console.log(JSON.parse(selField.json).properties.name, f.field_name)
        if (curYear !== f.season) {
          if (curYear !== 0) {
            f.div = true
          }
          curYear = f.season
        }
        return (
          <>
            <div
              className='note-div' style={{ width: 'min-content', whiteSpace: 'nowrap' }} key={key + 150} onClick={() => {
                setChartID(f.field_name)
                setChartYear(f.season)
                setFieldsToSend(fieldsArray.filter((e) => e.field_name === f.field_name))
              }}
            >
              <p style={{ width: '20%' }}>{f.season}: {f.field_name}</p>
            </div>
          </>
        )
      })
    }
        </div> */}
      </div>
      {fieldsToSend &&
      <>
          <div className='calcmodal-body data' style={slave && { width: '100%', margin: 0 }}>
            <WeatherChartTemp fields={fieldsToSend} update={updateKey} />
          </div>
          <div className='calcmodal-body data' style={slave && { width: '100%', margin: 0 }}>
            <WeatherChartHumidity fields={fieldsToSend} update={updateKey} />
          </div>
          <div className='calcmodal-body data' style={slave && { width: '100%', margin: 0 }}>
            <WeatherChartWind fields={fieldsToSend} update={updateKey} />
          </div>
          <div className='calcmodal-body data' style={slave && { width: '100%', margin: 0 }}>
            <WeatherChartPercip fields={fieldsToSend} update={updateKey} />
          </div>
      </>
      }
    </div>
  )
}

export default WeatherAnalysis
