import React from 'react'
import Cookies from 'js-cookie'

const RecList = () => {
  const [curTheme, setTheme] = React.useState(Cookies.get('theme') === 'black' ? 'black' : 'white')
  if (curTheme === 'white') {
    document.documentElement.style.setProperty('--theme-main', 'darkblue')
    document.documentElement.style.setProperty('--theme-text', 'white')
    document.documentElement.style.setProperty('--theme-secondary', 'lightgray')
    document.documentElement.style.setProperty('--theme-contrast', 'black')
  } else if (curTheme === 'black') {
    document.documentElement.style.setProperty('--theme-main', 'lime')
    document.documentElement.style.setProperty('--theme-text', 'black')
    document.documentElement.style.setProperty('--theme-secondary', 'darkslategray')
    document.documentElement.style.setProperty('--theme-contrast', 'white')
  }
  const themeChangeFunc = () => {
    if (curTheme === 'black') {
      Cookies.set('theme', 'white')
      setTheme('white')
      document.documentElement.style.setProperty('--theme-main', 'darkblue')
      document.documentElement.style.setProperty('--theme-text', 'white')
      document.documentElement.style.setProperty('--theme-secondary', 'lightgray')
      document.documentElement.style.setProperty('--theme-contrast', 'black')
    } else {
      Cookies.set('theme', 'black')
      setTheme('black')
      document.documentElement.style.setProperty('--theme-main', 'lime')
      document.documentElement.style.setProperty('--theme-text', 'black')
      document.documentElement.style.setProperty('--theme-secondary', 'darkslategray')
      document.documentElement.style.setProperty('--theme-contrast', 'white')
    }
  }
  return (
    <div className='original-wrapper'>
      <header className='wrapper-full' style={{ width: 'min-content' }}>
        <div className='header'>
          <div id='toggle'>
            <input type='checkbox' />
          </div>
          <div id='mobile-menu__bg' />
          <a href='/' >
            <img src='img/site_logo.png' alt='' />
          </a>
        </div>
      </header>
      <section>
        <div className='gallery'>
          <div className='gallery__unit large'>
            <div className='gallery__unit--text'>
              <div>
                <h2 className='__head'>
                  Рекомендации
                </h2>
                <br />
                <br />
                <br />
                <br />
                <a className='transparent-button' href='/profile'>
                  НАЗАД
                </a>
              </div>
            </div>
          </div>
          <div className='gallery__unit'>
            <div className='gallery__unit--img'>
              <div />
              <h3>Рекомендации по севообороту</h3>
              <img src='img/lechlerfon.png' alt='img' />
              <a href='/recommendation' />
            </div>
          </div>
          <div className='gallery__unit'>
            <div className='gallery__unit--img'>
              <div />
              <h3>Прогноз урожайности</h3>
              <img src='img/lechlerfon.png' alt='img' />
              <a href='/recspecial' />
            </div>
          </div>
          <div className='gallery__unit'>
            <div className='gallery__unit--img'>
              <div />
              <h3>Анализ урожайности</h3>
              <img src='img/lechlerfon.png' alt='img' />
              <a href='/chartsspecial' />
            </div>
          </div>
        </div>
      </section>
    </div>

  )
}

export default RecList
