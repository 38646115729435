import { useContext, useEffect, useState } from 'react'
import { Modal, Form, Button, Uploader, Alert } from 'rsuite'
import tj from '@mapbox/togeojson'
import JSZip from 'jszip'
import L from 'leaflet'
import shp from 'shpjs'
import * as turf from '@turf/turf'
import { NavigationContext } from '../contexts/NavigationContext'
import { SearchOptionsContext } from '../contexts/SearchDetailsContext'
import { SatImgContext } from '../contexts/DataProviderContext'
import { useTranslation } from 'react-i18next'
import { parse } from 'date-fns'
import { geometry } from '@turf/turf'

const UploadFilesModal = (props) => {
  const { t } = useTranslation()
  const [searchLayer, setSearchLayer] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const { jsonLayers, setJsonLayers, geoJsonParser } = useContext(SearchOptionsContext)
  const { searchOptions, setSearchOptions, newOrder } = useContext(SearchOptionsContext)
  const { setNavItemId } = useContext(NavigationContext)
  const [file, setFile] = useState()
  const [localclass, hide] = useState('')
  const { dataTiles, fetchDataTiles, activeTile, setActiveTile, chosenTiles, setChosenTiles } = useContext(SatImgContext)
  const kmlUpload = (e) => {
    if (e && e.length > 0) {
      setFile(() => e)
      const filename = e[0].name
      const lastDot = filename.lastIndexOf('.')
      const ext = filename.slice(lastDot + 1)
      const f = e[0].blobFile
      const r = new FileReader()
      const options = {
        stroke: true,
        color: 'white',
        weight: 3,
        opacity: 0.5,
        fill: true,
        fillColor: 'white',
        fillOpacity: 0.1,
        clickable: true
      }
      r.addEventListener('load', async (e) => {
        let convertedKml = ''
        if (ext === 'kml') {
          let KMLfile = ''
          if (e.target.result[0] !== '<') {
            KMLfile = e.target.result.slice(3)
          } else {
            KMLfile = e.target.result
          }
          const kml = new DOMParser().parseFromString(KMLfile, 'text/xml')
          convertedKml = tj.kml(kml)
        } else if (ext === 'kmz') {
          const newZip = new JSZip()
          const contents = await newZip.loadAsync(e.target.result)
          const content = await contents.file('doc.kml').async('string')
          convertedKml = tj.kml(
            new DOMParser().parseFromString(content, 'text/xml')
          )
        } else if (ext === 'geojson') {
          convertedKml = JSON.parse(e.target.result)
        } else {
          // this.toaster.current.configErrorResponse({
          //   message: 'FormatNotAllowed'
          // })
          console.log('FormatNotAllowed')
          return
        }

        if (convertedKml.type === 'Feature') {
          convertedKml = {
            type: 'FeatureCollection',
            features: [convertedKml]
          }
        }

        const layers = geoJsonParser(convertedKml)
        setSearchLayer(layers)
        if (!layers) return Alert.error(t('Errors.wrong'))
        if (layers) {
          let convertedKmlFilter = {}
          convertedKml.features.forEach(feat => {
            if (feat.geometry.type === 'Polygon') {
              convertedKmlFilter = feat
            }
          })
          const toDisplay = L.geoJSON(convertedKml)
          toDisplay.eachLayer((l) => (l.options.color = 'lime'))
          console.log(toDisplay)
          props.drawLayer.addLayer(toDisplay)
          setActiveTile(convertedKmlFilter, true)
          // const gjson = convertedKmlFilter
          const gjson = convertedKml.features
          const gjsonArray = []
          gjson.forEach((i, key) => {
            const l_geojson = L.geoJSON(i)
            const area = L.GeometryUtil.geodesicArea(l_geojson.getLayers()[0].getLatLngs()[0])
            i.properties = {
              name: `${t('common.noname')}_${key}`,
              area: area
            }
            gjsonArray.push(i)
          })
          props.saveJSON('m' + JSON.stringify(gjsonArray))
        }
        props.onHide()
      })
      r.readAsBinaryString(f)
      e = ''
    }
  }

  const shpUpload = (e) => {
    const shpFiles = e[0]
    const name = shpFiles.blobFile.name
    if (name.includes('.zip') === true) {
      const zipFile = shpFiles.blobFile
      parseZipShp(zipFile)
    } else {
      parseShp(shpFiles.blobFile)
    }
  }

  const parseZipShp = (zipFile) => {
    const fr = new FileReader()
    fr.readAsArrayBuffer(zipFile)
    fr.onload = zipReceiveBinary
    // check syntax
  }

  const zipReceiveBinary = () => {
    const fr = new FileReader()
    const result = fr.result
    const shpfile = shp(result)
  }

  const parseShp = (shpFiles) => {
    let dbfContent = {}
    let shpContent = {}
    if (shpFiles.name.includes('.dbf') === true) {
      loadLocalFile(shpFiles, function (content) {
        dbfContent = content
        if (shpContent && dbfContent) {
          loadShapefile(shpContent, dbfContent)
        }
      })
    } else if (shpFiles.name.includes('.shp') === true) {
      loadLocalFile(shpFiles, function (content) {
        shpContent = content
        if (shpContent && dbfContent) {
          loadShapefile(shpContent, dbfContent)
        }
      })
    }
  }

  const loadShapefile = (shp, dbf) => {
    let convertedShp = ''
    const shpArr = {
      type: 'FeatureCollection',
      features: []
    }
    const shapefile = require('shapefile')
    const geojson = shapefile.open(shp)
      .then(source => source.read()
        .then(function log (result) {
          if (result.done) return
          convertedShp = result.value
          if (convertedShp.type === 'Feature') {
            convertedShp = {
              type: 'FeatureCollection',
              features: [convertedShp]
            }
            console.log(convertedShp)
            shpArr.features.push({ ...convertedShp, type: 'Feature' })
            console.log(shpArr)
            getShpLayer(convertedShp, shpArr)
          }
          return source.read().then(log)
        }))
      .catch(error => console.error(error.stack))
  }

  const getShpLayer = (convertedShp, convertedArray) => {
    const layers = geoJsonParser(convertedShp)
    setSearchLayer(layers)
    if (!layers) return Alert.error(t('Errors.wrong'))
    if (layers) {
      setActiveTile(convertedShp.features[0], true)
      const gjson = convertedArray.features
      const gjsonArray = []
      gjson.forEach((i, key) => {
        const l_geojson = L.geoJSON(i)
        const area = L.GeometryUtil.geodesicArea(l_geojson.getLayers()[0].getLatLngs()[0])
        i.properties = {
          name: `${t('common.noname')}_${key}`,
          area: area
        }
        gjsonArray.push(i)
      })
      props.saveJSON('m' + JSON.stringify(gjsonArray))
    }
    props.onHide()
  }

  const loadLocalFile = (file, callback) => {
    const modelShape = {
      coordinates: null,
      content: null
    }
    let content = modelShape.content
    if (typeof FileReader === 'function') {
      const reader = new FileReader()
      reader.onload = function (e) {
        content = e.target.result
        callback.call(this, content)
      }

      reader.readAsArrayBuffer(file)
    } else {
      console.log('Error')
    }
  }
  useEffect(() => {
    if (!newOrder) {
      if (!(searchLayer && Object.keys(searchLayer).length === 0 && searchLayer.constructor === Object)) {
        // fetchData()
        setIsLoading(true)
      }
    }
  }, [searchOptions])

  useEffect(() => {
    setSearchOptions({
      satellite: searchOptions.satellite,
      date_from: searchOptions.date_from,
      date_to: searchOptions.date_to,
      geometry: searchLayer?.geometry,
      cloud_coverage: searchOptions.cloud_coverage
    })
  }, [searchLayer])

  return (
    // <Modal {...props} show={props.show}>
    //   <Modal.Header>
    //     <Modal.Title>
    //     Загрузка KML
    //     </Modal.Title>
    //   </Modal.Header>
    //   <Modal.Body>
    //     <Form>
    //       <Uploader
    //         accept='.kml, .kmz, .geojson, .zip'
    //         type='file'
    //         onChange={kmlUpload}
    //       >
    //         <Button>{t('Common.Upload.kml')}</Button>
    //       </Uploader>
    //       <Uploader
    //         accept='.geojson, .shp, .zip'
    //         type='file'
    //         onChange={shpUpload}
    //       >
    //         <Button>{t('Common.Upload.shp')}</Button>
    //       </Uploader>
    //     </Form>
    //   </Modal.Body>
    //   <Modal.Footer>
    //     <Button onClick={props.onHide}>{t('Common.cancel')}</Button>
    //   </Modal.Footer>
    // </Modal>
    <div className={`dropdown-content ${props.adClass} ${localclass}`}>
      <menu className='dropdown-lister'>
        <div>
          <h4>{t('upload.title')}</h4>
          <hr />
          <Form>
            <Uploader
              accept='.kml, .kmz, .geojson, .zip'
              type='file'
              onChange={kmlUpload}
            >
              <Button style={{ width: '100%' }}>KML</Button>
            </Uploader>
            <Uploader
              accept='.geojson, .shp, .zip'
              type='file'
              onChange={shpUpload}
            >
              <Button style={{ width: '100%' }}>SHP</Button>
            </Uploader>
          </Form>
        </div>
      </menu>
    </div>
  )
}

export default UploadFilesModal
