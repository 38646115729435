import { $authHost, $host } from './index'
import jwt_decode from 'jwt-decode'
import Cookies from 'js-cookie'

export const registration = async (props) => {
  const { data } = await $host.post('user/registration', { ...props, role: 'USER' })
  if (data.success) {
    await login(props.email, props.password)
    return { decyph: jwt_decode(data.token), response: data }
  } else {
    return { response: data }
  }
}

export const login = async (email, password) => {
  const { data } = await $host.post('user/login', { email, password })
  console.log(data)
  if (data.success) {
    Cookies.set('first_name', data.user.first_name)
    Cookies.set('last_name', data.user.last_name)
    Cookies.set('email', data.user.email)
    Cookies.set('place', data.user.place)
    Cookies.set('token', data.token)
    return { decyph: jwt_decode(data.token), response: data }
  } else {
    return { response: data }
  }
}

export const syncJSON = async (id) => {
  const { data } = await $authHost.post('map/sync', { id })
  return { response: data }
}
export const addField = async (props) => {
  const { data } = await $authHost.post('fielddata/post', { field: { ...props, place: Cookies.get('place'), userid: jwt_decode(Cookies.get('token')).id } })
  return { response: data }
}
export const addFieldSpecial = async (props) => {
  const { data } = await $authHost.post('fielddata/special/post', { field: { ...props, place: Cookies.get('place') } })
  return { response: data }
}
export const addWeather = async (props) => {
  const { data } = await $authHost.post('fielddata/weather/post', { field: { ...props, place: Cookies.get('place'), userid: jwt_decode(Cookies.get('token')).id } })
  return { response: data }
}
export const getFields = async () => {
  const { data } = await $authHost.get('fielddata/get')
  return { response: data }
}
export const getFieldsSpecial = async () => {
  const { data } = await $authHost.get('fielddata/special/get')
  return { response: data }
}
export const getWeather = async () => {
  const { data } = await $authHost.get('fielddata/weather/get')
  return { response: data }
}
export const removeField = async (id) => {
  const { data } = await $authHost.post('fielddata/remove', { id })
  return { response: data }
}
export const removeFieldSpecial = async (id) => {
  const { data } = await $authHost.post('fielddata/special/remove', { id })
  return { response: data }
}
export const removeWeather = async (id) => {
  const { data } = await $authHost.post('fielddata/weather/remove', { id })
  return { response: data }
}
export const generateRegKey = async (props) => {
  const { data } = await $authHost.post('admin/regkey', { props })
  return { response: data }
}
export const getKeys = async () => {
  const { data } = await $authHost.get('admin/getkeys')
  return { response: data }
}
export const getUsers = async () => {
  const { data } = await $authHost.get('admin/getusers')
  return { response: data }
}
export const activate = async (regkey) => {
  const { data } = await $authHost.post('user/activate', { regkey, email: Cookies.get('email') })
  if (data.success) {
    Cookies.set('token', data.token)
    return { decyph: jwt_decode(data.token), response: data }
  } else {
    return { response: data }
  }
}
export const addGeoJSON = async (json) => {
  const { data } = await $authHost.post('map/add', { json, userid: jwt_decode(Cookies.get('token')).id })
  return { response: data }
}
export const addMarker = async (json) => {
  const { data } = await $authHost.post('map/marker/add', { json, userid: jwt_decode(Cookies.get('token')).id })
  return { response: data }
}
export const getGeoJSON = async (onlyProps = false) => {
  const { data } = await $authHost.post('map/get', { onlyProps })
  return { response: data }
}
export const deleteGeoJSON = async (id) => {
  const { data } = await $authHost.post('map/delete', { id })
  return { response: data }
}
export const getPlaces = async () => {
  const { data } = await $host.get('user/places')
  return { response: data }
}
export const quickLook = async () => {
  const { data } = await $authHost.get('map/quicklook')
  return { response: data }
}
export const updateGeoJSON = async (id, newname, cadaster, soil) => {
  const { data } = await $authHost.put('map/update', { id, newname, cadaster, soil, userid: jwt_decode(Cookies.get('token')).id })
  return { response: data }
}
export const addRecommendation = async (props) => {
  const { data } = await $authHost.post('map/recommend', { ...props })
  return { response: data }
}
export const getRecommendation = async () => {
  const { data } = await $authHost.get('map/recommend')
  return { response: data }
}
export const removeRecommendation = async (id) => {
  const { data } = await $authHost.post('map/recommend/delete', { id })
  return { response: data }
}
export const getFieldProps = async (name) => {
  const { data } = await $authHost.post('fielddata/props', { name })
  return { response: data }
}
export const getTempDay = async (time, croptype, field) => {
  const { data } = await $authHost.post('fielddata/gettempday', { croptype, field, time })
  return { response: data }
}
