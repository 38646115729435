/* eslint-disable prefer-regex-literals */
import React, { useState, useEffect } from 'react'
import { Modal, Form, Button, InputPicker, FlexboxGrid, Input, DatePicker, Whisper, Tooltip, InputGroup, Icon, Alert, IconButton } from 'rsuite'
import { getPlaces, registration } from '../http/API'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  BrowserRouter,
  Link
} from 'react-router-dom'

const SignUpModal = (props) => {
  const { t } = useTranslation()
  const [data, setData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    place: '',
    rkey: ''
  })
  useEffect(async () => {
    const res = await getPlaces()
    const p = res.response.placelist
    updatePlaces(p.filter((item, pos, self) =>
      (self.indexOf(item) === pos)))
  }
  , [])
  const [places, updatePlaces] = useState()
  const [eye, setEye] = useState('eye')
  const [passwordType, setPasswordType] = useState('password')
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    console.log(places)
  }, [places])
  const togglePassword = () => {
    if (eye === 'eye') {
      setEye('eye-slash')
      setPasswordType('text')
    } else {
      setEye('eye')
      setPasswordType('password')
    }
  }

  const onSubmit = async () => {
    const tempErrors = validate(data)
    // setErrors(temp_errors);
    if (Object.keys(tempErrors).length === 0) {
      try {
        const resp = await registration(data).then((res) => {
          setLoading(false)
          const response = res.response
          if (response.success) {
            Alert.success(response.message)
            props.onHide()
            window.location.href = window.location
          } else {
            Alert.error(response.message)
          }
        })
      } catch (e) {
        setLoading(false)
        Alert.error(t('error.connectionerror'))
        console.log(e)
      }
    }
  }

  const validate = (data) => {
    const errors = {}
    if (!data.email) {
      errors.email = t('Errors.empty_email')
    }
    const emailPattern = new RegExp('^[a-z0-9]+(.[_a-z0-9]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,15})$', 'i')
    if (data.email && !emailPattern.test(data.email.toLowerCase())) {
      errors.email = t('Errors.valid_email')
    }
    if (!data.password) {
      errors.password = t('Errors.empty_password')
    }
    const passwordPattern = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])', 'i')
    if (!passwordPattern.test(data.password)) {
      errors.password = t('Errors.valid_password')
    }
    return errors
  }

  return (
    <div className='show-grid'>

      <Modal className='welcome-modals' {...props} show={props.show} backdrop={false} keyboard={false}>
        <Modal.Header closeButton={false}>
          <Modal.Title className='text-center'>
            {t('welcome.signup')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <FlexboxGrid>
              <FlexboxGrid.Item colspan={12} className='p-15'>
                <Input
                  placeholder={t('common.name')} onChange={e => setData({
                    ...data,
                    first_name: e
                  })} size='md'
                />
              </FlexboxGrid.Item>

              <FlexboxGrid.Item colspan={12} className='p-15'>
                <Input
                  placeholder={t('common.surname')} onChange={e => setData({
                    ...data,
                    last_name: e
                  })} size='md'
                />
              </FlexboxGrid.Item>
              <FlexboxGrid.Item colspan={24} className='p-15'>
                <Input
                  placeholder={t('common.place_dif')} list='places' onChange={e => setData({
                    ...data,
                    place: e
                  })} size='md'
                />
              </FlexboxGrid.Item>

              {/* <FlexboxGrid.Item colspan={12} className='p-15'>

                <DatePicker
                  placeholder={t('Auth.Signup.birth_date')}
                  placement='bottomStart'
                />
              </FlexboxGrid.Item>

              <FlexboxGrid.Item colspan={12} className='p-15'>
                <Input
                  placeholder={t('Auth.Signup.phone')} o type='text' onChange={e => setData({
                    ...data,
                    phone: e
                  })} size='md'
                />
              </FlexboxGrid.Item>

              <FlexboxGrid.Item colspan={24} className='p-15'>
                <Input
                  placeholder={t('Auth.Signup.company')} o onChange={e => setData({
                    ...data,
                    company: e
                  })} size='md'
                />
              </FlexboxGrid.Item>

              <FlexboxGrid.Item colspan={24} className='p-15'>
                <InputPicker
                  placeholder={t('Auth.Signup.purpose')} o data={purposeList} onChange={e => setData({
                    ...data,
                    purpose: e
                  })} labelKey='text' valueKey='text' block size='md'
                />
              </FlexboxGrid.Item> */}

              <FlexboxGrid.Item colspan={24} className='p-15'>
                <Input
                  placeholder={t('common.email_dif')} csize='md' onChange={e => setData({
                    ...data,
                    email: e
                  })}
                />
              </FlexboxGrid.Item>

              <FlexboxGrid.Item colspan={24} className='p-15'>
                <Whisper
                  trigger='focus' speaker={
                    <Tooltip>{t('signup.passwordtooltip')} </Tooltip>
                }
                >
                  <InputGroup inside>
                    <Input
                      placeholder={t('common.password')} size='md'
                      type={passwordType}
                      onChange={e => setData({
                        ...data,
                        password: e
                      })}
                    />
                    <InputGroup.Addon>
                      <Icon icon={eye} onClick={togglePassword} />
                    </InputGroup.Addon>
                  </InputGroup>
                </Whisper>
              </FlexboxGrid.Item>
              <FlexboxGrid.Item colspan={24} className='p-15'>
                <Whisper
                  trigger='active' speaker={
                    <Tooltip>{t('signup.regkeytooltip')} <a href='https://forms.gle/ZVNpMFMhtxgMbpFK9' target='_blank' rel='noreferrer'>{t('profile.activatelink')}</a> </Tooltip>
                }
                >
                  <Input
                    placeholder={t('common.regkey')} csize='md' onChange={e => setData({
                      ...data,
                      rkey: e
                    })}
                  />
                </Whisper>
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <BrowserRouter forceRefresh>
            <Link to='/profile'>
              <IconButton appearance='ghost' size='sm' className='m-r-15 m-b-15' icon={<Icon icon='angle-left' />} />
            </Link>
          </BrowserRouter>
          <Button
            appearance='primary' size='sm' onClick={onSubmit} loading={loading} className='m-b-15 m-r-15 inc'
            disabled={data.email === '' || data.password === '' || data.first_name === '' || data.last_name === '' || data.company === ''}
          >
            {t('welcome.signup')}
          </Button>
        </Modal.Footer>
      </Modal>
      <datalist id='places'>
        {places && places.map((a, key) => {
          return (
            <option key={key} value={a}>{a}</option>
          )
        })}
      </datalist>
    </div>

  )
}

export default SignUpModal
// export default (SignUpModal)
