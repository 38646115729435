import { useState, useEffect } from 'react'
import { Alert, Input, SelectPicker } from 'rsuite'
import L from 'leaflet'
import { useTranslation } from 'react-i18next'

function CreateField ({ adClass, sendFunc, fieldJSON, fitBounds, layer, drawLayer, updatekey }) {
  const { t } = useTranslation()
  const [name, rename] = useState(t('common.noname'))
  const [cadaster, setCad] = useState()
  const [soiltype, setSoil] = useState()
  console.log(fieldJSON)
  let fieldToDisplay
  if (fieldJSON[0] === 'm') {
  // eslint-disable-next-line no-eval
    fieldToDisplay = eval(fieldJSON?.substring(1))
  }
  const selData = [
    {
      label: t('soiltype.south_ch'),
      value: t('soiltype.south_ch')
    },
    {
      label: t('soiltype.common_ch'),
      value: t('soiltype.common_ch')
    },
    {
      label: t('soiltype.dark_k'),
      value: t('soiltype.dark_k')
    },
    {
      label: t('soiltype.light_k'),
      value: t('soiltype.light_k')
    }
  ]
  useEffect(() => {
    fieldToDisplay &&
    fieldToDisplay.forEach(e => {
      const d = L.geoJSON(e).eachLayer((l) => (l.options.color = 'lime'))
      console.log(d)
      drawLayer.addLayer(d)
    })
  }, [fieldJSON, updatekey])
  return (
    <div className={`dropdown-content ${adClass}`}>
      {fieldJSON[0] === 'm'
        ? <>
          <h4>{t('sidemenu.savefield_file')}</h4><hr />
          <div style={{ overflowY: 'auto', maxHeight: '80vh' }}>
            {
            // eslint-disable-next-line no-eval
            fieldToDisplay.map((i, key) => {
              return (
                <div key={key} className='add-tile'>
                  <Input id='field-name' type='text' placeholder={t('sidemenu.name')} onChange={(e) => (i.properties.name = e)} />
                  <Input type='text' placeholder={t('sidemenu.cadaster')} onChange={(e) => (i.properties.cadaster = e)} />
                  <SelectPicker placement='autoHorizontalStart' searchable={false} block style={{ backgroundColor: 'var(--theme-text)' }} data={selData} placeholder={t('sidemenu.soil')} onChange={(e) => (i.properties.soiltype = e)} />
                  <div className='rs-input' style={{ marginBottom: '20px' }}>{t('sidemenu.area')} {i ? (i.properties.area / 10000).toFixed(3) : '0'} {t('sidemenu.ha')}</div>
                  <button
                    className='new-button' onClick={() => {
                      if (i && (i).type) {
                        drawLayer.clearLayers()
                        sendFunc(JSON.stringify(i))
                        fieldToDisplay.forEach(e => {
                          const d = L.geoJSON(e).eachLayer((l) => (l.options.color = 'lime'))
                          drawLayer.addLayer(d)
                        })
                      } else {
                        Alert.error('Сначала выберите поле!')
                      }
                    }}
                  >{t('common.save')}
                  </button>
                  <button
                    className='new-button' onClick={() => {
                      layer.clearLayers()
                      const polyToShow = L.geoJSON(i)
                      polyToShow.getLayers()[0].options.color = '#FF0000'
                      polyToShow.addEventListener('click', () => (layer.clearLayers()))
                      polyToShow.bringToBack()
                      layer.addLayer(polyToShow)
                      fitBounds(i)
                    }}
                  >{t('sidemenu.goto')}
                  </button>
                </div>
              )
            })
}
          </div>
        </>

        : <>
          <h4>{t('sidemenu.savefield')}</h4><hr />
          <Input id='field-name' type='text' placeholder={t('sidemenu.name')} onChange={(e) => rename(e)} />
          <Input type='text' placeholder={t('sidemenu.cadaster')} onChange={(e) => setCad(e)} />
          <SelectPicker placement='autoHorizontalStart' searchable={false} block style={{ backgroundColor: 'var(--theme-text)' }} data={selData} placeholder={t('sidemenu.soil')} onChange={(e) => setSoil(e)} />
          <div className='rs-input' style={{ marginBottom: '20px' }}>{t('sidemenu.area')} {fieldJSON ? (JSON.parse(fieldJSON).properties.area / 10000).toFixed(3) : '0'} {t('sidemenu.ha')}</div>
          <button
            className='new-button' onClick={() => {
              if (fieldJSON && JSON.parse(fieldJSON).type) {
                sendFunc(JSON.stringify({ ...JSON.parse(fieldJSON), properties: { ...JSON.parse(fieldJSON).properties, name, cadaster, soiltype } }))
              } else {
                Alert.error('Сначала выберите поле!')
              }
            }}
          >{t('common.save')}
          </button>
        </>}
    </div>
  )
}

export default CreateField
