import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import mapMain from './pages/map_main'
import Title from './pages/Title'
import Aboutus from './pages/AboutUs'
import Profile from './pages/Profile'
import Normcalculator from './pages/NormCalculator'
import Calculatorlist from './pages/CalculatorList'
import Analysis from './pages/Analysis'
import Manual from './pages/Manual'
import React from 'react'
import Recommend from './pages/Recommend'
import DataSave from './pages/DataSave'
import AdminSettings from './pages/AdminSettings'
import jwt_decode from 'jwt-decode'
import Cookies from 'js-cookie'
import ExternalLinks from './pages/ExternalLinks'
import Info from './pages/Info'
import DataAnalysis from './pages/DataAnalysis'
import WeatherSave from './pages/WeatherSave'
import InfoSpecial from './pages/InfoSpecial'
import DataSaveSpecial from './pages/DataSaveSpecial'
import DataAnalysisSpecial from './pages/DataAnalysisSpecial'
import Tracking from './pages/Tracking'
import RecSpecial from './pages/RecSpecial'
import MapRec from './map/MapRec'
import MapEmpty from './map/MapEmpty'
import RecList from './pages/RecList'
import NoteList from './pages/NoteList'
import Extra from './pages/Extra'

const token = Cookies.get('token')
const userRole = token ? jwt_decode(token).role : undefined
function App () {
  if (token) {
    const isTokenExpired = t => Date.now() >= (JSON.parse(atob(t.split('.')[1]))).exp * 1000
    if (isTokenExpired(token)) {
      Cookies.remove('token')
      window.location.reload()
    }
  }
  return (
    <>
      <Router>
        <Switch>
          <Route path='/recommendation' component={userRole ? Recommend : Profile} />
          <Route path='/extra' component={Extra} />
          <Route path='/manual' component={Manual} />
          <Route path='/analysis' component={userRole ? Analysis : Profile} />
          <Route path='/chartsspecial' component={userRole ? DataAnalysisSpecial : Profile} />
          <Route path='/about_us' component={Aboutus} />
          <Route path='/calc_list' component={Calculatorlist} />
          <Route path='/calculator_norm' component={userRole ? Normcalculator : Profile} />
          <Route path='/profile' component={Profile} />
          <Route path='/map_main' component={userRole ? mapMain : Profile} />
          <Route path='/datasave/:id' component={userRole ? DataSave : Profile} />
          <Route path='/datasavespecial/:id' component={userRole ? DataSaveSpecial : Profile} />
          <Route path='/extres' component={ExternalLinks} />
          <Route path='/info' component={userRole ? Info : Profile} />
          <Route path='/infospecial' component={InfoSpecial} />
          <Route path='/charts' component={userRole ? DataAnalysis : Profile} />
          <Route path='/weather' component={userRole ? WeatherSave : Profile} />
          <Route path='/tracking' component={userRole ? Tracking : Profile} />
          <Route path='/recspecial' component={userRole ? MapRec : Profile} />
          <Route path='/reclist' component={userRole ? RecList : Profile} />
          <Route path='/notelist' component={userRole ? NoteList : Profile} />
          <Route path='/map' component={MapEmpty} />
          <Route path='/adminsettings' component={userRole === 'ADMIN' ? AdminSettings : Profile} />
          <Route exact path='*' component={Title} />
        </Switch>
      </Router>
    </>
  )
}

export default App
