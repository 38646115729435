import React from 'react'
import Cookies from 'js-cookie'

const Calculatorlist = () => {
  const [curTheme, setTheme] = React.useState(Cookies.get('theme') === 'black' ? 'black' : 'white')
  if (curTheme === 'white') {
    document.documentElement.style.setProperty('--theme-main', 'darkblue')
    document.documentElement.style.setProperty('--theme-text', 'white')
    document.documentElement.style.setProperty('--theme-secondary', 'lightgray')
    document.documentElement.style.setProperty('--theme-contrast', 'black')
  } else if (curTheme === 'black') {
    document.documentElement.style.setProperty('--theme-main', 'lime')
    document.documentElement.style.setProperty('--theme-text', 'black')
    document.documentElement.style.setProperty('--theme-secondary', 'darkslategray')
    document.documentElement.style.setProperty('--theme-contrast', 'white')
  }
  const themeChangeFunc = () => {
    if (curTheme === 'black') {
      Cookies.set('theme', 'white')
      setTheme('white')
      document.documentElement.style.setProperty('--theme-main', 'darkblue')
      document.documentElement.style.setProperty('--theme-text', 'white')
      document.documentElement.style.setProperty('--theme-secondary', 'lightgray')
      document.documentElement.style.setProperty('--theme-contrast', 'black')
    } else {
      Cookies.set('theme', 'black')
      setTheme('black')
      document.documentElement.style.setProperty('--theme-main', 'lime')
      document.documentElement.style.setProperty('--theme-text', 'black')
      document.documentElement.style.setProperty('--theme-secondary', 'darkslategray')
      document.documentElement.style.setProperty('--theme-contrast', 'white')
    }
  }
  return (
    <div className='original-wrapper'>
      <header className='wrapper-full' style={{ width: 'min-content' }}>
        <div className='header'>
          <div id='toggle'>
            <input type='checkbox' />
          </div>
          <div id='mobile-menu__bg' />
          <a href='/'>
            <img src='img/site_logo.png' alt='' />
          </a>
        </div>
      </header>
      <section>
        <div className='gallery'>
          <div className='gallery__unit large'>
            <div className='gallery__unit--text'>
              <div>
                <h2 className='__head'>
                  Обзор <br />
                  калькуляторов
                </h2>
                <br />
                <br />
                <br />
                <br />
                <a className='transparent-button' href='/profile'>
                  НАЗАД
                </a>
              </div>
            </div>
          </div>
          <div className='gallery__unit'>
            <div className='gallery__unit--img'>
              <div />
              <h3>
                Штанговый <br />
                опрыскиватель (СЗР)
              </h3>
              <img src='img/ph1.png' alt='img' />
              <a href='https://eshop.lechler.de/INTERSHOP/web/WFS/LechlerDE-ToolsDE-Site/ru_RU/-/EUR/L3_ViewAgricultureCalculator-Start?_ga=2.259917833.2026413803.1605219632-2026041483.1605219632' />
            </div>
          </div>
          <div className='gallery__unit'>
            <div className='gallery__unit--img'>
              <div />
              <h3>Жидкие удобрения (КАС)</h3>
              <img src='img/ph1.png' alt='img' />
              <a href='https://eshop.lechler.de/INTERSHOP/web/WFS/LechlerDE-ToolsDE-Site/ru_RU/-/EUR/L3_ViewLiquidFertilizingCalculator-Start?_ga=2.94293150.2026413803.1605219632-2026041483.1605219632' />
            </div>
          </div>
          <div className='gallery__unit'>
            <div className='gallery__unit--img'>
              <div />
              <h3>
                Расчет норм <br />
                внесения удобрений
              </h3>
              <img src='img/105.jpg' alt='img' />
              <a href='/calculator_norm' />
            </div>
          </div>
          {/* <div className="gallery__unit">
        <div className="gallery__unit--img">
          <div />
          <h3>Садовые</h3>
          <img src="img/ph1.png" alt="img" />
          <a href="https://eshop.lechler.de/INTERSHOP/web/WFS/LechlerDE-ToolsDE-Site/ru_RU/-/EUR/L3_ViewVerticalCropsCalculator-Start?_ga=2.94293150.2026413803.1605219632-2026041483.1605219632" />
        </div>
      </div>
      <div className="gallery__unit">
        <div className="gallery__unit--img">
          <div />
          <h3>Ранцевые</h3>
          <img src="img/ph1.png" alt="img" />
          <a href="https://eshop.lechler.de/INTERSHOP/web/WFS/LechlerDE-ToolsDE-Site/ru_RU/-/EUR/L3_ViewKnapsackCalculator-Start?_ga=2.94293150.2026413803.1605219632-2026041483.1605219632" />
        </div>
      </div> */}
          <div className='gallery__unit'>
            <div className='gallery__unit--img'>
              <div />
              <h3>Аппликатор пропашных</h3>
              <img src='img/ph1.png' alt='img' />
              <a href='https://eshop.lechler.de/INTERSHOP/web/WFS/LechlerDE-ToolsDE-Site/ru_RU/-/EUR/L3_ViewSprayFrameCalculator-Start?_ga=2.94293150.2026413803.1605219632-2026041483.1605219632' />
            </div>
          </div>
          <div className='gallery__unit'>
            <div className='gallery__unit--img'>
              <div />
              <h3>Dropleg</h3>
              <img src='img/ph1.png' alt='img' />
              <a href='https://eshop.lechler.de/INTERSHOP/web/WFS/LechlerDE-ToolsDE-Site/ru_RU/-/EUR/L3_ViewDroplegCalculator-Start?_ga=2.94293150.2026413803.1605219632-2026041483.1605219632' />
            </div>
          </div>
        </div>
      </section>
    </div>

  )
}

export default Calculatorlist
