import 'rsuite/dist/styles/rsuite-dark.css'
import Map from '../map/Map'
import SatImgContextProvider from '../contexts/DataProviderContext'
import SearchOptionsContextProvider from '../contexts/SearchDetailsContext'
// import UploadKML from '../imports/UploadKML'
// import SidenavPanel from '../components/SidebarPanel'
import NavigationContextProvider from '../contexts/NavigationContext'

function mapMain () {
  // const { setNavItemId } = useContext(NavigationContextProvider)
  // TODO: add logic for router order path

  return (
    <div className='App'>
      <NavigationContextProvider>
        <SearchOptionsContextProvider>
          <SatImgContextProvider>
            <Map />
          </SatImgContextProvider>
        </SearchOptionsContextProvider>
      </NavigationContextProvider>
    </div>
  )
}

export default mapMain
