import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as FA from '@fortawesome/free-solid-svg-icons'
import Cookies from 'js-cookie'
import { SelectPicker } from 'rsuite'
import { useTranslation } from 'react-i18next'

const Info = () => {
  const { t, i18n } = useTranslation()
  const infoData = {
    Подсолнечник: {
      'Однолетние злаковые сорняки (овсюг, виды щетинника, просо куриное)': {
        agro_method: 'Соблюдение севооборота и своевременное выполнение агротехнических мероприятий',
        chems: ['Фуроре Ультра (0,5 -0,75 л/га)'],
        chem_regs: ['Фаза развития культуры: 2 настоящих листика – 8 настоящих листиков. Опрыскивание посевов с фазы 2-х листьев до конца кущения сорняков']
      }
    },
    'Пшеница озимая': {
      'Однолетние злаковые': {
        agro_method: 'Соблюдение севооборота и своевременное выполнение агротехнических мероприятий',
        chems: ['Барс Супер (0,6 – 0,9 л/га)', 'Пума Супер 100 (0,6 – 0,9 л/га)', 'Пума Турбо (0,45 – 0,75 л/га)'],
        chem_regs: ['Фаза развития культуры: 1,2,3 лист – начало колошения. Опрыскивание посевов по вегетирующим сорнякам, начиная с фазы 2-го листа до конца кущения (независимо от фазы развития культуры)', 'Опрыскивание посевов по вегетирующим сорнякам, начиная с фазы 2-го листа до конца кущения (независимо от фазы развития культуры)', 'Опрыскивание посевов по вегетирующим сорнякам, начиная с фазы 2-го листа до конца кущения (независимо от фазы развития культуры)']
      },
      'Однолетние и многолетние двудольные': {
        agro_method: 'Севооборот',
        chems: ['Секатор Турбо (0,05 – 0,075 л/га)'],
        chem_regs: ['Опрыскивание посевов по вегетирующим сорнякам (независимо от фазы развития культуры)']
      },
      'Метлица обыкновенная': {
        agro_method: 'Севооборот',
        chems: ['Пума Супер 7.5 (0,8 – 1,0 л/га)'],
        chem_regs: ['Фаза развития культуры: 1,2,3 лист – начало колошения. Опрыскивание посевов рано весной по вегетирующим сорнякам']
      },
      'Однолетние злаковые, однолетние и многолетние двудольные сорняки': {
        agro_method: 'Севооборот',
        chems: ['Велосити Пауэр (0,22-0,33 кг/га + ПАВ (Био-Пауэр) 0,5-0,7 л/га)'],
        chem_regs: ['Фаза развития культуры: 1,2,3 лист – 2е междоузлие. Опрыскивание посевов в фазе с 3-х листьев до 2-го междоузлия культуры']
      }
    },
    'Пшеница яровая': {
      'Однолетние злаковые(овсюг, виды щетинника, просо куриное)': {
        agro_method: 'Соблюдение севооборота и своевременное выполнение агротехнических мероприятий',
        chems: ['Барс Супер (0,6 – 0,9 л/га)', 'Пума Супер 7.5 (0,8 – 1,2 л/га)', 'Пума Супер 100 (0,6 – 0,9 л/га)', 'Пума Турбо (0,45 – 0,75 л/га)'],
        chem_regs: ['Опрыскивание посевов по вегетирующим сорнякам, начиная с фазы 2-го листа до конца кущения (независимо от фазы развития культуры)', 'Опрыскивание посевов по вегетирующим сорнякам, начиная с фазы 2-го листа до конца кущения (независимо от фазы развития культуры)', 'Опрыскивание посевов по вегетирующим сорнякам, начиная с фазы 2-го листа до конца кущения (независимо от фазы развития культуры)', 'Опрыскивание посевов по вегетирующим сорнякам, начиная с фазы 2-го листа до конца кущения (независимо от фазы развития культуры)']
      },
      'Однолетние и многолетние двудольные': {
        agro_method: 'Севооборот',
        chems: ['Секатор Турбо (0,05 – 0,075 л/га)'],
        chem_regs: ['Опрыскивание посевов по вегетирующим сорнякам (независимо от фазы развития культуры)']
      },
      'Однолетние злаковые, однолетние и многолетние двудольные сорняки': {
        chems: ['Велосити Пауэр (0,22-0,33 кг/га + ПАВ (Био-Пауэр) 0,5-0,7 л/га)'],
        chem_regs: ['Фаза развития культуры: 1,2,3 лист – 2е междоузлие. Опрыскивание посевов в фазе с 3-х листьев до 2-го междоузлия культуры']
      },
      'Злаковые (в т.ч. пырей ползучий) и некоторые однолетние двудольные сорные растения': {
        chems: ['Атрибут (0,08-0,10 + ПАВ Био-Пауэр 0,5 л/га)'],
        chem_regs: ['Фаза развития культуры: 1,2,3 лист – 2е междоузлие. Опрыскивание посевов в фазу от 3-х листьев до конца кущения культуры']
      }
    },
    'Горох (на зерно)': {
      'Однолетние злаковые(овсюг, виды щетинника, просо куриное)': {
        agro_method: 'Соблюдение севооборота и своевременное выполнение агротехнических мероприятий',
        chems: ['Фуроре ультра (0,5 – 0,75 л/га)'],
        chem_regs: ['Фаза развития культуры: Прорастание – 1,2,3 лист. Фаза развития культуры: 2 настоящих листика – 8 настоящих листиков. Опрыскивание посевов с фазы 2-х листьев до конца кущения сорняков']
      }
    },
    'Соя (в условиях орошения)': {
      'Однолетние злаковые(овсюг, виды щетинника, просо куриное)': {
        chems: ['Фуроре ультра (0,5 – 0,75 л/га)'],
        chem_regs: ['Опрыскивание посевов с фазы 2-х листьев до конца кущения сорняков']
      },
      'Однолетние двудольные и злаковые сорняки': {
        agro_method: 'Соблюдение севооборота и своевременное выполнение агротехнических мероприятий',
        chems: ['Зенкор Ультра (0,6 л/га)'],
        chem_regs: ['Опрыскивание почвы до всходов культуры']
      }
    },
    'Люцерна-многолетние травы': {
      'Любые сорняки': {
        agro_method: 'Соблюдение севооборота и своевременное выполнение агротехнических мероприятий'
      }
    },
    'Яровой ячмень': {
      'Однолетние злаковые(овсюг обыкновенный, щетинник зеленый, просо куриное)': {
        agro_method: 'Соблюдение севооборота и своевременное выполнение агротехнических мероприятий',
        chems: ['Пума Супер 7.5 (0,6 – 0,9 л/га)'],
        chem_regs: ['Фаза развития культуры: 1,2,3 лист – 2е междоузлие. Опрыскивание посевов по вегетирующим сорнякам, начиная с фазы 2-го листа до конца кущения (независимо от фазы развития культуры)']
      },
      'Однолетние и многолетние двудольные (желтушник левкойный, все виды ромашек)': {
        chems: ['Секатор Турбо (0,05 – 0,075 л/га)'],
        chem_regs: ['Опрыскивание почвы до всходов культуры']
      }
    },
    Гречиха: {
      'Однолетние двудольные сорняки, однолетние злаковые сорняки (щирица запрокинутая, горчица полевая)': {
        agro_method: 'Соблюдение севооборота и своевременное выполнение агротехнических мероприятий',
        chems: ['2,4-Д (диметиламинная соль) (Норма расхода 1,0-1,3 л/га. Расход рабочей жидкости – 200-300 л/га)'],
        chem_regs: ['Опрыскивание посевов за 2-3 дня до всходов культуры.']
      },
      'Однолетние злаковые сорняки': {
        chems: ['Клетодим + галоксифоп-Р-метил  (Норма расхода 0,4-0,8 л/га. Расход рабочей жидкости – 200-300 л/га.)'],
        chem_regs: ['Опрыскивание посевов в фазе 2-6 листьев сорных растений независимо от фазы развития культуры.']
      }
    },
    Картофель: {
      'Осот огородный, пастушья сумка, лебеда раскидистая, вьюнок. Карантинные паразитные сорняки – повилика (клеверная, китайская, полевая) и заразиха египетская.': {
        agro_method: 'Соблюдение севооборота и своевременное выполнение агротехнических мероприятий',
        chems: ['Раундап, Боксер, Лазурит 250 на 100 л воды'],
        chem_regs: ['Опрыскивание посевов по вегетирующим сорнякам (независимо от фазы развития культуры) ']
      },
      'Однолетние двудольные и злаковые сорняки': {
        chems: ['Зенкор ультра (0,6-0,8 л/га)'],
        chem_regs: ['Опрыскивание почвы до и после всходов культуры']
      },
      'Многолетние, однолетние двудольные и злаковые сорняки': {
        chems: ['Баста (2,0-2,5 л/га)'],
        chem_regs: ['Созревание клубней, увядание ботвы']
      }
    }
  }
  const [curTheme, setTheme] = React.useState(Cookies.get('theme') === 'black' ? 'black' : 'white')
  if (curTheme === 'white') {
    document.documentElement.style.setProperty('--theme-main', 'darkblue')
    document.documentElement.style.setProperty('--theme-text', 'white')
    document.documentElement.style.setProperty('--theme-secondary', 'lightgray')
    document.documentElement.style.setProperty('--theme-contrast', 'black')
  } else if (curTheme === 'black') {
    document.documentElement.style.setProperty('--theme-main', 'lime')
    document.documentElement.style.setProperty('--theme-text', 'black')
    document.documentElement.style.setProperty('--theme-secondary', 'darkslategray')
    document.documentElement.style.setProperty('--theme-contrast', 'white')
  }
  const selData = Object.keys(infoData).map((f) => {
    return (
      {
        label: f,
        value: f
      }
    )
  })
  console.log(selData)
  const themeChangeFunc = () => {
    if (curTheme === 'black') {
      Cookies.set('theme', 'white')
      setTheme('white')
      document.documentElement.style.setProperty('--theme-main', 'darkblue')
      document.documentElement.style.setProperty('--theme-text', 'white')
      document.documentElement.style.setProperty('--theme-secondary', 'lightgray')
      document.documentElement.style.setProperty('--theme-contrast', 'black')
    } else {
      Cookies.set('theme', 'black')
      setTheme('black')
      document.documentElement.style.setProperty('--theme-main', 'lime')
      document.documentElement.style.setProperty('--theme-text', 'black')
      document.documentElement.style.setProperty('--theme-secondary', 'darkslategray')
      document.documentElement.style.setProperty('--theme-contrast', 'white')
    }
  }
  const [croptype, setCrop] = useState()
  useEffect(() => {
    console.log(infoData[croptype])
  }, [croptype])

  return (
    <div className='data calcmodal-body'>
      <a style={{ width: 'fit-content', float: 'left', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' onClick={themeChangeFunc}>
        <FontAwesomeIcon icon={FA.faPalette} />
      </a>
      <a style={{ width: 'fit-content', float: 'right', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' href='/profile'>
        <FontAwesomeIcon icon={FA.faHome} />
      </a>
      <a style={{ width: 'fit-content', float: 'right', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' onClick={() => i18n.changeLanguage(i18n.language === 'ru' ? 'kz' : i18n.language === 'kz' ? 'en' : 'ru')}>
        <h5 style={{ margin: 'auto' }}>{i18n.language.toUpperCase()}</h5>
      </a>
      <h3 align='center'>Справочник по вредителям</h3>
      <hr />
      <SelectPicker searchable={false} placeholder='Выберите культуру' block id='croptype' onChange={e => setCrop(e)} data={selData} />
      {
        infoData[croptype] && <>
          <br /><h4>Вредители:</h4><br />
          {Object.keys(infoData[croptype]).map((f, key) => {
            return (
              <div className='info-div' key={key}>
                <h4>{f}</h4>
                <p>Методы защиты:</p>
                {infoData[croptype][f].agro_method && <>
                  <p>Агротехнические:</p>
                  {infoData[croptype][f].agro_method}
                </>}
                {infoData[croptype][f].chems && <>
                  <p>Химические (гербициды):</p>
                  {
              infoData[croptype][f].chems.map((g, key) => {
                return (
                  <div className='info-div' key={key}>
                    {g}
                    <p>Регламент:</p>
                    {infoData[croptype][f].chem_regs[key]}
                  </div>
                )
              })
            }
                </>}
              </div>
            )
          })}
        </>
      }
    </div>
  )
}

export default Info
