import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as FA from '@fortawesome/free-solid-svg-icons'
import { getFields, getFieldsSpecial, getGeoJSON } from '../http/API'
import { Input, Alert, Button, ControlLabel } from 'rsuite'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'
import ChartSpecial from './charts/ChartSpecial'

function DataAnalysisSpecial ({ slave, fieldId_master, adClass, hideFunc, field, chosenField }) {
  const { t, i18n } = useTranslation()
  function sortByProperty (property) {
    return function (a, b) {
      if (a[property] > b[property]) {
        return 1
      } else if (a[property] < b[property]) {
        return -1
      }
      return 0
    }
  }
  const renderIDs = (season) => {
    const ids = []
    return fieldsArray.map((f, key2) => {
      if (f.season === season && !ids.includes(f.field_id) && f.field_id === chosenField?.id) {
        ids.push(f.field_id)
        return (
          <div key = {key2}>
            <h5>-- Поле {namesByIDs[f.field_id] || '??'} - {f.croptype}<br /> ({yieldList[season] && yieldList[season][f.field_id] ? yieldList[season][f.field_id] + ' ц/га' : 'Заметка с урожайностью не найдена!'})</h5>
            {
              renderTypes(f.field_id, season)
            }
          </div>
        )
      }
    })
  }
  const renderTypes = (field_id, season) => {
    return fieldsArray.map((f, key3) => {
      if (f.season === season && f.field_id === field_id) {
        return (
          <div key = {key3}>
            <p className='note-div' style = {{ width: 'fit-content' }} onClick = {() => {
              setChartSumsToSend([])
              setChartSums({})
              fieldsArray.map(e => {
                if (e.field_id === field_id) {
                  chartSums[e.season] ? chartSums[e.season] += Number(e.sum) : chartSums[e.season] = Number(e.sum)
                }
              })
              setChartSumsToSend(Object.keys(chartSums).map(e => {
                return ({ label: e, y: chartSums[e] })
              }))
              setYieldListToSend(yearsList.map(e => {
                return ({ label: e, y: yieldList[e] && yieldList[e][field_id] ? Number(yieldList[e][field_id].replace(',', '.')) : 0 })
              }))
              setChartID(field_id)
              setChartType(f.type)
            }}>---- {f.type} ({f.sum}°C)</p>
          </div>
        )
      }
    })
  }
  const [chartID, setChartID] = useState()
  const [chartType, setChartType] = useState()
  const [chartSums, setChartSums] = useState({})
  const [chartSumsToSend, setChartSumsToSend] = useState({})
  const [fieldsToSend, setFieldsToSend] = useState()

  const [fieldsArray, setFields] = useState([])
  const [updateKey, update] = useState(0)
  const [yearsList, setYears] = useState([])
  const [yieldList, setYieldList] = useState({})
  const [yieldListChart, setYieldListToSend] = useState([])
  const [namesByIDs, setNames] = useState({})
  useEffect(async () => {
    const jsonlist = await getGeoJSON(true)
    jsonlist.response.fields.forEach(e => {
      namesByIDs[e.id] = e.properties.name
    })
    const notesList = await getFields()
    const notes = notesList.response.fields
    notes?.forEach(e => {
      if (!yieldList[e.season]) {
        yieldList[e.season] = {}
      }
      yieldList[e.season][e.field_id] = e.yield
    })
    const fieldsList = await getFieldsSpecial()
    const years = []
    fieldsList.response.fields.forEach(e => {
      years.push(e.season)
    })
    setYears([...new Set(years)])
    if (fieldsList.response.success) {
      setFields(fieldsList.response.fields.sort(sortByProperty('season')))
    }
  }, [updateKey])
  useEffect(() => {
    if (fieldId_master) {
      setChartID(fieldId_master)
      setFieldsToSend(fieldsArray.filter((e) => e.id === fieldId_master))
    }
    // update(updateKey + 1)
  }, [fieldId_master])
  const [curTheme, setTheme] = useState(Cookies.get('theme') === 'black' ? 'black' : 'white')
  if (!slave) {
    if (curTheme === 'white') {
      document.documentElement.style.setProperty('--theme-main', 'darkblue')
      document.documentElement.style.setProperty('--theme-text', 'white')
      document.documentElement.style.setProperty('--theme-secondary', 'lightgray')
      document.documentElement.style.setProperty('--theme-contrast', 'black')
    } else if (curTheme === 'black') {
      document.documentElement.style.setProperty('--theme-main', 'lime')
      document.documentElement.style.setProperty('--theme-text', 'black')
      document.documentElement.style.setProperty('--theme-secondary', 'darkslategray')
      document.documentElement.style.setProperty('--theme-contrast', 'white')
    }
  }
  const themeChangeFunc = () => {
    if (curTheme === 'black') {
      Cookies.set('theme', 'white')
      setTheme('white')
      document.documentElement.style.setProperty('--theme-main', 'darkblue')
      document.documentElement.style.setProperty('--theme-text', 'white')
      document.documentElement.style.setProperty('--theme-secondary', 'lightgray')
      document.documentElement.style.setProperty('--theme-contrast', 'black')
    } else {
      Cookies.set('theme', 'black')
      setTheme('black')
      document.documentElement.style.setProperty('--theme-main', 'lime')
      document.documentElement.style.setProperty('--theme-text', 'black')
      document.documentElement.style.setProperty('--theme-secondary', 'darkslategray')
      document.documentElement.style.setProperty('--theme-contrast', 'white')
    }
  }
  return (
    <div style={{ display: 'flex' }} className={slave && `dropdown-content ${adClass}`}>
    <div style={slave && { width: '400px', overflowY: 'auto', maxHeight: '80vh' }}>
{ slave && <a style={{ width: 'fit-content', float: 'right', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' onClick={() => hideFunc(false)}>
    <FontAwesomeIcon icon={FA.faTimes} />
  </a>}
      <div className='calcmodal-body data' style={slave && { display: 'none' }} key={updateKey}>
        <a style={{ width: 'fit-content', float: 'left', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' onClick={themeChangeFunc}>
          <FontAwesomeIcon icon={FA.faPalette} />
        </a>
        <a style={{ width: 'fit-content', float: 'right', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' href='/profile'>
          <FontAwesomeIcon icon={FA.faHome} />
        </a>
        <a style={{ width: 'fit-content', float: 'right', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' onClick={() => i18n.changeLanguage(i18n.language === 'ru' ? 'kz' : i18n.language === 'kz' ? 'en' : 'ru')}>
          <h5 style={{ margin: 'auto' }}>{i18n.language.toUpperCase()}</h5>
        </a>
        <h3 align='center'>Мониторинг урожайности полей</h3>
        <br />
        <div className='note-div-parent'>
          {/* {
    fieldsArray.length === 0
      ? 'У вашего хозяйства пока нет заметок!'
      : fieldsArray.map((f, key) => {
        // console.log(JSON.parse(selField.json).properties.name, f.field_name)
        if (curYear !== f.season) {
          if (curYear !== 0) {
            f.div = true
          }
          curYear = f.season
        }
        return (
          <>
            <div
              className='note-div' style={{ width: 'min-content', whiteSpace: 'nowrap' }} key={key + 150} onClick={() => {
                setChartID(f.field_name)
                setChartYear(f.season)
                setFieldsToSend(fieldsArray.filter((e) => e.field_name === f.field_name))
              }}
            >
              <p style={{ width: '20%' }}>{f.season}: {f.croptype} ({f.type})</p>
            </div>
          </>
        )
      })
    } */}
        </div>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
      {fieldsArray &&
          <div className='calcmodal-body data' style={{ width: 'fit-content' }}>
            {
              yearsList.map((season, key) => (
                <div key = {key}>
                  <h4>{season}:</h4>
                  {renderIDs(season)}
                  <hr />
                </div>
              ))
            }
          </div>
      }
      {fieldsArray.length === 0 && <h5>{t('yield.nonotes')}</h5>}
      {/* {chartID && !slave &&
          <div className='calcmodal-body data' style = {{ height: '100%', margin: '0 10px' }}>
            <ChartSpecial id={chartID} fields={fieldsArray} update={updateKey} type={chartType} yields={yieldListChart} names={namesByIDs} sums={chartSumsToSend} />
          </div>
      } */}
      </div>
    </div>
          {chartID && slave &&
            <div className='calcmodal-body data' style = {{ height: '100%', width: '40vw', margin: '0 10px' }}>
              <ChartSpecial id={chartID} fields={fieldsArray} update={updateKey} type={chartType} yields={yieldListChart} names={namesByIDs} sums={chartSumsToSend} />
              </div>
          }
          </div>
  )
}

export default DataAnalysisSpecial
