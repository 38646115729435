import { useState, useEffect } from 'react'
import { Alert, Input, SelectPicker } from 'rsuite'
import { useTranslation } from 'react-i18next'
import { updateGeoJSON } from '../../http/API'

function UpdateField ({ adClass, fieldJSON, updatekey, key, onHide }) {
  const { t } = useTranslation()
  const [name, rename] = useState()
  const [cadaster, setCad] = useState()
  const [soiltype, setSoil] = useState()
  useEffect(() => {
    if (fieldJSON) {
      console.log(JSON.parse(fieldJSON.json).properties.name)
      document.getElementById('field-name-update').value = JSON.parse(fieldJSON.json).properties.name ? JSON.parse(fieldJSON.json).properties.name : null
      if (JSON.parse(fieldJSON.json).geometry?.type !== 'Point' && JSON.parse(fieldJSON.json).properties.cadaster) {
        document.getElementById('cadaster').value = JSON.parse(fieldJSON.json).properties.cadaster
      } else if (JSON.parse(fieldJSON.json).geometry?.type !== 'Point') {
        document.getElementById('cadaster').value = null
      }
    }
  }, [fieldJSON])
  const selData = [
    {
      label: t('soiltype.south_ch'),
      value: t('soiltype.south_ch')
    },
    {
      label: t('soiltype.common_ch'),
      value: t('soiltype.common_ch')
    },
    {
      label: t('soiltype.dark_k'),
      value: t('soiltype.dark_k')
    },
    {
      label: t('soiltype.light_k'),
      value: t('soiltype.light_k')
    }
  ]
  return (
    <div className={`dropdown-content ${adClass}`}>
        {fieldJSON &&
          <>
          <h4>Обновление поля</h4><hr />
          <Input id='field-name-update' type='text' placeholder={JSON.parse(fieldJSON.json).geometry?.type !== 'Point' ? t('sidemenu.name') : 'Текст заметки'} onChange={(e) => rename(e)} />
          { JSON.parse(fieldJSON.json).geometry?.type !== 'Point' &&
          <>
          <Input type='text' id='cadaster' placeholder={JSON.parse(fieldJSON.json).properties.cadaster ? JSON.parse(fieldJSON.json).properties.cadaster : t('sidemenu.cadaster')} onChange={(e) => setCad(e)} />
          <SelectPicker placement='autoHorizontalStart' searchable={false} block style={{ backgroundColor: 'var(--theme-text)' }} data={selData} placeholder={t('sidemenu.soil')} onChange={(e) => setSoil(e)} />
          <div className='rs-input' style={{ marginBottom: '20px' }}>{t('sidemenu.area')} {fieldJSON ? (JSON.parse(fieldJSON.json).properties.area / 10000).toFixed(3) : '0'} {t('sidemenu.ha')}</div>
          </>
}
          <button
            className='new-button' onClick={async () => {
              try {
                const res = await updateGeoJSON(fieldJSON.id, name, cadaster, soiltype).then((e) => {
                  e.response.success ? Alert.success(e.response.message) : Alert.error(e.response.message)
                  updatekey(key + 1)
                  onHide()
                  window.location.reload()
                })
              } catch (e) {
                console.log(e)
              }
            }}
          >{t('common.save')}
          </button><br />
          <button
            className='new-button' onClick={() => {
              onHide()
            }}
          >Назад
          </button>
        </>
}
    </div>
  )
}

export default UpdateField
