import { useEffect } from 'react'
import AOS from 'aos'
import { useTranslation } from 'react-i18next'

const Aboutus = () => {
  const { t, i18n } = useTranslation()
  const reveal = () => {
    const mobileMenuBg = document.getElementById('mobile-menu__bg') // mobile menu fullscreen background
    const scrollLine = document.querySelector('.scroll-line') // two vertical lines
    const menuMobile = document.querySelector('.header__hav') // mobile menu
    if (mobileMenuBg.style.display === 'block') {
      mobileMenuBg.style.display = 'none'
      scrollLine.style.display = 'block'
      menuMobile.style.display = 'none'
      document.body.style.overflowY = 'auto' // disable scrolling in menu
    } else {
      mobileMenuBg.style.display = 'block'
      scrollLine.style.display = 'none'
      menuMobile.style.display = 'flex'
      document.body.style.overflowY = 'hidden'
    }
  }
  useEffect(() => {
    AOS.init()
    AOS.refresh()
  }, [])
  return (
    <div className='original-wrapper'>
      <header className='wrapper-full'>
        <div className='header'>
          {/* Mobile Menu */}
          <div id='toggle' className='black-toggle'>
            <input style={{ backgroundColor: 'black' }} type='checkbox' onChange={reveal} />
            <span className='black-toggle' />
          </div>
          <div id='mobile-menu__bg' />
          {/* /Mobile Menu */}
          <a href='/'>
            <img src='img/site_logo.png' alt='' />
          </a>
          <div className='header__hav'>
            <nav className='header__hav--items'>
              <ul>
                <li>
                  <a className='--link' href='/'>
                    <font color='black'>{t('title.list.main')}</font>
                  </a>
                </li>
                <li>
                  <a className='--link' href='/about_us'>
                    <font color='black'>{t('title.list.aboutus')}</font>
                  </a>
                </li>
                <li>
                  <a className='--link' href='/profile'><font color='black'>{t('title.list.profile')}</font></a>
                </li>
                <li>
                  <a className='--link' style={{ cursor: 'pointer' }} onClick={() => i18n.changeLanguage(i18n.language === 'ru' ? 'kz' : i18n.language === 'kz' ? 'en' : 'ru')}><font color='black'>{t('map.sidebar.language').toUpperCase()}: {i18n.language.toUpperCase()}</font></a>
                </li>
              </ul>
            </nav>
            <a
              href='https://forms.gle/ZVNpMFMhtxgMbpFK9'
              className='color-button'
            >
              {t('title.list.demo')}
            </a>
          </div>
        </div>
      </header>
      <section className='__testimonials-bg'>
        <div className='wrapper'>
          <div className='testimonials'>
            <div className='testimonials__text'>
              <div aos-init='' aos-animate='' data-aos='fade'>
                <h2 className='__head'>{t('title.list.aboutus')}</h2>
                <p />
              </div>
            </div>
            <div className='testimonials-slider'>
              <div>
                <p>
                  {t('aboutus.test1.text')}
                </p>
                <div className='testimonials-slider__info'>
                  <div>
                    <img src='img/EmployeePhoto2.jpg' alt='ava' />
                  </div>
                  <strong>{t('aboutus.test1.name')}</strong>
                  <span>&nbsp;/&nbsp;</span>{t('aboutus.test1.degree')}
                </div>
              </div>
              {/* <div>
                  <p>Планшет Агронома — это интерактивная карта, которая визуализирует данные о 60 миллионах полей. Cтатистика собрана при помощи нейронной сети, для визуализации использован сервис Mapbox. При помощи нашего проекта можно узнать размеры
                      и количество полей в разных странах, рейтинг сельскохозяйственных культур, проследить развитие поля конкретного поля и относительную урожайность за последние три года. Пользователи могут сообщить разработчикам о неточностях
                      в определении культур и полей, эти данные используются для улучшения алгоритмов машинного обучения. Также карта позволяет получить представление о локальных и мировых тенденциях в производстве сельскохозяйственных культур.
                  </p>
                  <div class='testimonials-slider__info'>
                      <div><img src='img/EmployeePhoto3.png' alt='ava'></div>
                      <strong>Бейсекенов Наиль Аликұлы</strong>
                      <span>&nbsp;/&nbsp;</span>Программист
                  </div>
              </div> */}
              <div style={{ marginTop: '60px' }}>
                <p>
                  {t('aboutus.test2.text')} <br /> {t('aboutus.test2.text2')}
                </p>
                <div className='testimonials-slider__info'>
                  <div>
                    <img src='img/EmployeePhoto.jpg' alt='ava' />
                  </div>
                  <strong>{t('aboutus.test2.name')}</strong>
                  <span>{t('aboutus.test2.deg')}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className='scroll-line' />
      <section>
        <div className='wrapper'>
          <div aos-init='' aos-animate='' data-aos='fade' className='world'>
            <h2 className='__head'>{t('aboutus.test3.title')}</h2>
            <h2 className='__head'>
              <span>{t('aboutus.test3.subtitle')} </span>
            </h2>
            <font color='black'>
              <p>
                {t('aboutus.test3.text')}
              </p>
            </font>
            <img
              data-aos='slide-up'
              data-aos-duration={800}
              data-aos-offset={50}
              className='img-adaptive'
              src='img/header11.jpg'
              alt='img'
            />
            {/*  <img data-aos='slide-up' data-aos-duration='800' data-aos-offset='50' class='img-adaptive'
         src='img/employer2.png' alt='img'>
      </div>
    </div>
  </section>
*/}
            {/*
  <section class='__testimonials-bg'>
    <div class='wrapper'>
      <div class='testimonials'>
        <div class='testimonials__text'>
          <div aos-init aos-animate data-aos='fade'>
            <p></p>
          </div>
        </div>
              <img src='img/969.png'>
              <img src='img/969_1.png'>
          </div>
        </div>
  </section>
    <div class='wrapper'>
      <div class='testimonials'>
        <div class='testimonials__text'>
          <div aos-init aos-animate data-aos='fade'>
            <p></p>
          </div>
        </div>
              <img src='img/900.png'>
              <img src='img/901.png'>
          </div>
      </div>
*/}
            <footer>
              <div className='wrapper'>
                <div className='footer'>
                  <div className='footer--top'>
                    <div className='footer--top__links'>
                      <div className='logo'>
                        <a href=''>
                          <img src='img/site_logo.png' alt='logo' />
                        </a>
                      </div>
                      <div className='text'>
                        <p>
                          {t('title.place')}
                        </p>
                        <p>{t('title.address')} </p>
                        <p>8 (7232) 26-74-09</p>
                        <p>
                          <a href='mailto:ekstu_tz@mail.ru'>ekstu_tz@mail.ru</a>
                        </p>
                      </div>
                    </div>
                    <div className='footer--bottom'>
                      <div className='footer--bottom__copyright'>
                        <span>
                          <p>
                            © {t('title.place')} <br /> {t('title.rights')}
                            <br />
                            {t('common.kazakhstan')}, 070004, {t('title.address')} Тел./факс 8 (7232) 26-74-09
                          </p>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Aboutus
