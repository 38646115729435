import Cookies from 'js-cookie'
import 'leaflet-distortableimage'
import 'leaflet-imageoverlay-rotated'
import 'leaflet-toolbar'
import React, { useEffect, useState } from 'react'
import { Alert, Button, DateRangePicker } from 'rsuite'
import { getTempDay } from '../../http/API'
import { useTranslation } from 'react-i18next'

const YieldPredict = ({ adClass, setDataModalShow, setRecommendShow, DataModalShow, foundFields, fitBounds, setfieldMasterOrig, fieldMasterOrig, RecommendShow }) => {
  const { t, i18n } = useTranslation()
  const [curTheme, setTheme] = useState(Cookies.get('theme') === 'black' ? 'black' : 'white')
  const [croptype, setCropType] = useState()
  if (curTheme === 'white') {
    document.documentElement.style.setProperty('--theme-main', 'darkblue')
    document.documentElement.style.setProperty('--theme-text', 'white')
    document.documentElement.style.setProperty('--theme-secondary', 'lightgray')
    document.documentElement.style.setProperty('--theme-contrast', 'black')
  } else if (curTheme === 'black') {
    document.documentElement.style.setProperty('--theme-main', 'lime')
    document.documentElement.style.setProperty('--theme-text', 'black')
    document.documentElement.style.setProperty('--theme-secondary', 'darkslategray')
    document.documentElement.style.setProperty('--theme-contrast', 'white')
  }
  const themeChangeFunc = () => {
    if (curTheme === 'black') {
      Cookies.set('theme', 'white')
      setTheme('white')
      document.documentElement.style.setProperty('--theme-main', 'darkblue')
      document.documentElement.style.setProperty('--theme-text', 'white')
      document.documentElement.style.setProperty('--theme-secondary', 'lightgray')
      document.documentElement.style.setProperty('--theme-contrast', 'black')
    } else {
      Cookies.set('theme', 'black')
      setTheme('black')
      document.documentElement.style.setProperty('--theme-main', 'lime')
      document.documentElement.style.setProperty('--theme-text', 'black')
      document.documentElement.style.setProperty('--theme-secondary', 'darkslategray')
      document.documentElement.style.setProperty('--theme-contrast', 'white')
    }
  }

  const getAverageTemp = async () => {
    if (!dateRange) {
      Alert.error(t('yield.warn.ranges'))
      return
    }
    if (!fieldMasterOrig) {
      Alert.error(t('yield.warn.field'))
      return
    }
    const range = [
      {
        day: dateRange[0].getDate(),
        month: dateRange[0].getMonth() + 1,
        year: dateRange[0].getFullYear()
      },
      {
        day: dateRange[1].getDate(),
        month: dateRange[1].getMonth() + 1,
        year: dateRange[1].getFullYear()
      }
    ]
    const props = await getTempDay(JSON.stringify(range), croptype, fieldMasterOrig.id).then(res => {
      if (res.response.success) {
        setTemp(res.response.result)
      }
    })
  }
  const [dateRange, setDateRange] = useState()
  const [temp, setTemp] = useState()
  useEffect(() => {
    dateRange && getAverageTemp()
  }, [dateRange])

  const cropList = [
    'culture.barley',
    'culture.sun',
    'culture.wheat',
    'culture.oats'
  ]
  return (
<div className={`dropdown-content ${adClass}`} style={{ maxHeight: '70vh', overflowY: 'auto' }}>
{ croptype
  ? <>
<h4>{t('profile.predict')}</h4>
<hr />
<h5>{t('yield.trange')}</h5> <DateRangePicker onChange={(e) => { setDateRange(e); setTemp() }} placement='auto' disabled={!fieldMasterOrig}/>
      {/* <Button onClick={getAverageTemp} /> */}
      {
        dateRange && temp && <>
        {/* <h5>Средняя температура: {temp.temp}°C</h5> */}
        <h5>{t('yield.prop.temp')} {temp.tempsum.toFixed(2)}°C</h5>
        <h5>{t('yield.prop.trec')}</h5>
        {temp.diff !== 0 ? <h5>{t('yield.rec')} {temp.diff > 0 ? t('yield.prolong') : t('yield.short')} {t('yield.rangeby')} {Math.abs(temp.diff)} {t('yield.days')}</h5> : <h5>{t('yield.optimal')}</h5>}
        <hr />
        <h5>{t('yield.ranges')}</h5>
        <h5>{t('yield.rng1')} {dateRange[1].toLocaleDateString('ru-RU')} - {temp.res_stages[0].date} <br /> <h4>({temp.res_stages[0].sum.toFixed(2)}°C)</h4></h5><br />
        <h5>{t('yield.range.2')} {temp.res_stages[0].date} - {temp.res_stages[1].date} <br /> <h4>({temp.res_stages[1].sum.toFixed(2)}°C)</h4></h5><br />
        <h5>{t('yield.range.3')} {temp.res_stages[1].date} - {temp.res_stages[2].date} <br /> <h4>({temp.res_stages[2].sum.toFixed(2)}°C)</h4></h5><br />
        <h5>{t('yield.range.4')} {temp.res_stages[2].date} - {temp.res_stages[3].date} <br /> <h4>({temp.res_stages[3].sum.toFixed(2)}°C)</h4></h5><br />
        <h5>{t('yield.range.5')} {temp.res_stages[3].date} - {temp.res_stages[4].date} <br /> <h4>({temp.res_stages[4].sum.toFixed(2)}°C)</h4></h5><br />
        <h5>{t('yield.range.6')} <h4>{temp.commonsum.toFixed(2)}°C</h4></h5>
        <h5>{t('yield.range.norm')}</h5>
        <hr />
        <h4>{t('yield.predition')} {temp?.yields?.toFixed(2)} {t('yield.measure')}</h4>
        {/* <p>Влажность: {temp.humidity}%</p> */}
        {/* <p>Атмосферное давление: {temp.pressure} мм.рт.ст</p> */}
        </>
      }
      <br />
      <h5>{t('yield.field')} {fieldMasterOrig ? JSON.parse(fieldMasterOrig?.json).properties.name : t('yield.nofield')}</h5>
      <hr />
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
              {foundFields.map((e, key) => <Button style={{ margin: '10px' }} onClick={() => { setfieldMasterOrig(e); fitBounds(JSON.parse(e.json)) }} key = {key * 9}>{JSON.parse(e.json).properties.name}</Button>)}
              </div>
      </>
  : <>
  <h4>{t('yield.selecttitle')}</h4>
  <hr />
  {
    cropList.map((e, key) =>
      <button key={key + 250} onClick={() => setCropType(e)}>{t(e)}</button>
    )
  }
  </>
    }
      <hr />
      {temp && typeof DataModalShow !== 'undefined' && <><button onClick={() => { setRecommendShow(false); setDataModalShow(!DataModalShow) }}>{DataModalShow ? t('common.close') : t('common.open')} {t('yield.analysis')}</button><br /></>}
      {typeof DataModalShow !== 'undefined' && <button onClick={() => { setRecommendShow(!RecommendShow); setDataModalShow(false) }}>{RecommendShow ? t('common.close') : t('common.open')} {t('yield.reccs')}</button>}
      <br />
      {typeof DataModalShow !== 'undefined' && <button onClick={() => { window.location.href = '/profile' }}>{t('common.exit')}</button>}
</div>
  )
}

export default YieldPredict
