import React, { useEffect, useState } from 'react'
import WelcomeModal from '../components/WelcomeModal'
import jwt_decode from 'jwt-decode'
import Cookies from 'js-cookie'
import { FlexboxGrid, Modal, Form, Tooltip, Input, Button, Whisper, Alert } from 'rsuite'
import { activate, quickLook } from '../http/API'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as FA from '@fortawesome/free-solid-svg-icons'

const Profile = () => {
  const { t, i18n } = useTranslation()
  const token = Cookies.get('token')
  const userRole = token ? jwt_decode(token).role : undefined
  const [regkey, setRKey] = React.useState()
  const [curTheme, setTheme] = React.useState(Cookies.get('theme') === 'black' ? 'black' : 'white')
  const [modalShow, setModalShow] = React.useState(false)
  const [regmodalShow, setRegModalShow] = React.useState(false)
  const [quickData, setData] = useState()
  const [updateKey, update] = useState(0)
  const [loading, setLoading] = useState(true)
  const handleLogOut = () => {
    Cookies.remove('token')
    window.location.href = '/profile'
  }
  useEffect(() => {
  }, [updateKey])
  useEffect(async () => {
    if (Cookies.get('token')) {
      const query = await quickLook()
        .then(e => {
          setLoading(false)
          if (e.response.success) {
            setData(e.response.query)
            update(updateKey + 1)
          }
        })
    } else {
      setLoading(false)
    }
  }, [])
  const submit = async () => {
    let response
    const resp = await activate(regkey)
      .then((res) => {
        console.log(res)
        response = res.response
        if (response.success) {
          Alert.success(response.message)
          setRegModalShow(false)
          window.location.href = window.location
        } else {
          Alert.error(response.message)
        }
      })
  }
  return (
    <>
      <WelcomeModal show={modalShow} onHide={() => setModalShow(false)} />
      <div className={loading ? 'backdrop-loading' : 'hidden'}>
      <FontAwesomeIcon icon={FA.faSpinner} spin={true} size={'10x'}/>
      </div>
      <Modal className='welcome-modals' show={regmodalShow} backdrop keyboard={false} onHide={() => setRegModalShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title className='text-center'>
            {t('profile.activate')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <FlexboxGrid>
              <FlexboxGrid.Item colspan={24} className='p-15'>
                <Whisper
                  trigger='active' speaker={
                    <Tooltip>{t('profile.activatetooltip')}<a href='https://forms.gle/ZVNpMFMhtxgMbpFK9' target='_blank' rel='noreferrer'>{t('profile.activatelink')}</a></Tooltip>
                }
                >
                  <Input
                    placeholder={t('common.regkey')} csize='md' onChange={e => setRKey(e)}
                  />
                </Whisper>
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            appearance='primary' size='sm' onClick={submit} className='m-b-15 m-r-15'
          >
            {t('profile.activatebutton')}
          </Button>
        </Modal.Footer>
      </Modal>
      <div className={loading ? 'hidden' : 'original-wrapper'} style={{ filter: modalShow ? 'blur(5px)' : '' }}>
        <div className='page'>
          <header className='wrapper-full' style={{ width: 'min-content' }}>
            <div className='header'>
              <div id='toggle'>
                <input type='checkbox' />
              </div>
              <div id='mobile-menu__bg' />
              <a href='/' className={loading && 'hidden'}>
              <img src='img/site_logo.png' alt='' width='150px' height='150px'/>
              </a>
            </div>
          </header>
          {/* <div id='login_div' className='main-div'>
          <br />
          <br />
          <h3 align='center'>Авторизация</h3>
          <br />
          <form id='login-form'>
          <input type='email' placeholder='Логин...' id='email_field' />
          <input type='password' placeholder='Пароль...' id='password_field' />
          <button onClick={login({
            email: document.getElementById('email_field')?.value,
            password: document.getElementById('password_field')?.value
          })}>Войти в аккаунт</button>
          </form>
        </div> */}
        </div>
        <div id='loading' />
        <div id='user_div'>
          <section className=''>
            <div className='gallery'>
              <div className='gallery__unit large' style={{ height: 'auto' }}>
                <div className='gallery__unit--text'>
                  <div>
                    <br />
                    <br />
                    <br />
                    <h2 className='__head' style={{ width: 'min-content' }}>
                      {t('profile.title')}
                    </h2>
                    <p style={{ marginBottom: '25px' }} id='user_para'>{userRole === 'DEMO' ? `${t('profile.welcome')}, ${Cookies.get('first_name')}! ${t('profile.demowarn')}` : userRole ? `${t('profile.welcome')}, ${Cookies.get('first_name')}!` : t('profile.anonwelcome')}</p>
                    <div className='buttons-profile'>
                      <a className='custom-btn transparent-button' onClick={userRole ? handleLogOut : () => setModalShow(true)}>
                        {userRole ? t('profile.logout') : t('profile.auth')}
                      </a>
                      <a className='custom-btn transparent-button' onClick={() => i18n.changeLanguage(i18n.language === 'ru' ? 'kz' : i18n.language === 'kz' ? 'en' : 'ru')}>
                        {t('map.sidebar.language').toUpperCase()}: {i18n.language.toUpperCase()}
                      </a>
                      {userRole === 'DEMO'
                        ? <a className='custom-btn transparent-button' onClick={() => setRegModalShow(true)}>
                        {t('profile.activate')}
                      </a>
                        : ''}
                    </div>
                  </div>
                </div>
              </div>
              {quickData &&
                <div className='gallery__unit'>
                  <div className='stats gallery__unit--img'>
                    <h4>{t('profile.quickdata.title')}</h4>
                    <h2>{quickData.place}</h2>
                    <h5>{t('profile.quickdata.subtitle')}</h5><br /><br />
                    <h4 style={{ textAlign: 'left' }}> {t('profile.quickdata.total')} {quickData.total}</h4>
                    <h4 style={{ textAlign: 'left' }}>{t('profile.quickdata.area')} {(quickData.totalarea / 10000).toFixed(3)} {t('sidemenu.ha')}</h4>
                    <h4 style={{ textAlign: 'left' }}>{t('profile.quickdata.temp')} {quickData.averageTemp} °C</h4>
                      <a href="/map_main"></a>
                {/* <img src='img/map_icon.png' alt='img' /> */}
                {/* <img src='img/lechlerfon.png' alt='img' /> */}
                  </div>
                </div>}
              { userRole && <>
              <div className='gallery__unit' onClick={() => !userRole && Alert.warning('Необходима авторизация!')}>
                <div className='gallery__unit--img'>
                  <div />
                  <h3>
                    {t('common.weather')} </h3>
                  {/* <img src='img/map_icon.png' alt='img' /> */}
                  {userRole && <img src='img/lechlerfon.png' alt='img' />}
                  <a href={userRole && '/weather'} />
                </div>
              </div>
              <div className='gallery__unit' onClick={() => !userRole && Alert.warning('Необходима авторизация!')}>
                <div className='gallery__unit--img'>
                  <div />
                  <h3>
                    {t('profile.db')} </h3>
                  {userRole && <img src='img/lechlerfon.png' alt='img' />}
                  <a href={userRole && '/notelist'} />
                </div>
              </div></>
              }
              {userRole === 'ADMIN'
                ? <div className='gallery__unit'>
                  <div className='gallery__unit--img'>
                    <div />
                    <h3>
                      {t('profile.admin')}
                    </h3>
                    <img src='img/admin_icon.png' alt='img' />
                    <a href='/adminsettings' />
                  </div>
                </div>
                : ''}
              {!userRole && <>
              <div className='gallery__unit'>
                <div className='gallery__unit--img'>
                  <div />
                  <h3>{t('profile.calcs')}</h3>
                  <img src='img/lechlerfon.png' alt='img' />
                  <a href='/calc_list' />
                </div>
              </div>
              <div className='gallery__unit'>
                <div className='gallery__unit--img'>
                  <div />
                  <h3>{t('profile.externalres')}</h3>
                  <img src='img/lechlerfon.png' alt='img' />
                  <a href='/extres' />
                </div>
              </div>
              </>}
              { userRole &&
              <div className='gallery__unit' onClick={() => !userRole && Alert.warning('Необходима авторизация!')}>
                <div className='gallery__unit--img'>
                  <div />
                  <h3>{t('profile.predict')}</h3>
                  {/* <img src='img/5.jpg' alt='img' /> */}
                  {userRole && <img src='img/lechlerfon.png' alt='img' />}
                  <a href={userRole && '/recspecial'} />
                </div>
              </div>
}
{ !userRole &&
              <div className='gallery__unit'>
                <div className='gallery__unit--img'>
                  <div />
                  <h3>{t('profile.map')}</h3>
                  {/* <img src='img/5.jpg' alt='img' /> */}
                  <img src='img/lechlerfon.png' alt='img' />
                  <a href='/map' />
                </div>
              </div>
}
              { userRole &&
              <div className='gallery__unit' onClick={() => !userRole && Alert.warning('Необходима авторизация!')}>
                <div className='gallery__unit--img'>
                  <div />
                  <h3>{t('profile.telemetry')}</h3>
                  {/* <img src='img/5.jpg' alt='img' /> */}
                  {userRole && <img src='img/lechlerfon.png' alt='img' />}
                  <a href={userRole && 'https://online.omnicomm.ru'} target='_blank' rel="noreferrer"/>
                  {/* <a href={userRole && '/tracking'} /> */}
                </div>
              </div>
}
              <div className='gallery__unit'>
                <div className='gallery__unit--img'>
                  <div />
                  <h3>{t('profile.data')}</h3>
                  {/* <img src='img/directory.jpg' alt='img' /> */}
                  <img src='img/lechlerfon.png' alt='img' />
                  <a href='/infospecial' />
                </div>
              </div>
              {!userRole && <>
              <div className='gallery__unit'>
                <div className='gallery__unit--img'>
                  <div />
                  <h3>{t('profile.help')}</h3>
                  <img src='img/lechlerfon.png' alt='img' />
                  <a href='/manual' />
                </div>
              </div>
              </> }
            </div>
          </section>
        </div>
      </div>
    </>
  )
}

export default Profile
