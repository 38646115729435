import React from 'react'
import '../styles/calcmodal.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as FA from '@fortawesome/free-solid-svg-icons'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'

const Normcalculator = () => {
  const { t, i18n } = useTranslation()
  const [curTheme, setTheme] = React.useState(Cookies.get('theme') === 'black' ? 'black' : 'white')
  if (curTheme === 'white') {
    document.documentElement.style.setProperty('--theme-main', 'darkblue')
    document.documentElement.style.setProperty('--theme-text', 'white')
    document.documentElement.style.setProperty('--theme-secondary', 'lightgray')
    document.documentElement.style.setProperty('--theme-contrast', 'black')
  } else if (curTheme === 'black') {
    document.documentElement.style.setProperty('--theme-main', 'lime')
    document.documentElement.style.setProperty('--theme-text', 'black')
    document.documentElement.style.setProperty('--theme-secondary', 'darkslategray')
    document.documentElement.style.setProperty('--theme-contrast', 'white')
  }
  const themeChangeFunc = () => {
    if (curTheme === 'black') {
      Cookies.set('theme', 'white')
      setTheme('white')
      document.documentElement.style.setProperty('--theme-main', 'darkblue')
      document.documentElement.style.setProperty('--theme-text', 'white')
      document.documentElement.style.setProperty('--theme-secondary', 'lightgray')
      document.documentElement.style.setProperty('--theme-contrast', 'black')
    } else {
      Cookies.set('theme', 'black')
      setTheme('black')
      document.documentElement.style.setProperty('--theme-main', 'lime')
      document.documentElement.style.setProperty('--theme-text', 'black')
      document.documentElement.style.setProperty('--theme-secondary', 'darkslategray')
      document.documentElement.style.setProperty('--theme-contrast', 'white')
    }
  }
  return (
    <div className='data calcmodal-body'>
      <a style={{ width: 'fit-content', float: 'left', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' onClick={themeChangeFunc}>
        <FontAwesomeIcon icon={FA.faPalette} />
      </a>
      <a style={{ width: 'fit-content', float: 'right', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' href='/profile'>
        <FontAwesomeIcon icon={FA.faHome} />
      </a>
      <a style={{ width: 'fit-content', float: 'right', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' onClick={() => i18n.changeLanguage(i18n.language === 'ru' ? 'kz' : i18n.language === 'kz' ? 'en' : 'ru')}>
        <h5 style={{ margin: 'auto' }}>{i18n.language.toUpperCase()}</h5>
      </a>
      <h3 className='calc-title' align='center'>Расчет норм анализов норм минеральных удобрений</h3>
      <p />
      <b>Норма действующего вещества</b> (кг на 1 га):
      <input id='one' type='text' placeholder='Введите данные' />
      <hr /> <b>Содержание действующего вещества в данном удобрении</b>
      <input type='text' id='two' placeholder='Введите данные' />
      <hr />
      <input type='text' id='result' placeholder='Итог' />
      <p align='center'>
        <b>норм минеральных удобрений</b> (кг NH4NO2 на 1 га)
        <br />
        <br />
      </p>
      <h3 className='calc-title' align='center'>Расчет норм анализов действующего вещества</h3>
      Пользуясь данной формулой, можно сделать и обратные расчеты – установить,
      сколько внесено действующего вещества с определенным количеством туков:
      <br />
      <br />
      <b>Норма действующего вещества</b> (кг на 1 га):
      <input id='one1' type='text' placeholder='Введите данные' />
      <hr /> <b>Содержание действующего вещества в данном удобрении</b>
      <input type='text' id='two1' placeholder='Введите данные' />
      <hr />
      <input type='text' id='result1' placeholder='Итог' />
      <p align='center'>
        <b>действующего вещества</b> (кг NH4NO2 на 1 га)
        <br />
        <br />
        <button className='btn-default' id='insert'>
          Сохранить данные
        </button>
        <br />
      </p>
    </div>
  )
}

export default Normcalculator
