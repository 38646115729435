import React from 'react'
import { CanvasJSChart } from 'canvasjs-react-charts'
import Cookies from 'js-cookie'

const ChartModalYear = ({ year, fields, update }) => {
  const [updateKey, Aupdate] = React.useState(0)
  // const colors = ['red', 'blue', 'green', 'yellow', 'cyan', 'magenta']
  const colors = ['#8BD3E6', '#FF6D6A', '#E9EC6B', '#EFBE7D', '#B1A2CA', '#E0BBE4', '#957DAD', '#D291BC']
  const match = {}
  const options = {
    dataPointMaxWidth: 50,
    animationEnabled: true,
    exportEnabled: true,
    theme: Cookies.get('theme') === 'black' ? 'dark1' : 'light1', // "light1", "dark1", "dark2"
    title: {
      text: `Урожайность полей во время сезона "${year}" (ц/га) `
    },
    axisY: {
      includeZero: true
    },
    data: [{
      type: 'column',
      indexLabelFontColor: '#5A5757',
      indexLabelPlacement: 'outside',
      indexLabel: '{y}',
      dataPoints: fields && fields.filter((e) => e.season === year).map((e) => {
        console.log(e.croptype)
        if (!Object.keys(match).includes(e.croptype)) {
          match[e.croptype] = colors[0]
          colors.shift()
        }
        return (
          { color: match[e.croptype], label: `${e.field_name} (${e.croptype})`, y: e.yield ? Number(e.yield.replace(',', '.')) : 0 }
        )
      })
    }]
  }
  return (
    <div>
      <CanvasJSChart options={options} />

    </div>
  )
}
export default ChartModalYear
