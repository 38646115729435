import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as FA from '@fortawesome/free-solid-svg-icons'
import { getFields, addField, removeField, getGeoJSON } from '../http/API'
import { Input, Alert, Button, ControlLabel } from 'rsuite'
import Cookies from 'js-cookie'
import { useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import ChartModal from './charts/Chart'
import ChartModalYear from './charts/ChartYear'
import ChartModalCrop from './charts/ChartCrop'

function DataAnalysis ({ slave, fieldId_master, adClass, hideFunc, field }) {
  const { t, i18n } = useTranslation()
  function sortByProperty (property) {
    return function (a, b) {
      if (a[property] > b[property]) {
        return 1
      } else if (a[property] < b[property]) {
        return -1
      }
      return 0
    }
  }
  const groupBy = (arr, property) => {
    return arr.reduce((acc, cur) => {
      acc[cur[property]] = [...acc[cur[property]] || [], cur]
      return acc
    }, {})
  }
  const [selField, selectField] = useState()
  const [chartID, setChartID] = useState()
  const [chartYear, setChartYear] = useState()
  const [fieldsToSend, setFieldsToSend] = useState()
  const curYear = 0

  const [fieldsArray, setFields] = useState([])
  const [fieldsArrayGroup, setFieldsGroup] = useState([])
  const [updateKey, update] = useState(0)
  useEffect(async () => {
    const fieldsList = await getFields()
    if (fieldsList.response.success) {
      const fields = fieldsList.response.fields.sort(sortByProperty('season'))
      const fieldsGroup = groupBy(fields, 'season')
      setFieldsGroup(fieldsGroup)
      setFields(fields)
    }
  }, [updateKey])
  useEffect(() => {
    if (fieldId_master) {
      setChartID(fieldId_master)
      setFieldsToSend(fieldsArray.filter((e) => e.field_name === fieldId_master))
    }
  }, [fieldId_master])
  const [curTheme, setTheme] = useState(Cookies.get('theme') === 'black' ? 'black' : 'white')
  if (!slave) {
    if (curTheme === 'white') {
      document.documentElement.style.setProperty('--theme-main', 'darkblue')
      document.documentElement.style.setProperty('--theme-text', 'white')
      document.documentElement.style.setProperty('--theme-secondary', 'lightgray')
      document.documentElement.style.setProperty('--theme-contrast', 'black')
    } else if (curTheme === 'black') {
      document.documentElement.style.setProperty('--theme-main', 'lime')
      document.documentElement.style.setProperty('--theme-text', 'black')
      document.documentElement.style.setProperty('--theme-secondary', 'darkslategray')
      document.documentElement.style.setProperty('--theme-contrast', 'white')
    }
  }
  const themeChangeFunc = () => {
    if (curTheme === 'black') {
      Cookies.set('theme', 'white')
      setTheme('white')
      document.documentElement.style.setProperty('--theme-main', 'darkblue')
      document.documentElement.style.setProperty('--theme-text', 'white')
      document.documentElement.style.setProperty('--theme-secondary', 'lightgray')
      document.documentElement.style.setProperty('--theme-contrast', 'black')
    } else {
      Cookies.set('theme', 'black')
      setTheme('black')
      document.documentElement.style.setProperty('--theme-main', 'lime')
      document.documentElement.style.setProperty('--theme-text', 'black')
      document.documentElement.style.setProperty('--theme-secondary', 'darkslategray')
      document.documentElement.style.setProperty('--theme-contrast', 'white')
    }
  }
  return (
    <div className={slave && `dropdown-content ${adClass}`} style={slave && { width: '400px', overflowY: 'auto', maxHeight: '80vh' }}>
{ slave && <a style={{ width: 'fit-content', float: 'right', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' onClick={() => hideFunc(false)}>
    <FontAwesomeIcon icon={FA.faTimes} />
  </a>}
      <div className='calcmodal-body data' style={slave && { display: 'none' }} key={updateKey}>
        <a style={{ width: 'fit-content', float: 'left', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' onClick={themeChangeFunc}>
          <FontAwesomeIcon icon={FA.faPalette} />
        </a>
        <a style={{ width: 'fit-content', float: 'right', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' href='/profile'>
          <FontAwesomeIcon icon={FA.faHome} />
        </a>
        <a style={{ width: 'fit-content', float: 'right', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' onClick={() => i18n.changeLanguage(i18n.language === 'ru' ? 'kz' : i18n.language === 'kz' ? 'en' : 'ru')}>
          <h5 style={{ margin: 'auto' }}>{i18n.language.toUpperCase()}</h5>
        </a>
        <h3 align='center'>Графики урожайности</h3>
        <br />

      </div>
      <div className='chart-notes-wrapper'>
      {
    fieldsArray.length === 0
      ? 'У вашего хозяйства пока нет заметок!'
      : !slave && Object.keys(fieldsArrayGroup).map((year, key) => {
        // console.log(JSON.parse(selField.json).properties.name, f.field_name)
        // if (curYear !== f.season) {
        //   if (curYear !== 0) {
        //     f.div = true
        //   }
        //   curYear = f.season
        // }
          return (
          <div className='calcmodal-body data' key = {key + 20000}>
            <div className='note-div-parent'>
              {
                fieldsArrayGroup[year].map((f, key2) =>
                  <div key={key2 * 39}
                  className='note-div' style={{ width: 'min-content', whiteSpace: 'nowrap' }} onClick={() => {
                    setChartID(f.field_name)
                    setChartYear(f.season)
                    setFieldsToSend(fieldsArray.filter((e) => e.field_name === f.field_name))
                  }}
                >
                  <p style={{ width: '20%' }}>{f.season}: {f.field_name}</p>
                </div>
                )
              }
            </div>
          </div>
          )
        })
    }
    </div>
      {chartID &&
          <div className='calcmodal-body data' style={slave && { width: '100%', margin: 0 }}>
            <ChartModal id={chartID} fields={fieldsArray} update={updateKey} />
          </div>
      }
      {!slave && chartYear && <div className='calcmodal-body data' style={slave && { width: '100%', margin: 0 }}>
            <ChartModalYear year={chartYear} fields={fieldsArray} update={updateKey} />
          </div>}
      {!slave && chartYear && <div className='calcmodal-body data' style={slave && { width: '100%', margin: 0 }}>
            <ChartModalCrop year={chartYear} fields={fieldsArray} update={updateKey} />
          </div>}
    </div>
  )
}

export default DataAnalysis
