import React, { useContext, useRef, useEffect, useState } from 'react'
import L from 'leaflet'
// import 'leaflet/dist/leaflet.css'
import 'leaflet-imageoverlay-rotated'
import 'leaflet-toolbar'
import 'leaflet-distortableimage'
import MapControls from './MapControls'
import { HOST_URL } from '../constants/constants'
import { SatImgContext } from '../contexts/DataProviderContext'
import { SearchOptionsContext } from '../contexts/SearchDetailsContext'
import { NavigationContext } from '../contexts/NavigationContext'
import { Loader, Alert, Whisper, Tooltip } from 'rsuite'
import * as turf from '@turf/turf'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as FA from '@fortawesome/free-solid-svg-icons'
import AttributeList from '../sidecomponents/sidewindows/AttributeList'
import DrawTools from '../sidecomponents/sidewindows/DrawTools'
import LayerList from '../sidecomponents/sidewindows/LayerList'
import UploadFilesModal from '../imports/UploadFilesModal'
import '../styles/sidenav.css'
import Cookies from 'js-cookie'
import { addGeoJSON, deleteGeoJSON, getGeoJSON, syncJSON } from '../http/API'
import CreateField from '../sidecomponents/sidewindows/CreateField'
import FieldsList from '../sidecomponents/sidewindows/FieldsList'
import DataSave from '../pages/DataSave'
import Recommend from '../pages/Recommend'
import UpdateField from '../sidecomponents/sidewindows/UpdateField'
import DataAnalysis from '../pages/DataAnalysis'
import MapTypes from '../sidecomponents/sidewindows/MapTypes'
import CreateMarker from '../sidecomponents/sidewindows/CreateMarker'
import YieldPredict from '../sidecomponents/sidewindows/YieldPredict'

const Map = () => {
  const [curTheme, setTheme] = useState(Cookies.get('theme') === 'black' ? 'black' : 'white')
  const [jsonToSave, saveJSON] = useState('')
  const [markerJSON, setMarkerJSON] = useState('')
  const [jsonToDisplay, setJSON] = useState([])
  const [fieldToUpdate, setFieldToUpdate] = useState()
  const [updateKey, update] = useState(0)
  const [checker, sureness] = useState(false)
  const [mapType, setMap] = useState(Cookies.get('pref-map') || 'http://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}')
  function sortByProperty (property) {
    return function (a, b) {
      if (a[property] > b[property]) {
        return 1
      } else if (a[property] < b[property]) {
        return -1
      }
      return 0
    }
  }
  if (curTheme === 'white') {
    document.documentElement.style.setProperty('--theme-main', 'darkblue')
    document.documentElement.style.setProperty('--theme-text', 'white')
    document.documentElement.style.setProperty('--theme-secondary', 'lightgray')
    document.documentElement.style.setProperty('--theme-contrast', 'black')
  } else if (curTheme === 'black') {
    document.documentElement.style.setProperty('--theme-main', 'lime')
    document.documentElement.style.setProperty('--theme-text', 'black')
    document.documentElement.style.setProperty('--theme-secondary', 'darkslategray')
    document.documentElement.style.setProperty('--theme-contrast', 'white')
  }
  const themeChangeFunc = () => {
    if (curTheme === 'black') {
      Cookies.set('theme', 'white')
      setTheme('white')
      document.documentElement.style.setProperty('--theme-main', 'darkblue')
      document.documentElement.style.setProperty('--theme-text', 'white')
      document.documentElement.style.setProperty('--theme-secondary', 'lightgray')
      document.documentElement.style.setProperty('--theme-contrast', 'black')
    } else {
      Cookies.set('theme', 'black')
      setTheme('black')
      document.documentElement.style.setProperty('--theme-main', 'lime')
      document.documentElement.style.setProperty('--theme-text', 'black')
      document.documentElement.style.setProperty('--theme-secondary', 'darkslategray')
      document.documentElement.style.setProperty('--theme-contrast', 'white')
    }
  }
  const CloseOtherModals = () => {
    setLayerListShow(false)
    setAttributeListShow(false)
    setDrawToolsShow(false)
    setUploadModalShow(false)
    setFieldsListShow(false)
    setNotesListShow(false)
    setCreateModalShow(false)
    setDataModalShow(false)
    setRecommendShow(false)
    setUpdateModalShow(false)
    setChartModalShow(false)
    setMapTypesShow(false)
    setCreateMarkerModalShow(false)
    setYieldModalShow(false)
  }
  useEffect(async () => {
    const fieldsList = await getGeoJSON()
      .catch((e) => {
        if (String(e).includes('401')) {
          Cookies.remove('token')
          window.location.href = '/map_main'
        }
      })
    if (fieldsList.response.success) {
      setJSON(fieldsList.response.fields.sort(sortByProperty('id')))
      try {
        if (updateKey === 0) {
          fitBounds(JSON.parse(fieldsList.response.fields[0].json))
        }
      } catch (e) {
        console.log(e)
      }
    }
    showLayerRef.current.clearLayers()
  }, [updateKey])
  const addField = async (field, notClear) => {
    try {
      let response
      const resp = await addGeoJSON(field).then((res) => {
        response = res.response
        if (response.success) {
          Alert.success(response.message)
          !notClear && clearLayers()
          !notClear && update(updateKey + 1)
        } else {
          Alert.error(response.message)
        }
      })
    } catch (e) {
      Alert.error(t('error.noconnection'))
      console.log(e)
    }
  }
  const addMarker = async (field) => {
    try {
      let response
      const resp = await addGeoJSON(field).then((res) => {
        response = res.response
        if (response.success) {
          Alert.success(response.message)
          clearLayers()
          update(updateKey + 1)
        } else {
          Alert.error(response.message)
        }
      })
    } catch (e) {
      Alert.error(t('error.noconnection'))
      console.log(e)
    }
  }
  const deleteFields = async (field) => {
    if (!checker) {
      Alert.warning(t('warning.clickagain'))
      sureness(true)
    } else {
      sureness(false)
      try {
        let response
        const resp = await deleteGeoJSON(field).then((res) => {
          response = res.response
          if (response.success) {
            Alert.success(response.message)
            clearLayers()
            update(updateKey + 1)
          } else {
            Alert.error(response.message)
          }
        })
      } catch (e) {
        Alert.error(t('error.noconnection'))
        console.log(e)
      }
    }
  }
  const syncFields = async (id) => {
    try {
      let response
      const resp = await syncJSON(id).then((res) => {
        response = res.response
        if (response.success) {
          Alert.success(response.message)
          clearLayers()
          update(updateKey + 1)
        } else {
          Alert.error(response.message)
        }
      })
    } catch (e) {
      Alert.error(t('error.noconnection'))
      console.log(e)
    }
  }
  const [LayerListShow, setLayerListShow] = useState(false)
  const [AttributeListShow, setAttributeListShow] = useState(false)
  const [DrawToolsShow, setDrawToolsShow] = useState(false)
  const [UploadModalShow, setUploadModalShow] = useState(false)
  const [CreateModalShow, setCreateModalShow] = useState(false)
  const [CreateMarkerModalShow, setCreateMarkerModalShow] = useState(false)
  const [FieldsListShow, setFieldsListShow] = useState(true)
  const [NotesListShow, setNotesListShow] = useState(false)
  const [DataModalShow, setDataModalShow] = useState(false)
  const [RecommendShow, setRecommendShow] = useState(false)
  const [updateModalShow, setUpdateModalShow] = useState(false)
  const [chartModalShow, setChartModalShow] = useState(false)
  const [MapTypesShow, setMapTypesShow] = useState(false)
  const [YieldModalShow, setYieldModalShow] = useState(false)

  const [fieldMaster, setfieldMaster] = useState()
  const [fieldMasterOrig, setfieldMasterOrig] = useState()
  const [fieldNameMaster, setfieldNameMaster] = useState()
  const { t, i18n } = useTranslation()
  const { dataTiles, fetchDataTiles, activeTile, setActiveTile } = useContext(SatImgContext)
  const { searchOptions, setSearchOptions, jsonLayers, newOrder, setNewOrder } = useContext(SearchOptionsContext)
  const { navItemId, setNavItemId } = useContext(NavigationContext)
  const [isLoading, setIsLoading] = useState(false)

  const MAX_AREA = 6313063483978.778// in ha
  const footprintOptions = {
    stroke: true,
    // color: '#4185f4',
    color: '#E3AF34',
    weight: 3,
    opacity: 0.6,
    fill: true,
    fillColor: null,
    fillOpacity: 0,
    clickable: true
  }

  const mapRef = useRef(null) // map
  const paneRef = useRef(null) // tiles order on top
  const drawnLayerRef = useRef(null) // drawn footrpints - paths (lines) = featureGroup
  // const markerRef = useRef(null) // drawn footrpints - paths (lines) = featureGroup
  const showLayerRef = useRef(null) // drawn footrpints - paths (lines) = featureGroup
  const tileLayerRef = useRef(null) // tiles in tilelayer = featureGroup
  const searchGeometryRef = useRef(null) // search geometries on the map = featureGroup
  // const [maptype, changeMap] = useState('alidade_smooth_dark')
  let map

  const handlePolygonClick = (e) => {
    setCreateModalShow(true)
    const point = turf.point([e.latlng.lng, e.latlng.lat])
    dataTiles?.forEach(scene => {
      if (turf.booleanPointInPolygon(point, scene.geometry)) {
        setActiveTile(scene, true)
      }
    })
  }

  // TODO: remove if not used in production = map cleaning - alternative method
  // function removeLayers () {
  //   if (mapRef?.current) {
  //     if (mapRef.current.hasLayer(drawnLayerRef.current)) {
  //       mapRef.current.removeLayer(drawnLayerRef.current)
  //     }
  //     if (mapRef.current.hasLayer(tileLayerRef.current)) {
  //       mapRef.current.removeLayer(tileLayerRef.current)
  //     }
  //   }
  // }

  function clearLayers () {
    if (mapRef?.current) {
      // if (mapRef.current.hasLayer(searchGeometryRef.current)) searchGeometryRef.current.clearLayers()
      if (mapRef.current.hasLayer(drawnLayerRef.current)) drawnLayerRef.current.clearLayers()
      // if (mapRef.current.hasLayer(markerRef.current)) markerRef.current.clearLayers()
      if (mapRef.current.hasLayer(tileLayerRef.current)) tileLayerRef.current.clearLayers()
    }
  }

  /** map initialization */
  useEffect(() => {
    try {
      map = L.map('map', {
        center: [49.945963, 82.613922],
        zoom: 10,
        minZoom: 1,
        zoomControl: false,
        attributionControl: false
      })
      L.tileLayer(mapType, {
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
        maxZoom: 18,
        id: 'baselayer'
      }).addTo(map)
      L.control.attribution({
        position: 'bottomleft'
      }).addTo(map)
      // map.fitBounds([[47.12995075666307, 52.064208984375], [51.1807, 71.461]])

      // Searched geometries
      searchGeometryRef.current = L.featureGroup().addTo(map)
      showLayerRef.current = L.featureGroup().addTo(map)
      // markerRef.current = L.featureGroup().addTo(map)

      mapRef.current = map
      // Pane for tiles topping on the map
      const topPane = mapRef?.current.createPane('leaflet-top-pane', mapRef.current.getPanes().mapPane)
      paneRef.current = topPane

      // Adding Controls
      MapControls(mapRef.current, t)
      mapRef.current.invalidateSize(9)
    } catch (e) {
      console.log(e)
    }
  }, [])
  useEffect(() => {
    // mapRef.current.removeLayer('baselayer')
    mapRef.current.attributionControl.setPrefix(Cookies.get('pref-attr'))
    L.tileLayer(mapType, {
      maxZoom: 18,
      id: 'baselayer'
    }).addTo(mapRef.current)
  }, [mapType])
  useEffect(() => {
    searchGeometryRef.current.clearLayers()
    jsonToDisplay.forEach((f) => {
      const mask = L.geoJSON(JSON.parse(f.json))
      const name = JSON.parse(f.json).properties.name
      console.log()
      if (JSON.parse(f.json).geometry?.type === 'Point') {
        mask.bindTooltip(() => name, { permanent: true })
        mask.addEventListener('mouseover', () => {
          mask.unbindTooltip()
          const time = new Date(f.createdAt)
          mask.bindTooltip(() => `Создано: ${time.toLocaleString('ru-RU')}`, { permanent: true })
        })
        mask.addEventListener('mouseout', () => {
          mask.unbindTooltip()
          mask.bindTooltip(() => name, { permanent: true })
        })
      } else {
        mask.bindTooltip(() => name)
        mask.addEventListener('click', () => { window.innerWidth > 840 && setfieldMaster(f.id); setfieldMasterOrig(f) })
        mask.addEventListener('dblclick', () => {
          if (window.innerWidth > 840) {
            fitBounds(JSON.parse(f.json))
            CloseOtherModals()
            setDataModalShow(true)
            setFieldsListShow(true)
            setfieldMaster(f.id)
            setfieldMasterOrig(f)
          } else {
            window.open(`/datasave/${f.id}`, '_blank')
          }
        })
      }
      mask.addEventListener('mouseover', () => (mask.openTooltip()))
      searchGeometryRef.current.addLayer(mask)
    })
  }, [jsonToDisplay])
  /** Handling drawing on map */
  const handleDrawCreated = React.useCallback(e => {
    console.log(e)
    clearLayers()
    const layer = e.layer
    console.log(layer)
    if (e.layerType === 'marker') {
      console.log(e)
      CloseOtherModals()
      setCreateMarkerModalShow(true)
      const gjson = layer.toGeoJSON()
      gjson.properties.name = '???'
      setMarkerJSON(JSON.stringify(gjson))
      drawnLayerRef.current.addLayer(layer)
    } else {
      const area = L.GeometryUtil.geodesicArea(layer.getLatLngs()[0])
      const gjson = layer.toGeoJSON()
      gjson.properties = {
        name: 'test',
        area: area
      }
      saveJSON(JSON.stringify(gjson))
      if (area <= MAX_AREA) {
        // setSearchLayer(() => layer.toGeoJSON())
      } else {
        Alert.info(t('errors.bigarea'), 5000)
      }
      drawnLayerRef.current.addLayer(layer)
    }
  }, [])
  useEffect(() => {
    // mapRef.current.on('draw:created', function (e) {
    //   // const type = e.layerType
    //   const layer = e.layer
    //   setSearchLayer(layer.toGeoJSON())
    //   searchGeometryRef.current.clearLayers()
    //   searchGeometryRef.current.addLayer(e.layer)
    // })
    mapRef.current.on('draw:created', handleDrawCreated)
  }, [handleDrawCreated])

  /** Draw footprints every time satellite data updates */
  useEffect(() => {
    // Cleaning the map before re-draw, except searching Geometry
    if (mapRef?.current) {
      if (mapRef.current.hasLayer(drawnLayerRef.current)) drawnLayerRef.current.clearLayers()
      if (mapRef.current.hasLayer(tileLayerRef.current)) tileLayerRef.current.clearLayers()
    }
    const drawnItemsGroup = L.featureGroup()

    dataTiles?.map(tile => {
      const layer = L.geoJSON(tile.geometry)
      layer.layerID = tile.id
      layer.setStyle(footprintOptions)
      layer.addTo(drawnItemsGroup)
    })
    drawnItemsGroup.on('click', handlePolygonClick).addTo(mapRef.current)
    drawnItemsGroup.bringToFront()
    drawnLayerRef.current = drawnItemsGroup
  }, [dataTiles])
  /** Adding Img Layer */
  useEffect(() => {
    if (mapRef.current) {
      if (mapRef.current.hasLayer(tileLayerRef.current)) {
        mapRef.current.removeLayer(tileLayerRef.current)
      }
    }
    const tileGroup = L.featureGroup()
    if (dataTiles?.length > 0 || activeTile.geometry) {
      const tempCo = []
      activeTile.geometry.coordinates.forEach(co => {
        co.forEach(co2 => {
          tempCo.push(co2.slice(0, 2).reverse())
        })
      })
      const corners = tempCo
      if ((activeTile?.tile_service_url != null) && (activeTile.source === 'metadataservice')) {
        const tiles = L.tileLayer(
          HOST_URL + activeTile.tile_service_url + '/{z}/{x}/{y}.png', {
          // tms: (q.tileServiceType === "tms")? true : false,
            tms: true,
            bounds: L.latLngBounds(corners),
            opacity: 1,
            // updateWhenIdle: true, - do not increase performance much
            minZoom: mapRef.current.minZoom,
            maxZoom: mapRef.current.maxZoom,
            minNativeZoom: activeTile.min_zoom,
            maxNativeZoom: activeTile.max_zoom
          })
        tiles.addTo(tileGroup)
        tileLayerRef.current = tileGroup
        // tileLayerRef.current.addTo(drawnLayerRef.current)
        tileLayerRef.current.addTo(mapRef.current)

        paneRef.current.appendChild(tiles.getContainer())
        tiles.setZIndex(5)
      } else {
        const quicklook = L.distortableImageOverlay(
          HOST_URL + activeTile.quicklook_url,
          {
            corners: corners
          })
        quicklook.addTo(drawnLayerRef.current)
      }
      drawnLayerRef.current.addTo(mapRef.current)
      // markerRef.current.addTo(mapRef.current)
      mapRef.current.fitBounds(corners, { paddingBottomRight: [400, 0] })
    }
  }, [activeTile])

  useEffect(() => {
    clearLayers()

    if (jsonLayers && !newOrder) {
      L.geoJSON(jsonLayers).addTo(searchGeometryRef.current)
      // const geoJsonFeatures = jsonLayers.getLayers().map(feature => feature.getCoords())
      // mapRef.current.fitBounds()
    } else if (newOrder) {
      setNewOrder(() => false)
    }
  }, [jsonLayers])
  const fitBounds = (field) => {
    const mask = L.geoJSON(field)
    console.log(field)
    if (field.geometry?.type === 'Point') {
      const lat = field.geometry.coordinates[1]
      const lng = field.geometry.coordinates[0]
      mapRef.current.panTo([lat, lng], 10)
    } else {
      mapRef.current.fitBounds(mask.getLayers()[0].getLatLngs())
    }
  }
  return (
    <>
      <div className='navbar-side-wrapper'>
        <nav className='navbar-side'>
          <div className='navbar nav-side'>
            {/* <button className={`nav-element side dropdown ${LayerListShow ? 'dropdown-chosen' : ''}`} onClick={() => setLayerListShow(!LayerListShow)}>
                        <FontAwesomeIcon icon={FA.faSearch} />&nbsp;<h4>Поиск</h4>
                    </button>
                    <button className={`nav-element side dropdown ${AttributeListShow ? 'dropdown-chosen' : ''}`} onClick={() => setAttributeListShow(!AttributeListShow)}>
                        <FontAwesomeIcon icon={FA.faInfo} />&nbsp;<h4>Список слоев</h4>
                    </button>
                    <button className={`nav-element side dropdown ${DrawToolsShow ? 'dropdown-chosen' : ''}`} onClick={() => setDrawToolsShow(!DrawToolsShow)}>
                        <FontAwesomeIcon icon={FA.faPen} />&nbsp;<h4>Атрибуты слоя</h4>
                    </button> */}
            <button className='new-link-element' style={{ width: 'fit-content', padding: '15px', fontWeight: 'bolder', fontSize: '20px', marginRight: '50px' }} onClick={() => { window.location.href = '/' }}><h5 style={{ fontSize: '30px' }}>{t('common.tablet')}&nbsp;</h5><h3 style={{ color: 'gray', fontSize: '25px' }}>{t('common.agro')}</h3></button>
            <Whisper
              trigger='hover' placement={window.innerWidth > 840 ? 'bottom' : 'right'} speaker={
                <Tooltip>{t('map.sidebar.addfield')}</Tooltip>
                    }
            >
              <button className={`new-link-element ${CreateModalShow ? 'dropdown-chosen' : ''}`} onClick={() => { CloseOtherModals(); setCreateModalShow(!CreateModalShow) }}>
                <FontAwesomeIcon icon={FA.faPlus} />
              </button>
            </Whisper>
            <Whisper
              trigger='hover' placement={window.innerWidth > 840 ? 'bottom' : 'right'} speaker={
                <Tooltip>{t('map.sidebar.fieldslist')}</Tooltip>
                    }
            >
              <button className={`new-link-element ${FieldsListShow ? 'dropdown-chosen' : ''}`} onClick={() => { CloseOtherModals(); setFieldsListShow(!FieldsListShow) }}>
                <FontAwesomeIcon icon={FA.faBorderAll} />
              </button>
            </Whisper>
            <Whisper
              trigger='hover' placement={window.innerWidth > 840 ? 'bottom' : 'right'} speaker={
                <Tooltip>Ваши заметки</Tooltip>
                    }
            >
              <button className={`new-link-element ${NotesListShow ? 'dropdown-chosen' : ''}`} onClick={() => { CloseOtherModals(); setNotesListShow(!NotesListShow) }}>
                <FontAwesomeIcon icon={FA.faNotesMedical} />
              </button>
            </Whisper>
            <Whisper
              trigger='hover' placement={window.innerWidth > 840 ? 'bottom' : 'right'} speaker={
                <Tooltip>{t('map.sidebar.upload')}</Tooltip>
                    }
            >
              <button className={`new-link-element ${UploadModalShow ? 'dropdown-chosen' : ''}`} onClick={() => { CloseOtherModals(); setUploadModalShow(!UploadModalShow) }}>
                <FontAwesomeIcon icon={FA.faFileImport} />
              </button>
            </Whisper>
            <Whisper
              trigger='hover' placement={window.innerWidth > 840 ? 'bottom' : 'right'} speaker={
                <Tooltip>Прогноз урожайности</Tooltip>
                    }
            >
              <button className={`new-link-element ${YieldModalShow ? 'dropdown-chosen' : ''}`} onClick={() => { CloseOtherModals(); setYieldModalShow(!YieldModalShow) }}>
                <FontAwesomeIcon icon={FA.faArchive} />
              </button>
            </Whisper>
            <Whisper
              trigger='hover' placement={window.innerWidth > 840 ? 'bottom' : 'right'} speaker={
                <Tooltip>Рекомендации</Tooltip>
                    }
            >
              <button className={`new-link-element ${RecommendShow ? 'dropdown-chosen' : ''}`} onClick={() => {
                if (window.innerWidth > 840) {
                  CloseOtherModals()
                  setRecommendShow(!RecommendShow)
                } else {
                  window.open('/recommendation', '_blank')
                }
              }}>
                <FontAwesomeIcon icon={FA.faChartBar} />
              </button>
            </Whisper>
            <Whisper
              trigger='hover' placement={window.innerWidth > 840 ? 'bottom' : 'right'} speaker={
                <Tooltip>Выбор подложки</Tooltip>
                    }
            >
              <button className={`new-link-element ${MapTypesShow ? 'dropdown-chosen' : ''}`} onClick={() => {
                CloseOtherModals()
                setMapTypesShow(!MapTypesShow)
              }}>
                <FontAwesomeIcon icon={FA.faMapMarked} />
              </button>
            </Whisper>
            {/* <button className='nav-element side dropdown'>
                <FontAwesomeIcon icon={FA.faThumbsUp} />&nbsp;<h4>Рекомендации</h4>
            </button>
            <a id='anchor' href='/calc_list' target='_blank' className='nav-element side dropdown'>
                <FontAwesomeIcon icon={FA.faCalculator} />&nbsp;<h4>Калькуляторы</h4>
            </a>
            <button className='nav-element side dropdown'>
                <FontAwesomeIcon icon={FA.faSpinner} />&nbsp;<h4>Погода</h4>
            </button>
            <button className='nav-element side dropdown'>
                <FontAwesomeIcon icon={FA.faSun} />&nbsp;<h4>NDVI</h4>
            </button>
            <button className='nav-element side dropdown'>
                <FontAwesomeIcon icon={FA.faBook} />&nbsp;<h4>Расчет</h4>
            </button>
            <button className='nav-element side dropdown'>
                <FontAwesomeIcon icon={FA.faTree} />&nbsp;<h4>Техкарты</h4>
            </button> */}
            {/* <button className='nav-element side dropdown'>
                        <FontAwesomeIcon icon={FA.faWarehouse} />&nbsp;<h4>Склад</h4>
                    </button>
                    <button className='nav-element side dropdown'>
                        <FontAwesomeIcon icon={FA.faPrint} />&nbsp;<h4>Работы</h4>
                    </button>
                    <button className='nav-element side dropdown'>
                        <FontAwesomeIcon icon={FA.faHammer} />&nbsp;<h4>GPS-трекинг</h4>
                    </button> */}
            <Whisper
              trigger='hover' placement={window.innerWidth > 840 ? 'bottom' : 'right'} speaker={
                <Tooltip>{t('common.themechange')}</Tooltip>
                    }
            >
              <button className='new-link-element' onClick={themeChangeFunc}>
                <FontAwesomeIcon icon={FA.faPalette} />
              </button>
            </Whisper>
            <Whisper
              trigger='hover' placement={window.innerWidth > 840 ? 'bottom' : 'right'} speaker={
                <Tooltip>{t('map.sidebar.language')}</Tooltip>
                    }
            >
              <button className='new-link-element' onClick={() => i18n.changeLanguage(i18n.language === 'ru' ? 'kz' : i18n.language === 'kz' ? 'en' : 'ru')}>
                <h5 style={{ margin: 'auto' }}>
                  {/* {
                            i18n.language === 'ru'
                              ? <>
                            <font style={{ color: 'red' }}>R</font>
                            <font style={{ color: 'blue' }}>U</font>
                            </>
                              : i18n.language === 'kz'
                                ? <>
                            <font style={{ color: 'yellow' }}>K</font>
                            <font style={{ color: 'skyblue' }}>Z</font>
                              </>
                                : <>
                            <font style={{ color: 'yellow' }}>E</font>
                            <font style={{ color: 'skyblue' }}>N</font>
                              </>
                          } */}
                  {i18n.language.toUpperCase()}
                </h5>
              </button>
            </Whisper>
            <Whisper
              trigger='hover' placement={window.innerWidth > 840 ? 'bottom' : 'right'} speaker={
                <Tooltip>{t('common.return')}</Tooltip>
                    }
            >
              <button onClick={() => { window.location.href = '/profile' }} className='new-link-element'>
                <FontAwesomeIcon icon={FA.faHome} />
              </button>
            </Whisper>
          </div>
        </nav>
        <div className='menu-list'>
          <LayerList adClass={!LayerListShow ? 'hidden' : ''} />
          <AttributeList adClass={!AttributeListShow ? 'hidden' : ''} />
          <DrawTools adClass={!DrawToolsShow ? 'hidden' : ''} />
          <CreateField updatekey={updateKey} drawLayer={drawnLayerRef.current} layer={showLayerRef.current} fitBounds={fitBounds} sendFunc={addField} fieldJSON={jsonToSave} adClass={!CreateModalShow ? 'hidden' : ''} />
          <CreateMarker updatekey={updateKey} drawLayer={drawnLayerRef.current} layer={showLayerRef.current} sendFunc={addField} fieldJSON={markerJSON} adClass={!CreateMarkerModalShow ? 'hidden' : ''} />
          <UpdateField updatekey={update} key={updateKey} fieldJSON={fieldToUpdate} adClass={!updateModalShow ? 'hidden' : ''} onHide={() => { setUpdateModalShow(false); setFieldsListShow(true) }} />
          <UploadFilesModal drawLayer={drawnLayerRef.current} saveJSON={saveJSON} searchRef={searchGeometryRef.current} onHide={() => { setUploadModalShow(false); setDataModalShow(false); setCreateModalShow(true) }} adClass={!UploadModalShow ? 'hidden' : ''} />
        <MapTypes adClass={!MapTypesShow ? 'hidden' : ''} slave setMap={setMap} hideFunc={setMapTypesShow} />
        <YieldPredict adClass={!YieldModalShow ? 'hidden' : ''} foundFields={jsonToDisplay} fitBounds={fitBounds} setfieldMasterOrig={setfieldMasterOrig} fieldMasterOrig={fieldMasterOrig}/>
        </div>
        <FieldsList layer={showLayerRef.current} openEditor = {setUpdateModalShow} openCharts={setChartModalShow} setFieldToUpdate={setFieldToUpdate} hideFunc={setFieldsListShow} showData={setDataModalShow} setFieldMaster={setfieldMaster} setFieldNameMaster={setfieldNameMaster} deleteFields={deleteFields} syncFields = {syncFields} fitBounds={fitBounds} fields={jsonToDisplay} onHide={() => setFieldsListShow(false)} adClass={(!FieldsListShow && !NotesListShow) ? 'hidden' : ''} notesModal={NotesListShow} fieldsModal={FieldsListShow}/>
        <DataSave adClass={!DataModalShow ? 'hidden' : ''} slave fieldId_master={fieldMaster} hideFunc={setDataModalShow} />
        <DataAnalysis adClass={!chartModalShow ? 'hidden' : ''} slave fieldId_master={fieldNameMaster} hideFunc={setChartModalShow} />
        <Recommend adClass={!RecommendShow ? 'hidden' : ''} slave fieldId_master={fieldMaster} hideFunc={setRecommendShow} />
      </div>
      <div id='map'>
        {isLoading && <Loader inverse size='md' center content='loading' className='z-600' />}
      </div>
    </>
  )
}

export default Map
